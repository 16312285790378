<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>VETIVER</h3>
                <h5>Chrysopogon zizanioides,syn. Vetiveria zizanioides</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/pic-vetiver-01.jpg'" class="img-source p-4" alt="vetiver oil java">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction to Vetiver Oil</h4>
                        <p>Vetiver is a parennial grass that shares a lot of its characteristics with other fragrant grasses like lemongrass, citronella and palmarosa. However unlike these plants, vetiver oil is extracted from the plant's roots rather than its leaves</p>
                        <p>While Vetiver Oil is mainly used in fine fragrances, the Indonesian Vetiver is widely used for making derivatives such as Vetiverol and Vetiveryl Acetate.
                        </p>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-12">
                              <h4 class="mt-5 mb-5 text-center">Regions of Cultivation</h4>
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/map-vetiver.jpg'" class="w-100" alt="vetiver oil java">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6">
                        <h4>A brief insight into the Vetiver Oil Industry in Indonesia.</h4>
                        <p>The only area where vetiver is cultivated throughout indonesia is Garut in Java Island. In comparison, the quality of vetiver oil from Haiti and India is preferred over the indonesian kind. Hence the indonesian vetiver oil is significantly cheaper in price.</p>
                        <p>The Indonesian Vetiver Oil is traditionally distilled under very high pressure, resulting in a burnt odour. To combat this, we have installed specific equipment to reduce and remove this odour.</p>
                    </div>
                    <div class="col-md-6">
                        <p>Process involved in production of The 3 Different types of Vetiver Oil and their characteristics</p>
                        <img :src="imageUrl+'new_website/images/botanical-source/ill-vetiver-02.png'" class="img-source p-4" alt="vetiver oil java">
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Vetiver Oil ?</h4>
                        <p class="text-center">
                           We get all our Vetiver Oil from local Indonesian farmers and accumulators who harvest and process the crop through steam distillation to produce a crude form of Vetiver Oil.
                        <br>VETIVER OIL DISTILLATION IN 3 STEPS
                        </p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_2.jpg'" alt="vetiver oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>PLANTATION</h5>
                                                <p>Vetiver is considered to be a rapidly growing variety of grass. It grows vertically tall and in thick clumps. It has a long fibrous root system which is knitted strongly together like a net in the soil.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_2.jpg'" alt="vetiver oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>COLECTING RAW MATERIALS</h5>
                                                <p>Vetiver is harvested between 15 to 18 months in order to get fully developed roots that yield high quality oil. The cleaned roots are spread on drying grounds. The roots should be turned over at regular intervals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3_1.jpg'" alt="vetiver oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>DISTILATION</h5>
                                                <p>
                                                    Steam distillation is used to isolate, separate and purify the oil content in the roots to provide Vetiver Oil.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_2.jpg'" alt="vetiver oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>VETIVER</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step5_2.jpg'" alt="vetiver oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>VETIVER MOLECULAR DISTILED</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        
        <FaqElement source="5c7621f8-a0d5-4cec-8198-520e6112a6eb"/>
         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Vetiver Product List</h4>
                    </div>
                    <ProductList source="5c7621f8-a0d5-4cec-8198-520e6112a6eb"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'VetiverPage',
   data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        }
    },
     components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Vetiver Oil Java Indonesia - Van Aroma';
  }
}
</script>
