<template>
  <section class="header-bg-video-2 dont-copy">
      <div class="row">
        <div class="col-md-5 p-5">
            <p class="excerpt mt-md-5">Sustainability is a core value at Van Aroma, and one of the aspects on which we were founded</p>
            <p>
            At Van Aroma, we are committed to being a responsible and sustainable business that generates value for all the stakeholders it interacts with. We take into account the farmers, distillers, accumulators, customers, and even the needs of our end users further downstream. While, by the very nature of our business, our own environmental footprint is minimal, we believe it is our responsibility as global citizens to address the challenges posed by diminishing natural resources and the various impacts of climate change to transform them into new opportunities for responsible economic growth.
            </p>
        </div>
        <div class="col-md-7 p-relative">
          <a href="#" @click.prevent="playVideo()" id="btn-play-video" class="photoswipe-link-button btn-play-video" data-index="1"></a>
          <video id="headerVideo" :poster="imageUrl+'new_website/images/video-cover.jpg'" @click.prevent="playVideo()">
            <source src="https://cdn.vanaroma.com/vaweb/sustainability.mp4" type="video/mp4">
          </video>
        </div>
      </div>
  </section>
 <section class="ls s-py-90 s-py-xl-150 dont-copy">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
            <h5>Comprehensive Policies</h5>
            <img :src="imageUrl+'new_website/images/sustainability/solar-water-heater.jpg'" class="animate" data-animation="fadeInUp">
        </div>
        <div class="col-md-6 p-5">
          <ul class="list-styled compact">
            <li>Environment Policy</li>
            <li>Occupational Health and Safety Policy</li>
            <li>Human Resource Policy</li>
            <li>Corporate Governance Policy</li>
            <li>Code of Conduct</li>
            <li>Whistleblower Policy</li>
            <li>Information Security Policy</li>
            <li>Sustainable Procurement Policy</li>
            <li>Customer Health and Safety Policy</li>
            <li>Food Security Manual</li>
            <li>Bribery, Corruption and Fraud Policy</li>
            <li>REACH Compliance</li>
            <li>Global Human Rights Policy</li>
          </ul>
        </div>
        <div class="col-md-12 mt-5">
          <div class="collapse_shortcode mt-5">
            <div class="collapse_shortcode-item-full-width">
              <div class="collapse_shortcode-item-title">
                <h4>
                  <a href=".CollapseContent1" aria-controls="CollapseContent1" data-bs-toggle="collapse" role="button" aria-expanded="true" class="collapsed">
                    Resource Efficient Operations</a>
                </h4>
                <div class="collapse CollapseContent1 show">
                  <p class="mt-5">
                    At Van Aroma, we follow a holistic approach that encompasses energy optimization, reduced water consumption, waste management, innovative and greener manufacturing processes/technologies to ensure safe, clean and efficient operations. We achieve this through constant research, and implementation of the latest technologies in heat-exchange, alternate energy sources, as well as the concept of a circular economy.
                  </p>
                </div>
              </div>
              <div class="collapse_shortcode-item-content multi-collapse CollapseContent1 show" id="CollapseContent1">
                <div class="content mt-5">
                  <div id="accordion01" role="tablist">
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse01_header">
                        <h5>
                          <a data-bs-toggle="collapse" href="#collapse01" aria-expanded="false" aria-controls="collapse01" class="collapsed">
                             Energy Management
                          </a>
                        </h5>
                      </div>
                      <div id="collapse01" class="collapse" role="tabpanel" aria-labelledby="collapse01_header" data-bs-parent="#accordion01" style="">
                        <div class="card-body pb-5">
                          <div class="row">
                            <div class="col-md-5">
                                <div class="mt-5 mx-5">
                                  <div class="owl-carousel owl-theme" data-draggable="true" data-nav="true" data-navclass="owl-nav owl-nav-custom" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/energy-management-1.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/energy-management-2.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/energy-management-3.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/energy-management-4.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/energy-management-5.jpg'">
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                              <p>
                                As a responsible corporate, we aim to optimize our energy consumption and reduce our carbon footprint by promoting use of energy efficient measures and behaviour, cleaner energy sources and adopt environmental-friendly production technologies. A constant monitoring, evaluation, and proactive measures towards energy saving also helps us with cost saving which eventually impacts our cost of operations
                              </p>
                              <ul>
                                <li><b>Energy automation</b> - Our plant is equipped with motion, time and temperature based switches (Depending on application) to minimize power consumption.</li>
                                <li><b>Efficient Utilities</b> - All our utilities are over-insulated, and closed loop/sealed systems that ensure minimal energy loss, and optimal heat transfer. We are equipped with wind driven turbine exhaust systems that classify as a green technology. Our facility is equipped with Low-energy LED lighting, which consumes a fraction of the energy consumed by conventional Halogen or even Fluorescent lighting.</li>
                                <li><b>Heat Exchangers</b> - We have developed in-house thermal balancing systems for heat transfer across multiple processing equipment, ensuring optimum use of heat generated. Some of the technologies we have in place are plate heat-exchangers, pipe heat-exchangers, jacketed vessels as well as air cooled heat exchangers.</li>
                                <li><b>Efficient fuel</b> - Our primary energy generation is through use of a combination fuel (bio-diesel & terpenic by-product generated from our factory). In addition to this, we have developed high calorific value, low carbon, and sulphate content terpenes as a fuel additive. We are also using commercial scale solar water-heaters to meet our hot water circulation requirement.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse02_header">
                        <h5>
                          <a class="collapsed" data-bs-toggle="collapse" href="#collapse02" aria-expanded="false" aria-controls="collapse02">
                           Water Management
                          </a>
                        </h5>
                      </div>
                      <div id="collapse02" class="collapse" role="tabpanel" aria-labelledby="collapse02_header" data-bs-parent="#accordion01" style="">
                        <div class="card-body pb-5">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="mt-5 mx-5">
                                  <div class="owl-carousel owl-theme" data-draggable="true" data-nav="true" data-navclass="owl-nav owl-nav-custom" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/water-management.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/water-management-2.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/water-management-3.jpg'">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/water-management-4.jpg'">
                                    </div>
                                  </div>
                              </div>
                            </div>
                            <div class="col-md-7">
                                 <p>
                                  Water is a precious resource and it is increasingly threatened by global scarcity, and over consumption. While our process is not water-intensive, we strive to be effective stewards of water and excel in its management in all our operations.
                                </p>
                                <ul>
                                  <li>We use closed-loop water processes for cooling waters and have progressively reduced our total water use through various efficiency measures. There is no instance of single pass cooling water used at our facility.</li>
                                  <li>Harvested rainwater is our primary source of water, with groundwater as our secondary source in the manufacturing unit, both of which are regularly monitored for quality checks.</li>
                                  <li>We have in place both real-time, as well as scheduled quality management methods in place. Some of the parameters we check for are -> Dissolved Oxygen, TDS, COD, BOD, pH, colour, amongst several others.</li>
                                  <li>The waste water generated from our processes (if any) is passed through aerobic and anaerobic bacteria bio-reactor treatment, green sand, activated carbon and polymer filters and finally through an Ion-Exchange membrane before it is passed through a fish tank prior to being discharged. Discharged water undergoes stringent quality check before disposal to meet regulatory requirement, and be of a higher standard than the minimum required for compliance. </li>
                                  <li>We recover approximately 98% of the solvent used in operations, leading to cleaner discharge. It also improves our end product costing.</li>
                                </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse03_header">
                        <h5>
                          <a class="collapsed" data-bs-toggle="collapse" href="#collapse03" aria-expanded="false" aria-controls="collapse03">
                            Chemicals and Hazardous Material Management
                          </a>
                        </h5>
                      </div>
                      <div id="collapse03" class="collapse" role="tabpanel" aria-labelledby="collapse03_header" data-bs-parent="#accordion01" style="">
                        <div class="card-body pb-5">
                          <p>
                            While in the agro-products and derivatives industry, we also happen to classify as a chemical manufacturer. It is thus very important of us to ensure safe handling, use and disposal of hazardous waste from our operations.
                          </p>
                         <ul>
                            <li>We follow Globally Harmonized System of Classification and Labelling of Chemicals (GHS) standards, International Maritime Dangerous Goods Code (IMDG) Regulations, and extensive Safety Data Sheet (SDS) that are regularly updated to be in line with global norms. Our Quality Assessment and Regulatory teams are up to date with regulations in various countries for controlled, and hazardous substances in order to ensure smooth handling, and delivery to our customers.</li>
                            <li>A demonstration of our commitment to communicating hazards is in our website product catalog where the SDS for all our products are readily available for public access. (products.vanaroma.com)</li>
                            <li>We have taken several initiatives to reduce our Volatile Organic Compounds (VOCs) emissions through use of abatement equipment as well as by using it as an alternative fuel source.</li>
                            <li>We use Supercritical CO2 and food grade ethanol as primary solvents in our process. These solvents are considered eco-friendly, and safe for extraction. Our processes ensure approximately 98% recovery of both solvents at all times, with very little side streams or disposal points. We have implemented drain chokes, and check-dams within the production area to contain any accidental spillages or leakages.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse04_header">
                        <h5>
                          <a class="collapsed" data-bs-toggle="collapse" href="#collapse04" aria-expanded="false" aria-controls="collapse04">
                            EU REACH
                          </a>
                        </h5>
                      </div>
                      <div id="collapse04" class="collapse" role="tabpanel" aria-labelledby="collapse04_header" data-bs-parent="#accordion01" style="">
                        <div class="card-body pb-5">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="mt-2 mx-5">
                                  <div class="owl-carousel owl-theme" data-draggable="true" data-nav="true" data-navclass="owl-nav owl-nav-custom" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/eu_reach_statement.jpg'">
                                    </div>
                                  </div>
                               </div>
                            </div>
                            <div class="col-md-7">
                              <p>
                                Given Van Aroma's global presence, it is imperative for Van Aroma to be compliant with regional regulations, and requirements in order to be in line with the expectations set, as well as to conduct business.<br>
                                EU REACH is a regulation of the European Union, adopted to improve the protection of human health and the environment from the risks that can be posed by chemicals (including fragrance ingredients).<br><br>
                                At present, Van Aroma is in compliance with EU REACH for 9 substances, which makes up for over 90% of our products supplied to customers in the EU region. Our goal is to be fully registered by 2024 for all substances that we ship to the EU over 1 MT.<br>
                                Specific list of products can be accessed here.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            
            <div class="collapse_shortcode-item-full-width mt-5">
              <div class="collapse_shortcode-item-title">
                <h4>
                  <a href=".CollapseContent2" aria-controls="CollapseContent2" data-bs-toggle="collapse" role="button" aria-expanded="true" class="collapsed">
                    Nurturing a Healthy and Safe Workplace</a>
                </h4>
                <div class="collapse CollapseContent2 show">
                  <p class="mt-5">
                       We believe that the strength of our company depends upon the capabilities of our people. In order to be an employer of choice, attract top talent, and help the business deliver lasting results, we ensure a safe working environment and maintaining open dialogue within our team. Our HR policies and processes are developed keeping in mind the best interests of our employees.
                  </p>
                </div>
              </div>
              <div class="collapse_shortcode-item-content multi-collapse CollapseContent2 show" id="CollapseContent2" style="">
                <div class="content mt-5">
                 <div id="accordion02" role="tablist">
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse05_header">
                        <h5>
                          <a data-bs-toggle="collapse" href="#collapse05" aria-expanded="false" aria-controls="collapse05" class="collapsed">
                             Safety at Work
                          </a>
                        </h5>
                      </div>
                      <div id="collapse05" class="collapse" role="tabpanel" aria-labelledby="collapse05_header" data-bs-parent="#accordion02" style="">
                        <div class="card-body pb-5">
                          <div class="row">
                            <div class="col-md-12">
                                <p>
                                  Maintaining adequate standards of occupational health, safety, and well-being adds value to our company as it minimizes the risk of harm to both people and the environment and diminishes potential liabilities for the company. Processes regarding occupational safety are top priorities for us to ensure seamless manufacturing operations, protect the health and safety of our employees, and meet legal requirements. We have formulated a comprehensive occupational health and safety policy in accordance with our core focus on employee safety, product safety and safe work environment. At Van Aroma, we adhere to all local employment norms given by the labour department in Indonesia.
                                </p>
                                <ul>
                                    <li>Well-lit alleys and exit routes with both lighted exit signs, as well as glow in the dark walkways</li>
                                    <li>4 levels of Fire safety (Hand-held CO2 and Powder extinguishers, Single use ceiling suspended foam extinguishers, Hydrant system with ceiling sprinklers triggered automatically upon pressure drop, as well as CO2 gas flooding mechanisms).</li>
                                    <li>Clearly marked assembly point in open grounds.</li>
                                    <li>Implementation of PAS 96:2016 standards.</li>
                                </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse06_header">
                        <h5>
                          <a data-bs-toggle="collapse" href="#collapse06" aria-expanded="false" aria-controls="collapse06" class="collapsed">
                             Learning and Development
                          </a>
                        </h5>
                      </div>
                      <div id="collapse06" class="collapse" role="tabpanel" aria-labelledby="collapse06_header" data-bs-parent="#accordion02" style="">
                        <div class="card-body pb-5">
                          <div class="row">
                            <div class="col-md-6">
                                <div class="mt-2 mx-5">
                                  <div class="owl-carousel owl-theme" data-draggable="true" data-nav="true" data-navclass="owl-nav owl-nav-custom" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-1.jpg'" class="h-300">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-2.jpg'" class="h-300">
                                    </div>
                                    <!-- <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-3.jpg'" class="h-300">
                                    </div> -->
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-4.jpg'" class="h-300">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-5.jpg'" class="h-300">
                                    </div>
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-6.jpg'" class="h-300">
                                    </div>
                                    <!-- <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-7.jpg'" class="h-300">
                                    </div> -->
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/training-8.jpg'" class="h-300">
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                 <p>
                                  Ongoing employee learning and professional development are crucial aspects for motivation and in turn business continuity at Van Aroma. Furthermore, periodic trainings help employees in gaining new knowledge, acquiring new skills, and reinforcing past learnings. All our employees receive technical and procedural training upon joining the company. Employees receive regular functional training, including on ISO standards, safety, product safety, efficient processes, throughout their employment with Van Aroma.
                                </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse07_header">
                        <h5>
                          <a data-bs-toggle="collapse" href="#collapse07" aria-expanded="false" aria-controls="collapse07" class="collapsed">
                             Ethical Business Practices
                          </a>
                        </h5>
                      </div>
                      <div id="collapse07" class="collapse" role="tabpanel" aria-labelledby="collapse07_header" data-bs-parent="#accordion02" style="">
                        <div class="card-body pb-5">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="mt-2 mx-5">
                                  <div class="owl-carousel owl-theme" data-draggable="true" data-nav="true" data-navclass="owl-nav owl-nav-custom" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                    <div class="item">
                                      <img :src="imageUrl+'new_website/images/sustainability/ethical-business.jpg'">
                                    </div>
                                  </div>
                               </div>
                            </div>
                            <div class="col-md-7">
                                <p>
                                  To achieve our long-term goals and continuous growth resulting in increased profitability, compliance with applicable rules, regulations, codes, guidelines, and policies is a permanent feature of our corporate culture. Our code of ethical business conduct specifies and helps continued implementation of the Company’s values by establishing certain non-negotiable minimum standards of behaviour. It guides us on compliance, labour practices and standards, environmental health and safety, board diversity and independent directors, reporting and contact persons, disciplinary actions and compliance with the code.<br>
                                  We believe that to enhance stakeholder value and accomplish long-term goals, a good corporate governance structure is a key element which provides a strong foundation. Our corporate governance is guided by our mission and our senior management’s experience of business sustainability and their values. This ensures transparency to take informed decision and building trust for influential collaboration. It helps us in being a reliable partner of our customers, distributors, farmers, employees, and shareholders. We comply with the mandatory corporate governance requirements stipulated under the Indonesian local law. 
                                </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse08_header">
                        <h5>
                          <a data-bs-toggle="collapse" href="#collapse08" aria-expanded="false" aria-controls="collapse08" class="collapsed">
                             Sustainable Procurement
                          </a>
                        </h5>
                      </div>
                      <div id="collapse08" class="collapse" role="tabpanel" aria-labelledby="collapse08_header" data-bs-parent="#accordion02" style="">
                        <div class="card-body pb-5">
                          <p>
                            A majority of our raw materials are agricultural produce, or derivatives. Through our sustainable procurement practices, we seek to forge strong and long-lasting relationship with the farmers, distillers and regional accumulators to strengthen agricultural productivity and increase yield I a sustainable manner. This will also ensure we continue to offer consistent, quality products to our customers.<br><br>
                            At Van Aroma, our experienced procurement team ensures we procure raw materials that are produced using responsible agricultural techniques. Our in-house agronomists train partner farmers on best practices for the use of organic pesticides and fertilizers, soil management, and improving their farming productivity. <br>
                            <br>
                            We are committed to the highest standard of ethical conduct and social and environmental responsibility which is documented through our sustainable procurement policy.  We expect our suppliers to aspire to these same standards in their business operation. Our Supplier code of conduct sets out the standards expected of all suppliers, and vendors for indirect materials. <br>
                            <br>
                            <b>Case Study - Using Post Extraction biomass for agricultural compost generation.</b><br>
                            In our operations, we generate post-extraction biomass, which is the leftover organic mass after the essential oils and natural extracts have been extracted from the raw material. Due to its partially decomposed nature, this biomass can be converted into compost and act as a fertilizing component, and help in enriching soil quality. At Van Aroma, we supply this post extraction biomass to nearby farmers free-of-cost. This is an exemplary evidence of symbiotic relationship between the industry and the community through a circular economy. Side-streams generated at one side are utilised once again by the farmers for their fields, helping them reduce the use of synthetic fertilizers. 
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" role="tab" id="collapse09_header">
                        <h5>
                          <a data-bs-toggle="collapse" href="#collapse09" aria-expanded="false" aria-controls="collapse09" class="collapsed">
                             Customer Health and Safety
                          </a>
                        </h5>
                      </div>
                      <div id="collapse09" class="collapse" role="tabpanel" aria-labelledby="collapse09_header" data-bs-parent="#accordion02" style="">
                        <div class="card-body pb-5">
                          <p>
                            Van Aroma has in place a food security manual based on PAS 96:2016 standards which focuses on the safety aspects of the manufacturing in the food and flavours sector, in the areas of management, staff, public, facility and operations. Our operations do not involve use of heavy or highly corrosive chemicals. None of our products are classified as PHF (Potentially Hazardous Food). <br>
                            Throughout the production process, our dedicated quality team conduct checks to ensure our products are safe and comply with all regulatory standards.
                          </p>
                          <ul>
                            <li>Adopting the best of the industry production processes with minimized human intervention</li>
                            <li>Use of appropriate PPE and food safety equipment</li>
                            <li>Proper labelling and storage of hazardous / toxic chemicals</li>
                            <li>Frequent health checks for the employees</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="divider-40 divider-lg-150"></div>
</template>
<style src="@/assets/css/about.css" scoped></style>
<script>

export default {
  name: 'SustainabilityPage',data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)

      let oldJS=document.getElementById('reInit');
      if(oldJS){
        oldJS.remove();
      }
      let reInit = document.createElement('script')
      reInit.setAttribute('id', 'reInit')
      reInit.setAttribute('src', 'assets/js/reinit.js')
      document.body.appendChild(reInit)
      document.title = 'Sustainability - Van Aroma';
  },
  methods:{
    playVideo(){
      let video=document.getElementById('headerVideo');
      let btn=document.getElementById('btn-play-video');
      btn.style.display='none';
      video.style.opacity=1;
      video.play();
      video.controls='controls';
    }
  }
}
</script>