<template>
  <section class="header-bg-video dont-copy">
    <a href="#" @click.prevent="playVideo()" id="btn-play-video" class="photoswipe-link-button btn-play-video" data-index="1"></a>
    <video :poster="imageUrl+'new_website/images/facility/van-aroma-hq.jpg'" @click.prevent="playVideo()" id="headerVideo">
        <source src="https://cdn.vanaroma.com/vaweb/van-aroma-video.mp4" type="video/mp4">
    </video>
    <div class="container" style="position:relative;">
      <div class="row video-caption">
        <div class="col-md-8"></div>
        <div class="col-md-4">
            <div class="row ds">
              <div class="col-12 text-center mb-md-5 mb-3">
                <p>Van Aroma was founded in 2006 with a mission to provide 
                <span  class="excerpt">"<i>SUSTAINABLY SOURCED & OPTIMAL QUALITY INDONESIAN NATURAL ESSENTIAL OILS TO THE GLOBAL MARKET</i> "</span></p>
              </div>
              <div class="col-4 text-center">
                  <h1 class="mb-0 text-uppercase"><span data-content="V" class="emptytext">V</span></h1>
                  <p>Value</p>
              </div>
              <div class="col-4 text-center side-border">
                  <h1 class="mb-0 text-uppercase"><span data-content="A" class="emptytext">A</span></h1>
                  <p>Authentic</p>
              </div>
              <div class="col-4 text-center">
                  <h1 class="mb-0 text-uppercase"><span data-content="N" class="emptytext">N</span></h1>
                  <p>Natural</p>
              </div>
            </div>
        </div>
      </div>
      <div class="row px-5 pb-2">
        <div class="col-md-8">
          <div class="owl-carousel organization owl-loaded owl-drag owl-theme"  data-draggable="true" data-nav="false" data-loop="true" data-autoplay="true" data-margin="80" data-items="5" data-responsive-lg="5" data-responsive-md="4" data-responsive-sm="4" data-responsive-xs="2">
            <blockquote>
              <div class="certification-img">
                 <img :src="imageUrl+'new_website/images/fssc-black.png'" alt="fssc">
              </div>
            </blockquote>
            <!-- <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/ecovadis-black.png'" alt="ecovadis">
              </div>
            </blockquote> -->
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/ecovadis-2023.webp'" alt="ecovadis">
              </div>
            </blockquote>
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/halal_va_black.png'" alt="halal">
              </div>
            </blockquote>
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/kosher.png'" alt="kosher">
              </div>
            </blockquote>
            <!-- <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/primaniyarta.png'" alt="primaniyarta">
              </div>
            </blockquote> -->
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/primaniyarta-2022.png'" alt="primaniyarta">
              </div>
            </blockquote>
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/uebt-logo.png'" alt="uebt">
              </div>
            </blockquote>
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/cosmos-logo.png'" alt="cosmos">
              </div>
            </blockquote>
            <blockquote>
              <div class="certification-img">
                <img :src="imageUrl+'new_website/images/iso_sai_logo.webp'" alt="cosmos">
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="ls s-py-90 s-py-xl-150 leaf-wrap dont-copy">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3 class="special-heading">
            <span class="custom-header emptytext">Our Story</span>
            <span>Our Story</span>
          </h3>
           <div class="divider-40"></div>
            <p>We setup our first facility in the city of Padang, West Sumatra. This is where the collection and standardization of essential oils initially took place. At the time of our genesis, Patchouli Oil and Nutmeg Oil were the only two products we dealt with. Our progressive planning quickly led us to expand our services to products such as Clove Oil, Citronella Oil, Vetiver Oil and several other natural extracts from Java and Sulawesi. In 2009, we added our second facility in Jakarta to keep up with the growing demand for our products.
            </p>
            <p>
            This expansion helped us to focus on the production of further refined and standardized products such as the many derivatives of clove oil and variants of patchouli oil. We have now expanded our workforce and broadened our infrastuctural capabilities to include supercritical fluid extraction, reaction units, solvent extraction and steam distillation units to name a few.
            </p>
        </div>
        <div class="col-lg-6">

          <div class="story mt-5">
              <div class="item">
                <div id="timeline">
                  <div>
                    <section class="year">
                      <h3>2006</h3>
                      <section>
                        <ul>
                          <li>Van Aroma Founded on May 24</li>
                        </ul>
                      </section>     
                    </section>

                    <section class="year">
                      <h3>2009</h3>
                      <section>
                        <ul>
                          <li>Moved HQ to Gunung Putri, Bogor</li>
                        </ul>
                      </section>
                    </section>
                    
                    <section class="year">
                      <h3>2011</h3>
                      <section>
                        <ul>
                          <li>Commissioned new dedicated product temperature controlled storage facility. </li>
                        </ul>
                      </section>     
                    </section>

                    <section class="year">
                      <h3>2017</h3>
                      <section>
                        <ul>
                          <li>Van Aroma implemented ERP and CRM solutions to enable business activity streamlining</li>
                        </ul>
                      </section>     
                    </section>

                    <section class="year">
                      <h3>2018</h3>
                      <section>
                        <ul>
                          <li>Installed and commissioned  Fully Automated Pilot scale Supercritical Fluid Extraction using CO2. Capacity: 2 x 5L system.</li>
                        </ul>
                      </section>
                    </section>

                    <section class="year">
                      <h3>2019</h3>
                      <section>
                        <ul>
                          <li>Global Team, and Independent Marketing & Sales</li>
                        </ul>
                      </section>
                    </section>

                    <section class="year">
                      <h3>2021</h3>
                      <section>
                        <ul>
                          <li>Primaniyarta Award 2021</li>
                        </ul>
                      </section>
                    </section>

                    <section class="year">
                      <h3>2022</h3>
                      <section>
                        <ul>
                          <li>Primaniyarta Award 2022</li>
                        </ul>
                      </section>
                    </section>

                    <section class="year">
                      <h3>2023</h3>
                      <section>
                        <ul>
                          <li> Launching Van Aroma Kolaka Phase 2</li>
                        </ul>
                      </section>
                    </section>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="features" class="ls with-leaf-1 dont-copy">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mt-md-5 px-md-5">
          <Splide :options="mainOptions" aria-label="My Favorite Images" ref="mainSplide" class="mt-md-5 mb-md-3">
            <SplideSlide v-for="photo in photos[activeFilter]" :key="photo.id">
              <img :src="imageUrl+photo.url" alt="Sample 1">
            </SplideSlide>
          </Splide>
          <Splide :options="thumbsOptions" aria-label="My Favorite Images" class="thumbsSplide" ref="thumbsSplide">
            <SplideSlide v-for="photo in photos[activeFilter]" :key="photo.id">
              <img :src="imageUrl+photo.url" alt="Sample 1">
            </SplideSlide>
          </Splide>
        </div>

        
        <div class="col-lg-6">
          <div class="divider-50 divider-md-90 divider-xl-100"></div>
          <h3 class="special-heading text-center text-md-start px-md-5">
            <span>Infrastructure</span>
          </h3>
          <div class="divider-50"></div>
          <div class="px-md-5">
            <p>
              Our facilities are equipped with state-of-the-art infrastructure and technologies including SCFE CO2 Extraction, Fractional Distillation Units, Molecular Distillation Units, Reaction Units, Solvent Extraction Units, Steam Distillation Units and a long list of specialised apparatus needed to provide premium grades of essential oils that are put through 
                through the gamut of our stringent quality control to make sure that the standards of our various products are optimal and consistent.
                
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
   <section class="overflow-visible parallax mt-5 mb-5 dont-copy">
    <div class="container mt-5">
      <div class="row justify-content-center align-center py-5 mb-5">
        <div class="col-lg-12 mb-lg-5">
          <h4 class="special-heading mb-5 text-center text-md-start"><span>Organizations</span></h4>
          <div class="divider-40 divider-lg-0"></div>
        </div>
        <div class="owl-carousel organization owl-loaded owl-drag owl-theme">
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/ifeat-logo.png'" alt="organization">
            </div>
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/dai-logo.png'" alt="organization">
            </div>
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/indessota-logo.png'" alt="organization">
            </div>
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/rspo_logo.png'" alt="organization">
            </div>
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/un_global_compact_logo.jpeg'" alt="organization">
            </div>  
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/UEBT_Logo-Green-Member.png'" alt="organization">
            </div>
            <div class="organizations-img">
              <a href="https://profiles.dunsregistered.com/DunsRegisteredProfileAnywhere.aspx?key1=3149323&PaArea=Mail" target="_blank">
                <img :src="imageUrl+'new_website/images/dnb-logo.png'" alt="organization">
              </a>
            </div>
            <div class="organizations-img">
              <img :src="imageUrl+'new_website/images/swisscham.png'" alt="organization">
            </div>
            <div class="organizations-img">
              <a href="https://efeo.eu/about-us/allmembers/indonesia-van-aroma/" target="_blank">
                <img :src="imageUrl+'new_website/images/EFEO_Logo.webp'" alt="organization">
                </a>
            </div>
            <div class="organizations-img">
              <a href="https://www.amcham.or.id/en/home" target="_blank">
                <img :src="imageUrl+'new_website/images/amcham.webp'" alt="organization">
                </a>
            </div>
        </div>
      </div>
    </div>
  </section>

  <div class="divider-40 divider-lg-150"></div>
</template>
<style src="@/assets/css/about.css" scoped></style>
<style>
  .splide__arrow.custom{
    border-radius: 0 !important;
  }
  .splide__arrow.custom:hover{
    background-color: #49d2a5;
  }
</style>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
  name: 'AboutView',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
      activeFilter :'qc',
      activePhoto : 1,
      photos:{
        qc:[
          // {
          //   id:0,
          //   url:'new_website/images/facility/van_aroma_qc_1.jpg'
          // },
          {
            id:1,
            url:'new_website/images/facility/van_aroma_qc_2.jpg'
          },
          {
            id:2,
            url:'new_website/images/facility/van_aroma_qc_3.jpg'
          },
          {
            id:3,
            url:'new_website/images/facility/van_aroma_qc_4.jpg'
          },
          {
            id:4,
            url:'new_website/images/facility/van_aroma_scfe_1.jpg'
          },
          {
            id:5,
            url:'new_website/images/facility/van_aroma_scfe_2.jpg'
          },
          {
            id:6,
            url:'new_website/images/facility/van_aroma_scfe_3.jpg'
          },
          {
            id:7,
            url:'new_website/images/facility/van_aroma_scfe_4.jpg'
          },
          {
            id:8,
            url:'new_website/images/facility/8.jpg'
          },
          // {
          //   id:9,
          //   url:'new_website/images/facility/5.jpg'
          // }
        ],
        scfe:[
          {
            id:0,
            url:'new_website/images/facility/van_aroma_scfe_1.jpg'
          },
          {
            id:1,
            url:'new_website/images/facility/van_aroma_scfe_2.jpg'
          },
          {
            id:2,
            url:'new_website/images/facility/van_aroma_scfe_3.jpg'
          },
          {
            id:3,
            url:'new_website/images/facility/van_aroma_scfe_4.jpg'
          }
        ]
      },
      mainOptions:{
        type      : 'loop',
        perPage   : 1,
        perMove   : 1,
        gap       : '1rem',
        pagination: false,
        lazyLoad  : true,
        classes: {
         arrow : 'splide__arrow custom'
        }
      },
      thumbsOptions: {
        type        : 'slide',
        rewind      : true,
        trimSpace: 'move',
        gap         : '0.7rem',
        pagination  : false,
        perPage     : 4,
        fixedHeight : 80,
        cover       : true,
        focus       : 'center',
        isNavigation: true,
        updateOnMove: true,
        arrows      : false,
        lazyLoad    : true,
      },

    }
  },
  methods:{
    filter(category){
      this.activeFilter=category;
    },
    changeActive(id){
      this.activePhoto=id
    },
    playVideo(){
      let video=document.getElementById('headerVideo');
      let btn=document.getElementById('btn-play-video');
      btn.style.display='none';
      video.style.opacity=1;
      video.play();
      video.controls='controls';
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)

      let oldJS=document.getElementById('aboutJS');
      if(oldJS){
        oldJS.remove();
      }
      let aboutJS = document.createElement('script')
      aboutJS.setAttribute('id', 'aboutJS')
      aboutJS.setAttribute('src', 'assets/js/about.js')
      document.body.appendChild(aboutJS)
      document.title = 'About Us - Van Aroma';

      //splideSplider
      const thumbsSplide = this.$refs.thumbsSplide
      const mainSplide =this.$refs.mainSplide
      mainSplide.sync(thumbsSplide.splide)
  },
  components: {
    Splide,
    SplideSlide,
  },
  
}
</script>