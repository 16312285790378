<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>KAFFIR LIME</h3>
                <h5>Citrus hystrix</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/pic-kaffirlime-01.jpg'" class="img-source p-4" alt="kaffir lime oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction to Kaffir Lime Oil</h4>
                        <p>Kaffir Lime is citrus fruit very similiar to lime, but it is small sized and has a uniquely rough, bumpy green exterior</p>
                        <p>Unlike a regular lime tree, its leaf actually resembles two connected leaves. The leaves have a very strong, characteristic fragrance that cannot easily be substituted by other spices or even from oils extracted from other plants in the same family.
                        </p>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-12">
                              <h4 class="mt-5 mb-5 text-center">Regions of Cultivation</h4>
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/map-kaffirlime.jpg'" class="w-100" alt="kaffir lime oil indonesia">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6">
                        <h4>A brief insight into the Kaffir Lime Oil Industry in Indonesia.</h4>
                        <p> Indonesian kaffir lime is primarily procured from the East Java Region. However, the plant grows in several other tropical location such Madagaskar, Thailand and a few other Southeast Asian Countries</p>
                        <p>In comparison to some of the other Indonesian essential oils like Patchouli Oil, Clove Oil and Nutmeg Oil; Kaffir Lime Oil is only dealt with, and exported in small quantities. The export quantities lie in the range of 2 to 3 Metric Tons, and tend to remain stable with little or no fluctuation in quantities or prices.</p>
                    </div>
                    <div class="col-md-6">
                        <p>The main compound responsible for the characteristic aroma of Kaffir Lime Leaves is citronellal</p>
                        <img :src="imageUrl+'new_website/images/botanical-source/ill-kaffirlime-02.png'" class="img-source p-4" alt="kaffir lime oil indonesia">
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Kaffir Lime Oil ?</h4>
                        <p class="text-center">
                           We get all our Kaffir Lime Oil from local Indonesian farmers and accumulators who harvest and process the crop through steam distillation to produce a crude form of Kaffir Lime Oil.
                        <br>KAFFIR LIME OIL DISTILLATION IN 3 STEPS
                        </p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_5.jpg'" alt="kaffir lime oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>PLANTATION</h5>
                                                <p>Kaffir Lime seedlings are planted in warm, temperate spaces. Leaves should be clipped often to ensure proper growth.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_5.jpg'" alt="kaffir lime oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>COLECTION OF KAFFIR LIME LEAVES</h5>
                                                <p> On reaching maturity, the leaves are plucked and sent for distillation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3_3.jpg'" alt="kaffir lime oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>DISTILATION</h5>
                                                <p>
                                                    Fresh green leaves of the Kaffir Lime tree are steam distilled to obtain oil.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_5.jpg'" alt="kaffir lime oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>KAFFIR LIME OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="c3c7e437-5bb6-4ce7-9998-2991801b2d37"/>

         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Kaffir Lime Product List</h4>
                    </div>
                    <ProductList source="c3c7e437-5bb6-4ce7-9998-2991801b2d37"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'KaffirLime',
   data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Kaffir Lime - Van Aroma';
  }
}
</script>
