<template>
  <!-- Notes : untuk edit position gunakan v-drag / draggable -->
  <div class="maps-body">
     <div class="side-bar" v-if="ActiveCountry=='indonesia'">
        <ul class="maps-nav" id="maps-nav">
          <li @click.prevent="collapseSidebar()" id="headerMenu">
            <a href="#" id="titleMenu" v-if="activeProduct == ''">
              <div class="py-2 d-none d-md-block"></div>
              <span>Select Source</span>
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'patchouli'">
              <img src="https://cdn.vanaroma.com/new_website/maps/patchouli-vector.jpeg" class="nav-icon" alt="">
              Patchouli
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'clove'">
                <img src="https://cdn.vanaroma.com/new_website/maps/clove-vector.jpeg" class="nav-icon" alt="">
                Clove
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'nutmeg'">
                <img src="https://cdn.vanaroma.com/new_website/maps/nutmeg-vector.jpeg" class="nav-icon" alt="">
                Nutmeg
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'citronella'">
                <img src="https://cdn.vanaroma.com/new_website/maps/citronella-vector.jpeg" class="nav-icon" alt="">
                Citronella
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'kaffirlime'">
                <img src="https://cdn.vanaroma.com/new_website/maps/kaffir-lime-vector.jpeg" class="nav-icon" alt="">
                Kaffir Lime
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'cajeput'">
                <img src="https://cdn.vanaroma.com/new_website/maps/cajeput-vector.jpeg" class="nav-icon" alt="">
                Cajeput
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'cananga'">
                <img src="https://cdn.vanaroma.com/new_website/maps/cananga-vector.jpeg" class="nav-icon" alt="">
                Cananga
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'coffee'">
                <img src="https://cdn.vanaroma.com/new_website/maps/coffee-vector.jpeg" class="nav-icon" alt="">
                Coffee
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'cacao'">
                <img src="https://cdn.vanaroma.com/new_website/maps/cacao-vector.jpeg" class="nav-icon" alt="">
                Cacao
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'ginger'">
                <img src="https://cdn.vanaroma.com/new_website/maps/ginger-vector.jpeg" class="nav-icon" alt="">
                Ginger
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'tobacco'">
                <img src="https://cdn.vanaroma.com/new_website/maps/tobacco-vector.jpeg" class="nav-icon" alt="">
                Tobacco
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'cassiavera'">
                <img src="https://cdn.vanaroma.com/new_website/maps/cassiavera-vector.jpeg" class="nav-icon" alt="">
                Cassiavera
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'turmeric'">
                <img src="https://cdn.vanaroma.com/new_website/maps/turmeric-vector.jpeg" class="nav-icon" alt="">
                Turmeric
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'benzoin'">
                <img src="https://cdn.vanaroma.com/new_website/maps/benzoin-vector.jpeg" class="nav-icon" alt="">
                Benzoin Sumatra
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'blackpepper'">
                <img src="https://cdn.vanaroma.com/new_website/maps/black-pepper-vector.jpeg" class="nav-icon" alt="">
                Black Pepper
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'vetiver'">
                <img src="https://cdn.vanaroma.com/new_website/maps/vetiver-vector.jpeg" class="nav-icon" alt="">
                Vetiver
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'cubeb'">
                <img src="https://cdn.vanaroma.com/new_website/maps/cubeb-vector.jpeg" class="nav-icon" alt="">
                Cubeb
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'betelleaf'">
                <img src="https://cdn.vanaroma.com/new_website/maps/betel-leaf-vector.jpeg" class="nav-icon" alt="">
                Betel Leaf
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'gurjunbalsam'">
                <img src="https://cdn.vanaroma.com/new_website/maps/gurjun-balsam-vector.jpeg" class="nav-icon" alt="">
                Gurjun Balsam
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'massoia'">
                <img src="https://cdn.vanaroma.com/new_website/maps/massoia-vector.jpeg" class="nav-icon" alt="">
                Massoia Bark
            </a>
            <a href="#" id="titleMenu" v-if="activeProduct == 'vanilla'">
                <img src="https://cdn.vanaroma.com/new_website/maps/vanilla-vector.jpeg" class="nav-icon" alt="">
                Vanilla
            </a>
            <a href="#" class="arrow float-end">
              <i class="fa fa-chevron-up"></i>
            </a>
          </li>
          <li class="first-item"></li>
          <li v-if="activeProduct != 'patchouli'">
            <a href="#" @click.prevent="changeActiveProduct($event,'patchouli')">
              <img src="https://cdn.vanaroma.com/new_website/maps/patchouli-vector.jpeg" class="nav-icon" alt="">
              Patchouli
            </a>
          </li>
          <li v-if="activeProduct != 'clove'">
            <a href="#" @click.prevent="changeActiveProduct($event,'clove')">
              <img src="https://cdn.vanaroma.com/new_website/maps/clove-vector.jpeg" class="nav-icon" alt="">
              Clove
            </a>
          </li>
          <li v-if="activeProduct != 'nutmeg'">
            <a href="#" @click.prevent="changeActiveProduct($event,'nutmeg')">
              <img src="https://cdn.vanaroma.com/new_website/maps/nutmeg-vector.jpeg" class="nav-icon" alt="">
              Nutmeg
            </a>
          </li>
          <li v-if="activeProduct != 'citronella'">
            <a href="#" @click.prevent="changeActiveProduct($event,'citronella')">
              <img src="https://cdn.vanaroma.com/new_website/maps/citronella-vector.jpeg" class="nav-icon" alt="">
              Citronella
            </a>
          </li>
          <li v-if="activeProduct != 'kaffirlime'">
            <a href="#" @click.prevent="changeActiveProduct($event,'kaffirlime')">
              <img src="https://cdn.vanaroma.com/new_website/maps/kaffir-lime-vector.jpeg" class="nav-icon" alt="">
              Kaffir Lime
            </a>
          </li>
          <li v-if="activeProduct != 'cajeput'">
            <a href="#" @click.prevent="changeActiveProduct($event,'cajeput')">
              <img src="https://cdn.vanaroma.com/new_website/maps/cajeput-vector.jpeg" class="nav-icon" alt="">
              Cajeput
            </a>
          </li>
          <li v-if="activeProduct != 'cananga'">
            <a href="#" @click.prevent="changeActiveProduct($event,'cananga')">
              <img src="https://cdn.vanaroma.com/new_website/maps/cananga-vector.jpeg" class="nav-icon" alt="">
              Cananga
            </a>
          </li>
          <li v-if="activeProduct != 'coffee'">
            <a href="#" @click.prevent="changeActiveProduct($event,'coffee')">
              <img src="https://cdn.vanaroma.com/new_website/maps/coffee-vector.jpeg" class="nav-icon" alt="">
              Coffee
            </a>
          </li>
          <li v-if="activeProduct != 'cacao'">
            <a href="#" @click.prevent="changeActiveProduct($event,'cacao')">
              <img src="https://cdn.vanaroma.com/new_website/maps/cacao-vector.jpeg" class="nav-icon" alt="">
              Cacao
            </a>
          </li>
          <li v-if="activeProduct != 'ginger'">
            <a href="#" @click.prevent="changeActiveProduct($event,'ginger')">
              <img src="https://cdn.vanaroma.com/new_website/maps/ginger-vector.jpeg" class="nav-icon" alt="">
              Ginger
            </a>
          </li>
          <li v-if="activeProduct != 'tobacco'">
            <a href="#" @click.prevent="changeActiveProduct($event,'tobacco')">
              <img src="https://cdn.vanaroma.com/new_website/maps/tobacco-vector.jpeg" class="nav-icon" alt="">
              Tobacco
            </a>
          </li>
          <li v-if="activeProduct != 'cassiavera'">
            <a href="#" @click.prevent="changeActiveProduct($event,'cassiavera')">
              <img src="https://cdn.vanaroma.com/new_website/maps/cassiavera-vector.jpeg" class="nav-icon" alt="">
              Cassiavera
            </a>
          </li>
          <li v-if="activeProduct != 'turmeric'">
            <a href="#" @click.prevent="changeActiveProduct($event,'turmeric')">
              <img src="https://cdn.vanaroma.com/new_website/maps/turmeric-vector.jpeg" class="nav-icon" alt="">
              Turmeric
            </a>
          </li>
          <li v-if="activeProduct != 'benzoin'">
            <a href="#" @click.prevent="changeActiveProduct($event,'benzoin')">
              <img src="https://cdn.vanaroma.com/new_website/maps/benzoin-vector.jpeg" class="nav-icon" alt="">
              Benzoin Sumatra
            </a>
          </li>
          <li v-if="activeProduct != 'blackpepper'">
            <a href="#" @click.prevent="changeActiveProduct($event,'blackpepper')">
              <img src="https://cdn.vanaroma.com/new_website/maps/black-pepper-vector.jpeg" class="nav-icon" alt="">
              Black Pepper
            </a>
          </li>
          <li v-if="activeProduct != 'vetiver'">
            <a href="#" @click.prevent="changeActiveProduct($event,'vetiver')">
              <img src="https://cdn.vanaroma.com/new_website/maps/vetiver-vector.jpeg" class="nav-icon" alt="">
              Vetiver
            </a>
          </li>
          <li v-if="activeProduct != 'cubeb'">
            <a href="#"  @click.prevent="changeActiveProduct($event,'cubeb')">
              <img src="https://cdn.vanaroma.com/new_website/maps/cubeb-vector.jpeg" class="nav-icon" alt="">
              Cubeb
            </a>
          </li>
          <li v-if="activeProduct != 'betelleaf'">
            <a href="#" @click.prevent="changeActiveProduct($event,'betelleaf')">
              <img src="https://cdn.vanaroma.com/new_website/maps/betel-leaf-vector.jpeg" class="nav-icon" alt="">
              Betel Leaf
            </a>
          </li>
          <li v-if="activeProduct != 'gurjunbalsam'">
            <a href="#" @click.prevent="changeActiveProduct($event,'gurjunbalsam')">
              <img src="https://cdn.vanaroma.com/new_website/maps/gurjun-balsam-vector.jpeg" class="nav-icon" alt="">
              Gurjun Balsam
            </a>
          </li>
          <li v-if="activeProduct != 'massoia'">
            <a href="#" @click.prevent="changeActiveProduct($event,'massoia')">
              <img src="https://cdn.vanaroma.com/new_website/maps/massoia-vector.jpeg" class="nav-icon" alt="">
              Massoia Bark
            </a>
          </li>
          <li v-if="activeProduct != 'vanilla'">
            <a href="#" @click.prevent="changeActiveProduct($event,'vanilla')">
              <img src="https://cdn.vanaroma.com/new_website/maps/vanilla-vector.jpeg" class="nav-icon" alt="">
              Vanilla
            </a>
          </li>
        </ul>
     </div>
     
     <div class="main-content">
        <div class="option-country">
          <button :class="{ active: ActiveCountry == 'indonesia' }" @click="changeCountry('indonesia')">Indonesia</button>
          <button :class="{ active: ActiveCountry == 'usa' }" @click="changeCountry('usa')">USA</button>
          <button :class="{ active: ActiveCountry == 'europe' }" @click="changeCountry('europe')">Europe</button>
        </div>
        <div class="maps-container indonesia" v-if="ActiveCountry == 'indonesia'">
          <div class="point-box" v-if="activeProduct == 'patchouli'">
              <span class="point blink-circle patchouli-1"></span>
              <span class="point blink-circle patchouli-2"></span>
              <span class="point blink-circle patchouli-3 lg"></span>
              <span class="point blink-circle xs patchouli-4"></span>
              <span class="point blink-circle sm patchouli-5"></span>
              <span class="point blink-circle lg patchouli-6"></span>
              <span class="point blink-circle lg patchouli-7"></span>
              <span class="point blink-circle patchouli-8"></span>
              <span class="point blink-circle patchouli-9"></span>
              <span class="point blink-circle patchouli-10"></span>
              <span class="point blink-circle patchouli-11"></span>
              <span class="point blink-circle patchouli-12"></span>
              <span class="point blink-circle xs patchouli-13"></span>
              <span class="point blink-circle xs patchouli-14"></span>
              <span class="point blink-circle xs patchouli-15"></span>
              <span class="point blink-circle patchouli-16"></span>
              <span class="point blink-circle lg patchouli-17"></span>
              <span class="point blink-circle patchouli-18"></span>
              <span class="point blink-circle patchouli-19"></span>
              <span class="point blink-circle patchouli-20"></span>
              <span class="point blink-circle sm patchouli-21"></span>
              <span class="point blink-circle patchouli-22"></span>
              <span class="point blink-circle xs patchouli-23"></span>
              <span class="point blink-circle xs patchouli-24"></span>
              <span class="point blink-circle xs patchouli-25"></span>
              <span class="point blink-circle xs patchouli-26"></span>
              <span class="point blink-circle patchouli-27"></span>
              <span class="point blink-circle patchouli-28"></span>
              <span class="point blink-circle patchouli-29"></span>
              <span class="point blink-circle patchouli-30"></span>
              <span class="point blink-circle patchouli-31"></span>
              <span class="point blink-circle lg patchouli-32"></span>
              <span class="point blink-circle patchouli-33"></span>
              <span class="point blink-circle lg patchouli-34"></span>
              <span class="point blink-circle sm patchouli-35"></span>
              <span class="point blink-circle xs patchouli-36"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'clove'">
            <span class="point blink-circle clove-1"></span>
            <span class="point blink-circle clove-2"></span>
            <span class="point blink-circle clove-3"></span>
            <span class="point blink-circle lg clove-4"></span>
            <span class="point blink-circle sm clove-5"></span>
            <span class="point blink-circle sm clove-6"></span>
            <span class="point blink-circle sm clove-7"></span>
            <span class="point blink-circle sm clove-8"></span>
            <span class="point blink-circle sm clove-9"></span>
            <span class="point blink-circle sm clove-10"></span>
            <span class="point blink-circle sm clove-11"></span>
            <span class="point blink-circle sm clove-12"></span>
            <span class="point blink-circle sm clove-13"></span>
            <span class="point blink-circle sm clove-14"></span>
            <span class="point blink-circle xxs clove-15"></span>
            <span class="point blink-circle clove-16"></span>
            <span class="point blink-circle clove-17"></span>
            <span class="point blink-circle lg clove-18"></span>
            <span class="point blink-circle clove-19"></span>
            <span class="point blink-circle xs clove-20"></span>
            <span class="point blink-circle xs clove-21"></span>
            <span class="point blink-circle xs clove-22"></span>
            <span class="point blink-circle xs clove-23"></span>
            <span class="point blink-circle xs clove-24"></span>
            <span class="point blink-circle xs clove-25"></span>
            <span class="point blink-circle xs clove-26"></span>
            <span class="point blink-circle xs clove-27"></span>
            <span class="point blink-circle xs clove-28"></span>
            <span class="point blink-circle xs clove-29"></span>
            <span class="point blink-circle xs clove-30"></span>
            <span class="point blink-circle xs clove-31"></span>
            <span class="point blink-circle xs clove-32"></span>
            <span class="point blink-circle xs clove-33"></span>
            <span class="point blink-circle xs clove-34"></span>
            <span class="point blink-circle xs clove-35"></span>
            <span class="point blink-circle xs clove-36"></span>
            <span class="point blink-circle xs clove-37"></span>
            <span class="point blink-circle xs clove-38"></span>
            <span class="point blink-circle xs clove-39"></span>
            <span class="point blink-circle xs clove-40"></span>
            <span class="point blink-circle xxs clove-41"></span>
            <span class="point blink-circle xxs clove-42"></span>
            <span class="point blink-circle xxs clove-43"></span>
            <span class="point blink-circle xxs clove-44"></span>
            <span class="point blink-circle xxs clove-45"></span>
            <span class="point blink-circle xxs clove-46"></span>
            <span class="point blink-circle xxs clove-47"></span>
            <span class="point blink-circle xxs clove-48"></span>
            <span class="point blink-circle xxs clove-49"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'nutmeg'">
            <span class="point blink-circle nutmeg-1"></span>
            <span class="point blink-circle sm nutmeg-2"></span>
            <span class="point blink-circle sm nutmeg-3"></span>
            <span class="point blink-circle nutmeg-4"></span>
            <span class="point blink-circle nutmeg-5"></span>
            <span class="point blink-circle sm nutmeg-6"></span>
            <span class="point blink-circle nutmeg-7"></span>
            <span class="point blink-circle sm nutmeg-8"></span>
            <span class="point blink-circle xs nutmeg-9"></span>
            <span class="point blink-circle xs nutmeg-10"></span>
            <span class="point blink-circle xs nutmeg-11"></span>
            <span class="point blink-circle sm nutmeg-12"></span>
            <span class="point blink-circle sm nutmeg-13"></span>
            <span class="point blink-circle xs nutmeg-14"></span>
            <span class="point blink-circle xs nutmeg-15"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'citronella'">
            <span class="point blink-circle citronella-1"></span>
            <span class="point blink-circle lg citronella-2"></span>
            <span class="point blink-circle citronella-3"></span>
            <span class="point blink-circle xs citronella-4"></span>
            <span class="point blink-circle citronella-5"></span>
            <span class="point blink-circle lg citronella-6"></span>
            <span class="point blink-circle citronella-7"></span>
            <span class="point blink-circle xs citronella-8"></span>
            <span class="point blink-circle lg citronella-9"></span>
            <span class="point blink-circle citronella-10"></span>
            <span class="point blink-circle citronella-11"></span>
            <span class="point blink-circle sm citronella-12"></span>
            <span class="point blink-circle lg citronella-13"></span>
            <span class="point blink-circle citronella-14"></span>
            <span class="point blink-circle xs citronella-15"></span>
            <span class="point blink-circle xs citronella-16"></span>
            <span class="point blink-circle citronella-17"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'kaffirlime'">
            <span class="point blink-circle kaffirlime-1"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'cajeput'">
            <span class="point blink-circle sm cajeput-1"></span>
            <span class="point blink-circle lg cajeput-2"></span>
            <span class="point blink-circle sm cajeput-3"></span>
            <span class="point blink-circle xxs cajeput-4"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'cananga'">
            <span class="point blink-circle sm cananga-1"></span>
          </div>
          <div class="point-box"  v-if="activeProduct == 'coffee'">
            <span class="point blink-circle lg coffee-1"></span>
            <span class="point blink-circle coffee-2"></span>
            <span class="point blink-circle lg coffee-3"></span>
            <span class="point blink-circle sm coffee-4"></span>
            <span class="point blink-circle coffee-5"></span>
            <span class="point blink-circle xs coffee-6"></span>
            <span class="point blink-circle xs coffee-7"></span>
            <span class="point blink-circle coffee-8"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'cacao'">
             <span class="point blink-circle cacao-1"></span>
             <span class="point blink-circle lg cacao-2"></span>
             <span class="point blink-circle lg cacao-3"></span>
             <span class="point blink-circle lg cacao-4"></span>
             <span class="point blink-circle cacao-5"></span>
             <span class="point blink-circle sm cacao-6"></span>
             <span class="point blink-circle sm cacao-7"></span>
             <span class="point blink-circle sm cacao-8"></span>
             <span class="point blink-circle xs cacao-9"></span>
             <span class="point blink-circle sm cacao-10"></span>
             <span class="point blink-circle lg cacao-11"></span>
             <span class="point blink-circle lg cacao-12"></span>
             <span class="point blink-circle lg cacao-13"></span>
              <span class="point blink-circle cacao-14"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'ginger'">
             <span class="point blink-circle lg ginger-1"></span>
             <span class="point blink-circle lg ginger-2"></span>
             <span class="point blink-circle lg ginger-3"></span>
             <span class="point blink-circle sm ginger-4"></span>
             <span class="point blink-circle sm ginger-5"></span>
             <span class="point blink-circle lg ginger-6"></span>
             <span class="point blink-circle ginger-7"></span>
             <span class="point blink-circle xs ginger-8"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'tobacco'">
             <span class="point blink-circle xxl tobacco-1"></span>
             <span class="point blink-circle xs tobacco-2"></span>
             <span class="point blink-circle sm tobacco-3"></span>
             <span class="point blink-circle lg tobacco-4"></span>
             <span class="point blink-circle sm tobacco-5"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'cassiavera'">
             <span class="point blink-circle cassiavera-1"></span>
             <span class="point blink-circle sm cassiavera-2"></span>
             <span class="point blink-circle sm cassiavera-3"></span>
             <span class="point blink-circle lg cassiavera-4"></span>
             <span class="point blink-circle sm cassiavera-5"></span>
             <span class="point blink-circle sm cassiavera-6"></span>
             <span class="point blink-circle cassiavera-7"></span>
             <span class="point blink-circle lg cassiavera-8"></span>
             <span class="point blink-circle lg cassiavera-9"></span>
             <span class="point blink-circle xxl cassiavera-10"></span>
             <span class="point blink-circle cassiavera-11"></span>
             <span class="point blink-circle cassiavera-12"></span>
             <span class="point blink-circle cassiavera-13"></span>
             <span class="point blink-circle cassiavera-14"></span>
             <span class="point blink-circle cassiavera-15"></span>
             <span class="point blink-circle xl cassiavera-16"></span>
             <span class="point blink-circle sm  cassiavera-17"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'turmeric'">
             <span class="point blink-circle lg turmeric-1"></span>
             <span class="point blink-circle turmeric-2"></span>
             <span class="point blink-circle lg turmeric-3"></span>
             <span class="point blink-circle sm turmeric-4"></span>
             <span class="point blink-circle turmeric-5"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'benzoin'">
             <span class="point blink-circle xl benzoin-1"></span>
             <span class="point blink-circle sm benzoin-2"></span>
             <span class="point blink-circle sm benzoin-3"></span>
             <span class="point blink-circle benzoin-4"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'blackpepper'">
             <span class="point blink-circle xxs blackpepper-1"></span>
             <span class="point blink-circle blackpepper-2"></span>
             <span class="point blink-circle xl blackpepper-3"></span>
             <span class="point blink-circle blackpepper-4"></span>
             <span class="point blink-circle xl blackpepper-5"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'vetiver'">
             <span class="point blink-circle vetiver-1"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'cubeb'">
             <span class="point blink-circle cubeb-1"></span>
             <span class="point blink-circle xl cubeb-2"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'betelleaf'">
             <span class="point blink-circle betelleaf-1"></span>
             <span class="point blink-circle xxl betelleaf-2"></span>
             <span class="point blink-circle betelleaf-3"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'gurjunbalsam'">
             <span class="point blink-circle xs gurjunbalsam-1"></span>
          </div>
          <div class="point-box" v-if="activeProduct == 'massoia'">
             <span class="point blink-circle massoia-1"></span>
             <span class="point blink-circle sm massoia-2"></span>
             <span class="point blink-circle massoia-3"></span>
          </div>
           <div class="point-box" v-if="activeProduct == 'vanilla'">
             <span class="point blink-circle sm vanilla-1"></span>
             <span class="point blink-circle sm vanilla-2"></span>
             <span class="point blink-circle sm vanilla-3"></span>
             <span class="point blink-circle sm vanilla-4"></span>
             <span class="point blink-circle sm vanilla-5"></span>
             <span class="point blink-circle sm vanilla-6"></span>
             <span class="point blink-circle sm vanilla-7"></span>
             <span class="point blink-circle sm vanilla-8"></span>
             <span class="point blink-circle sm vanilla-9"></span>
             <span class="point blink-circle sm vanilla-10"></span>
             <span class="point blink-circle xs vanilla-11"></span>
             <span class="point blink-circle xs vanilla-12"></span>
             <span class="point blink-circle xs vanilla-13"></span>
             <span class="point blink-circle sm vanilla-14"></span>
             <span class="point blink-circle sm vanilla-15"></span>
             <span class="point blink-circle sm vanilla-16"></span>
             <span class="point blink-circle sm vanilla-17"></span>
            <span class="point blink-circle sm vanilla-18"></span>
          </div>
          <div class="point-box">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point bogor-point" alt="essential oil indonesia" @click="showLocation('bogor')" :class="{ active: activeLocation == 'bogor' }">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point padang-point" alt="essential oil indonesia" @click="showLocation('padang')" :class="{ active: activeLocation == 'padang' }">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point makassar-point" alt="essential oil indonesia" @click="showLocation('makassar')" :class="{ active: activeLocation == 'makassar' }">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point kolaka-point" alt="essential oil indonesia" @click="showLocation('kolaka')" :class="{ active: activeLocation == 'kolaka' }">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point ampana-point" alt="essential oil indonesia" @click="showLocation('ampana')" :class="{ active: activeLocation == 'ampana' }">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point kotamobagu-point" alt="essential oil indonesia" @click="showLocation('kotamobagu')" :class="{ active: activeLocation == 'kotamobagu' }">
          </div>
          
        </div>
        <div class="maps-container usa" v-if="ActiveCountry == 'usa'">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point new-jersey-point" alt="essential oil indonesia" @click="showLocation('new_jersey')" :class="{ active: activeLocation == 'new_jersey' }">
        </div>
        <div class="maps-container europe" v-if="ActiveCountry == 'europe'">
              <img src="https://cdn.vanaroma.com/new_website/maps/marker-icon.png" class="point office-point rotterdam-point" alt="essential oil indonesia" @click="showLocation('rotterdam')" :class="{ active: activeLocation == 'rotterdam' }">
        </div>
     </div>

     <Transition name="slide-fade" mode="out-in">
      <div class="maps-card-info office-location" v-if="activeLocation">
          <Transition name="slide-fade" mode="out-in">
            <div v-if="activeLocation == 'bogor'">
                <div class="p-3">
                    <button type="button" @click.prevent="copyAddress('addressJakarta')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressJakarta">
                      <b class="mb-4"> Van Aroma (HQ - Office & Factory) </b>
                      <p class="mb-2"> Jl. Raya Cicadas No.16,<br>
                          RT.4/RW.4, Cicadas, Kec. Gn. Putri, <br>
                          Bogor, Jawa Barat 16964, <br>
                          Indonesia </p>
                    </div>
                    <div class="mb-4 d-block d-md-none pt-2">
                      <a href="https://www.google.com/maps/dir/?api=1&destination=-6.433594,106.927494&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                    </div>
                    <div class="d-none d-md-block">
                      <div class="mt-2">
                          <span><i class="fa fa-phone"></i></span>
                          <a href="tel:+62218677003" target="_blank" class="address-link"> +62 21 - 867 7003 </a>
                      </div>
                      <div class="mt-2">
                          <span><i class="fab fa-whatsapp"></i></span>
                          <a href="https://wa.me/14124632627" target="_blank" class="address-link"> +1 412 463 2627 </a>
                      </div>
                      <div class="mt-2">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:info@vanaroma.com" target="_blank" class="address-link">info@vanaroma.com</a>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeLocation == 'kolaka'">
              <div class="p-3">
                  <button type="button" @click.prevent="copyAddress('addressKolaka')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressKolaka">
                      <b class="mb-4"> Van Aroma - Kolaka </b>
                      <p class="mb-2"> Jl. Poros Kolaka Wolo, Mangolo, Latambaga,<br>
                      Kab. Kolaka, Sulawesi Tenggara 93512,<br>
                      Indonesia </p>
                    </div>
                  <div class="mb-4 d-block d-md-none pt-2">
                    <a href="https://www.google.com/maps/dir/?api=1&destination=-4.026706,121.548805&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                  </div>
                  <div class="d-none d-md-block">
                    <div class="mt-2">
                        <span>
                            <i class="fa fa-phone">
                            </i></span>
                        <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                    </div>
                    <div class="mt-2">
                        <span>
                            <i class="fab fa-whatsapp">
                            </i></span>
                        <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                    </div>
                  </div>
              </div>
            </div>
            <div v-else-if="activeLocation == 'makassar'">
              <div class="p-3">
                  <button type="button" @click.prevent="copyAddress('addressMakassar')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                  <div class="address-text" id="addressMakassar">
                    <b class="mb-4"> Van Aroma - Makassar </b>
                    <p class="mb-2">JL. Ir Sutami No. 38 Kawasan Pergudangan Parangloe Indah (Natura Cluster 1)<br>
                    Block E No. 8, Kapasa Raya, Bira, Tamalanrea,<br>
                    Kota Makassar, Sulawesi Selatan 90244,<br>
                    Indonesia</p>
                  </div>
                  <div class="mb-4 d-block d-md-none pt-2">
                    <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Makassar+(Parangloe)&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                  </div>
                  <div class="d-none d-md-block">
                    <div class="mt-2">
                        <span><i class="fa fa-phone"></i></span>
                        <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                    </div>
                    <div class="mt-2">
                        <span>
                            <i class="fab fa-whatsapp">
                            </i></span>
                        <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                    </div>
                  </div>
              </div>
            </div>
            <div v-else-if="activeLocation == 'ampana'">
                <div class="p-3">
                  <button type="button" @click.prevent="copyAddress('addressAmpana')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressAmpana">
                      <b class="mb-4"> Van Aroma - Ampana </b>
                      <p class="mb-2">JL. Moh. Hatta No. 138, Muara Toba, Ratolindo,
                      <br>Kab. Tojo Una Una, Sulawesi Tengah 94683,
                      <br>Indonesia </p>
                    </div>
                    <div class="mb-4 d-block d-md-none pt-2">
                      <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Ampana,+PT.+Van+Aroma,+Jl.+Moh.+Hatta+No.+138,+RT.+001+%2F+RW.001,+Kel.+Muara+Toba+Uentanaga+Bawah,+Kab,+Uentanaga+Bawah,+Kec.+Ampana+Kota,+Kabupaten+Tojo+Una-Una,+Sulawesi+Tengah+94683&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                    </div>
                    <div class="d-none d-md-block">
                      <div class="mt-2">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-2">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeLocation == 'kotamobagu'">
                <div class="p-3">
                  <button type="button" @click.prevent="copyAddress('addressKotamobagu')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressKotamobagu">
                      <b class="mb-4"> Van Aroma - Kotamobagu</b>
                      <p class="mb-2">Jl. Gatot Subroto No.KEL, RT.3/RW.2, Mongkonai, Kec. Kotamobagu Bar.
                      <br>Kota Kotamobagu, Sulawesi Utara 95716,
                      <br>Indonesia </p>
                    </div>
                    <div class="mb-4 d-block d-md-none pt-2">
                      <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Kotamobagu,+Jl.+Gatot+Subroto+No.KEL,+RT.3%2FRW.2,+Mongkonai,+Kotamobagu+Barat,+Kotamobagu+City,+North+Sulawesi+95716&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                    </div>
                    <div class="d-none d-md-block">
                      <div class="mt-2">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-2">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeLocation == 'padang'">
                <div class="p-3">
                    <button type="button" @click.prevent="copyAddress('addressPadang')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressPadang">
                      <b class="mb-4"> Van Aroma - Padang </b>
                      <p class="mb-2"> Jl. Raya Bypass, KM 20,<br>
                      Koto Panjang Ikua Koto, Koto Tengah,<br>
                      Kota Padang, Sumatera Barat 25175,<br>
                      Indonesia </p>
                    </div>
                    <div class="mb-4 d-block d-md-none pt-2">
                      <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Padang,+Jl.+By+Pass+No.KM.17,+RT.002%2FRW.008,+Koto+Panjang+Ikua+Koto,+Kec.+Koto+Tangah,+Kota+Padang,+Sumatera+Barat+25586&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                    </div>
                    <div class="d-none d-md-block">
                      <div class="mt-2">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-2">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeLocation == 'new_jersey'">
                <div class="p-3">
                  <button type="button" @click.prevent="copyAddress('addressNewJersey')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressNewJersey">
                      <b class="mb-4">Van Aroma Inc - New Jersey</b>
                      <p class="mb-2">1 Home News Row New Brunswick, New Jersey USA, 08901<br>
                          EIN: 87-2422347<br>
                          Identification No. 0450696180</p>
                    </div>
                    <div class="d-none d-md-block">
                      <div class="mt-2">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+14124632627" target="_blank" class="address-link"> +1 412 463 2627 (WhatsApp)</a>
                      </div>
                      <div class="mt-2">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/19087982243" target="_blank" class="address-link"> +1 908 798 2243</a>
                      </div>
                      <div class="mt-2">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:usa@vanaroma.com" target="_blank" class="address-link">usa@vanaroma.com</a>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeLocation == 'rotterdam'">
                <div class="p-3">
                  <button type="button" @click.prevent="copyAddress('addressRotterdam')" class="btn-copy-address"><i class="fa fa-copy" aria-hidden="true"></i></button>
                    <div class="address-text" id="addressRotterdam">
                      <b class="mb-4"> Van Aroma Europe c/o Jandico Ltd </b>
                      <p class="mb-2">Malledijk 3h, 3208 LA Spijkenisse, Netherlands</p>
                    </div>
                    <div class="d-none d-md-block">
                      <!-- <div class="mt-2">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+14124632627" target="_blank" class="address-link"> +1 412 463 2627 (WhatsApp)</a>
                      </div> -->
                      <div class="mt-2">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/447455257985" target="_blank" class="address-link"> +44 7455 257985</a>
                      </div>
                      <div class="mt-2">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:europe@vanaroma.com" target="_blank" class="address-link">europe@vanaroma.com</a>
                      </div>
                    </div>
                </div>
            </div>
          </Transition>
      </div>
      </Transition>
  </div>
</template>
<style src="@/assets/css/maps.css" scooped></style>
<script>
export default {
  name: 'MapsView',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
      activeProduct: (this.$route.params.source) ? this.$route.params.source : '',
      activeLocation: 'bogor',
      ActiveCountry:'indonesia'
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Maps - Van Aroma';
      this.activeLocation= 'bogor'
  },
  methods:{
    showLocation(loc){
        this.activeLocation = loc
        var nav=document.getElementById('maps-nav');
        nav.classList.remove('expanded');
    },
    changeActiveProduct(e,product){
      if(this.activeProduct == product){
        this.activeProduct = '';
      }else{
        this.activeProduct = product;
      }
      this.$router.push('/maps/'+this.activeProduct)
      var title = e.target.innerHTML;
      document.getElementById('titleMenu').innerHTML=title
      var nav=document.getElementById('maps-nav');
      nav.classList.toggle("expanded");
    },
    collapseSidebar(){
      var nav=document.getElementById('maps-nav');
      nav.classList.toggle("expanded");
    },
    changeCountry(country){
      if(country == 'usa'){
        this.activeLocation ='new_jersey'
      }else if(country == 'europe'){
        this.activeLocation ='rotterdam'
      }else{
         this.activeLocation ='bogor'
      }
      this.ActiveCountry=country
      this.activeProduct=''
    },
    copyAddress(address){
      var el=document.getElementById(address) 
      var range = document.createRange();  
      range.selectNode(el);  
      window.getSelection().addRange(range);  
      document.execCommand('copy');
      this.$swal(
          {
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Address are copied to Clipboard',
            showConfirmButton: false,
            timer: 2500
          }
      );
      window.getSelection().removeAllRanges(); 
    }

  },
  computed: {
    screenWidth() {
      var screen = window.innerWidth;
      return screen;
    }
  },
}
</script>