<template>
    <div class="mt-5">
      <section class="pb-150 mb-5">
         <div class="container dont-copy">
           <div class="row mt-5 mb-5">
             <div class="page-title mt-5">
                 <h3>BENZOIN SUMATRA</h3>
                 <h5>STYRAX BENZOIN</h5>
             </div>
           </div>
           <div class="row justify-content-center">
             <div class="col-md-10">
                 <div class="row">
                     <div class="col-md-6"><br>
                         <img :src="imageUrl+'new_website/images/botanical-source/benzoin_int.jpg'" class="img-source p-4" alt="Benzoin Sumatra">
                     </div>
                     <div class="col-md-6">
                        <h4 class="mt-5">Introduction</h4>
                        <p>The benzoin resin is a common ingredient in incense making and perfumery because of it’s vanilla ice cream aroma and fixative properties.</p>
                        <p align="left">There are two commonly available Benzoin varieties popularly known as Benzoin Sumatra (Styrax Benzoin which grows predominantly on the island of Sumatra.) and Benzoin Siam (Styrax tonkinensis, found across Thailand, Laos, Cambodia, and Vietnam). While the process of obtaining crude resin from both types is identical, they display different attributes.
                        </p>
                     </div>

                     <br><br>
                        <div class="row" style="margin-top: 80px;">
                            <div class="col-md-12">
                                <img :src="imageUrl+'new_website/images/botanical-source/benzoin_m.jpg'" class="img-thumbnail" style="width: 100%" alt="Benzoin Sumatra">
                                
                            </div>
                        </div><!-- / row -->

                     <div class="col-md-12 mt-5">
                         <h4 class="mt-5 mb-5 text-center">How we get our Benzoin Resinoids or Crystals</h4>
                         <p class="text-center" style="font-size: 15px;" >The crude resin then goes through the process of solvent extraction at our facilities to produce premium grades of Benzoin Resinoid and Absolute.</p>
                         <div class="mt-5">
                             <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Tapping.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 1 <br>TAPPING</h5>
                                                 <p>Tapping is the process of making a small incision in the bark of the tree to obtain the crude balsamic resin exudates, and this is done after the tree is 7+ years old. Farmers puncture parts of the tree’s trunk from the bottom to the top.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Drying.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 2 <br>DRYING</h5>
                                                 <p>After tapping, the exudate oozes out and is left to dry and harden.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Harvesting.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 3 <br>HARVESTING</h5>
                                                 <p>The collection of the dry resin happens only after 3 or 4 months once it hardens.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Preliminary-Cleaning.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 4 <br>PRELIMINARY CLEANING</h5>
                                                 <p>The local farmers, and harvesters remove excessive bark, and other foreign material to obtain a somewhat pure raw material.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Accumulation.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 5 <br>ACCUMULATION</h5>
                                                 <p>Local and regional accumulators collect the raw material for sorting.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Sorting.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 6 <br>SORTING</h5>
                                                <p>A very manual, and intensive process that takes place for grading the raw material for different buyers.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Solvent-Extraction.jpg'" alt="Benzoin Sumatra">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 7 <br>SOLVENT EXTRACTION</h5>
                                                <p>Through the process of solvent extraction, the oil is extracted from the resin. The resulting resinoid is still too viscous (thick) to work with and hence a diluent (such as Benzyl Benzoate, Propylene Glycol, and Dipropylene Glycol) is added to the resinoid to make it mobile and easier to use.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
 
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
           </div>
         </div>

         <FaqElement source="3b39731e-5a9e-4d68-8ce4-6eb9cb07ad37"/>
         
         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Benzoin Sumatra Product List</h4>
                    </div>
                    <ProductList source="3b39731e-5a9e-4d68-8ce4-6eb9cb07ad37"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
      </section>
    </div>
 </template>
 <style src="@/assets/css/source-detail.css"></style>
 
 <script>
 import ProductList from '@/components/ProductList.vue'
 import FaqElement from '@/components/FaqElement.vue'
 export default {
   name: 'SumatraBenzoin',
    data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
   mounted() {
     let oldScript=document.getElementById('mainJS');
     if(oldScript){
       oldScript.remove();
     }
     let mainJS = document.createElement('script')
     mainJS.setAttribute('id', 'mainJS')
     mainJS.setAttribute('src', 'assets/js/main.js')
     document.body.appendChild(mainJS)
 
     let oldPopover= document.getElementById('popoverJS');
     if(oldPopover){
         oldPopover.remove()
     }
     let popoverJS = document.createElement('script')
     popoverJS.setAttribute('id', 'popoverJS')
     popoverJS.setAttribute('src', 'assets/js/reinit.js')
     document.body.appendChild(popoverJS)
 
     document.title = 'Benzoin Sumatra - Van Aroma';
   }
 }
 </script>
 