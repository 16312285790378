<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>CLOVE</h3>
                <h5>Syzygium aromaticum (L.) syn. Eugenia Caryophyllus</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/clove-11.jpeg'" class="img-source p-4" alt="clove oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Cultivation of Clove in Indonesia</h4>
                        <p>Clove trees are indigenous to the Maluku islands of Indonesia. Indonesia has been the dominant producer of clove buds and clove oil globally for decades.</p>
                        <p>Indonesia is also the largest consumer of clove buds as a spice, which is primarily used in the kretek cigarette industry. It is due to this high demand for Clove Buds in the cigarette industry that farmers are using the remaining components (dry leaves, and stems/claws) of the clove tree to produce clove oil its derivatives thereafter.
                        </p>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <h4 class="mb-3">How have changes in the production of Patchouli affected the development of Clove Oil in Sulawesi?</h4>
                                <p>Around 5 years ago, despite being the largest producer of clove buds, the Sulawesi Island contributed a very small proportion of the overall clove oil production in Indonesia. Meanwhile the advent of patchouli distillation in Sulawesi eventually resulted in overproduction and a crash in patchouli prices</p>
                                <p>Farmers halted Patchouli cultivation and distillation temporarily to use the same infrastructure to distill Clove leaves and stems. Over time, other farmers followed suit to this practice and the number of distillation stills kept growing. This trend continues, and there are an increasing number of clove oil producers in Sulawesi. For many, the distillation of clove oils serves as an alternative source of income for the period where there are no clove buds to harvest.</p>
                            </div>
                            <div class="col-md-6">
                                <p class="text-center"><b>Regions of Cultivation</b></p>
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/map-clove.jpeg'" class="w-100"  alt="clove oil indonesia">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <div class="row">
                            <div class="col-md-12 text-center mb-5">
                                <h4>The dynamics of Clove Oil Production in Indonesia are evolving</h4>
                                <p>For a very long time, Java (~25% of Clove Plantations in Indonesia) has been the dominant producer of Clove oils with over 80% of the volume originating from this island.</p> 
                                <p>The island of Sulawesi, which has over 70% of Indonesia's clove plantations has seen very little focus from Essential Oil producers primarily because of it's remote / difficult access. With the overall availability of leaves and stems in Sulawesi, this island presents great potential to grow the overall production of clove oils by multiple folds. In 2017, Van Aroma established Asia's single largest Clove Oil distillation facility in south-east Sulawesi, and sources more than 80% of it's volume from Sulawesi today. This provides for a new, fully traceable, and sustainable source of clove oils from a previously untapped source.</p>
                            </div>
                            <div class="col-md-4 p-3 text-center">
                                <div class="chart" data-size="200" data-percent="0" data-line="4" data-bgcolor="#e5e5e5" data-trackcolor="#27d2a3" data-speed="3200">
                                    <div class="chart-meta">
                                        <strong class="percent color-darkgrey">0</strong>
                                        <h4 class="text-uppercase mt-3 thin">SUMATRA</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-3 text-center">
                                <div class="chart" data-size="200" data-percent="70" data-line="4" data-bgcolor="#e5e5e5" data-trackcolor="#27d2a3" data-speed="3200">
                                    <div class="chart-meta">
                                        <strong class="percent color-darkgrey">70</strong>
                                        <h4 class="text-uppercase mt-3 thin">JAVA</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-3 text-center">
                                <div class="chart" data-size="200" data-percent="30" data-line="4" data-bgcolor="#e5e5e5" data-trackcolor="#27d2a3" data-speed="3200">
                                    <div class="chart-meta">
                                        <strong class="percent color-darkgrey">30</strong>
                                        <h4 class="text-uppercase mt-3 thin">SULAWESI</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/pic-clove-02.png'" class="w-100"  alt="clove oil indonesia">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h4 class="mb-3">What are the different types of Clove Oils?</h4>
                                <p>THE DIFFERENT PARTS OF THE CLOVE PLANT CAN BE STEAM DISTILLED TO PRODUCE VARIOUS TYPES OF CLOVE OILS SUCH AS: CLOVE BUD OIL, CLOVE STEM OIL AND CLOVE LEAF OIL.</p>
                                <p>Clove bud oil derived from dried buds by steam distillation, contains free eugenol, eugenol acetate and caryophyllene. The stem oil contains more free eugenol than the bud oil, as well as a small percentage of eugenyl acetate. The leaf oil contains the least amount of total eugenol compared to buds or stems, and has very small quantities of eugenyl acetate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <img :src="imageUrl+'new_website/images/botanical-source/Clove_Isolates_and_their_Derivatives.webp'" style="width: 100%;">
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we source our Clove Oil ?</h4>
                        <p class="text-center">
                            We source all our Clove Oil from partner farmers and pre-approved accumulators who harvest and process the Clove leaves, stem and bud through steam distillation to produce a basic form of Clove Oil. 
                            <br>CLOVE OIL DISTILLATION IN 7 STEPS</p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_0.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>SEEDLING</h5>
                                                <p>Seedlings are nurtured under shade and can be retained in the nursery till they attain a height of 25-30 cm in six months and then grown in pots for another 12-18 months.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_0.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>PLANTATION</h5>
                                                <p>Clove trees thrive well under open conditions at high altitudes where there is a fair distribution of rainfall. Seedlings are planted in the centre of the pits during May and June at the onset of the monsoon and watered regularly.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3.1.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>MANUAL COLLECTION OF DRIED FALLEN LEAVES</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3.2.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 4 <br>PREPARED FOR DISTILLATION</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_0.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 5 <br>COLLECTION OF CLOVE STEMS</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step5_0.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 6 <br>COLLECTION OF CLOVE BUD</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step6.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 7 <br>DISTILLATION</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step7.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>CLOVE LEAF OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step8.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>CLOVE BUD OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step9.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>CLOVE STEM OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step10.jpg'"  alt="clove oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>EUGENOL USP</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="260edc55-34a0-4489-8e36-4acd4a9e3a84"/>

        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Clove Product List</h4>
                    </div>
                    <ProductList source="260edc55-34a0-4489-8e36-4acd4a9e3a84"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'ClovePage',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
    }
  },
  components:{
        ProductList,
        FaqElement
  },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Clove Oil Indonesia - Van Aroma';
  }
}
</script>
