<template>
  
  <!--Location-->
  <section class="ls s-py-90">
    <div class="container">
      <div class="row" v-if="emptyResult">
        <div class="col-md-12" style="height:80vh">
          <img :src="imageUrl+'new_website/images/search.png'" class="not-found-small-icon mt-5">
          <div class="text-center text-dark-grey">Ups!... no results found</div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-8">
          <div v-if="isLoading">
              <div class="title-loading-placeholder mb-3">
                <div class="skeleton"></div>
              </div>
              <div class="cover-loading-placeholder">
                <div class="skeleton"></div>
              </div>
              <div class="text-loading-placeholder mt-3">
                <div class="skeleton"></div>
              </div>
              <div class="text-loading-placeholder mt-3">
                <div class="skeleton"></div>
              </div>
              <div class="text-loading-placeholder mt-3">
                <div class="skeleton"></div>
              </div>
              <div class="text-loading-placeholder mt-3">
                <div class="skeleton"></div>
              </div>
          </div>
          <article class="vertical-item post type-post status-publish format-standard has-post-thumbnail" v-else>
            <h1 class="post-title">{{ post.title }}</h1>
            <p class="mb-5"><i>Post on {{post.post_date}}</i></p>
            <div class="item-media">
              <img :src="imageUrl+'new_website/blog/'+post.cover" alt="img" class="post-cover">
              <!-- <div class="custom-date-wrap">
                <div class="custom-date">
                  <span>28</span>
                  <span>may</span>
                </div>
              </div> -->
            </div>
            <div class="item-content pt-5">
              <div class="entry-content post-content" v-html="post.content">
              </div>
            </div>
            <!-- .item-content -->
          </article>
        </div>
        <div class="col-md-4">
          <div class="widget widget_recent_posts mt-5 mt-md-0">
            <h4>Recomended Posts</h4>
            <hr>
            <ul class="list-unstyled">
              <li class="d-flex media" v-for="post in recomendPosts" :key="post.slug">
                <a class="media-image latest-post" :href="'/post/'+post.slug">
                  <img :src="imageUrl+'new_website/blog/'+post.cover" alt="img">
                </a>
                <div class="media-body">
                  <p class="item-meta">
                   {{post.post_date}}
                  </p>
                  <h4 class="mt-0 latest-post-title">
                    <a :href="'/post/'+post.slug" class="post-link">{{ post.title }}</a>
                  </h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <div class="post-footer-nav">
             <a class="prev" :href="(post.prev) ? '/post/'+post.prev : '#'" :class="!post.prev ? 'disabled' : ''">
             Previous 
              </a>
              <a class="next" :href="(post.next) ? '/post/'+post.next : '#'" :class="!post.next ? 'disabled' : ''">
                Next
              </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Posts End-->
</template>
<style scoped>
  .title-loading-placeholder{
    height: 70px;
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
  }
  .cover-loading-placeholder{
    height: 200px;
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
  }
  .text-loading-placeholder{
    height: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
  }
  .skeleton {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    background-size: 200% 100%;
    animation: shine 1.5s infinite;
}

@keyframes shine {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
</style>
<script>
import axios from "axios";
export default {
  name: 'MediaView',
  data() {
      return { 
        //apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
        apiUrl:'https://api.vanaroma.com',
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        post:'',
        isLoading:true,
        recomendPosts:[],
        emptyResult:false
      }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Post - Van Aroma';
      this.getPost()
      this.getRecomendPosts()
  },
  watch: {
      slug:function(){
        this.getPost()
      }
  },
  computed:{
    slug(){
        return this.$route.params.slug;
    },
  },
  methods:{
      getPost(){
        let reqOptions = {
            url: this.apiUrl+'/webx/post_detail?slug='+this.slug,
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(Object.keys(response.data.data).length !== 0){
                this.post=response.data.data
                this.isLoading=false
                document.title=this.post.title
              }else{
                this.emptyResult=true
              }
            })
            .catch((error) => {
              console.log(error)
              this.post=''
              this.emptyResult=true
            }
        );
      },
      getRecomendPosts(){
        let reqOptions = {
            url: this.apiUrl+'/webx/get_posts?limit=7&offset=0&order_by=recomended&detail='+this.slug,
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(Object.keys(response.data.data.results).length !== 0){
                this.recomendPosts=response.data.data.results
              }
            })
            .catch((error) => {
              console.log(error)
              this.recomendPosts=[]
            }
        );
      },
  }
}
</script>