<template>
  
  <!--Location-->
  <section class="ls s-py-90">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 text-center mb-5">
          <a href="mailto:info@vanaroma.com" class="enquiries"><i class="fa fa-envelope"></i> info@vanaroma.com</a>
        </div>
        <div class="col-12">
          <div class="dropdown btn-location-mobile d-block d-md-none">
            <button class="dropdown-toggle form-control" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownLocation">
            Bogor (HQ)
            </button>
            <ul class="dropdown-menu w-100">
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'jakarta')">Bogor (HQ)</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'kolaka')">Kolaka</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'makassar')">Makassar</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'padang')">Padang</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'ampana')">Ampana</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'kotamobagu')">Kotamobagu</a></li>
              <!-- <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'toari')">Toari</a></li> -->
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'newJersey')">New Jersey (USA)</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'europe')">Europe</a></li>
            </ul>
          </div>

          <div class="d-none d-md-block">
            <!-- tabs start -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="jakartaAddress" data-bs-toggle="tab" href="#jakarta" role="tab" aria-controls="jakarta" aria-expanded="true" aria-selected="true">Bogor (HQ)</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="kolakaAddress" data-bs-toggle="tab" href="#kolaka" role="tab" aria-controls="kolaka" aria-selected="false">Kolaka</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="makassarAddress" data-bs-toggle="tab" href="#makassar" role="tab" aria-controls="makassar" aria-selected="false">Makassar</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="padangAddress" data-bs-toggle="tab" href="#padang" role="tab" aria-controls="padang" aria-selected="false">Padang</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="ampanaAddress" data-bs-toggle="tab" href="#ampana" role="tab" aria-controls="ampana" aria-selected="false">Ampana</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="ampanaAddress" data-bs-toggle="tab" href="#kotamobagu" role="tab" aria-controls="kotamobagu" aria-selected="false">Kotamobagu</a>
              </li>
              <!-- <li class="nav-item" role="presentation">
                <a class="tab-link" id="toariAddress" data-bs-toggle="tab" href="#toari" role="tab" aria-controls="toari" aria-selected="false">Toari</a>
              </li> -->
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="newJerseyAddress" data-bs-toggle="tab" href="#newJersey" role="tab" aria-controls="newJersey" aria-selected="false">New Jersey (USA)</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="europeAddress" data-bs-toggle="tab" href="#europe" role="tab" aria-controls="europe" aria-selected="false">Europe</a>
              </li>
            </ul>
          </div>
          <div class="tab-content mt-3">

            <div class="tab-pane fade in active show" id="jakarta" role="tabpanel" aria-labelledby="jakartaAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#jakartaTxt">
                      <b class="mb-4"> Van Aroma (HQ - Office & Factory) </b>
                      <p class="mb-2"> Jl. Raya Cicadas No.16,<br>
                          RT.4/RW.4, Cicadas, Kec. Gn. Putri, <br>
                          Bogor, Jawa Barat 16964, <br>
                          Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=-6.433594,106.927494&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-phone"></i></span>
                          <a href="tel:+62218677003" target="_blank" class="address-link"> +62 21 - 867 7003 </a>
                      </div>
                      <!-- <div class="mt-3">
                          <span><i class="fab fa-whatsapp"></i></span>
                          <a href="https://wa.me/14124632627" target="_blank" class="address-link"> +1 412 463 2627 </a>
                      </div> -->
                      <div class="mt-3">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:info@vanaroma.com" target="_blank" class="address-link">info@vanaroma.com</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link"> vanaroma </a>
                      </div>
                  </div>
                  <div id="jakartaTxt" class="copy-content" style="display: none;">
                    <b class="mb-4"> Van Aroma (HQ - Office & Factory) </b>
                    <p class="mb-2"> Jl. Raya Cicadas No.16,<br>
                        RT.4/RW.4, Cicadas, Kec. Gn. Putri, <br>
                        Bogor, Jawa Barat 16964, <br>
                        Indonesia </p>
                        <p>Phone : +62 21- 867 7003</p>
                        <p>Email : info@vanaroma.com</p>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.69200394844!2d106.92530025024854!3d-6.433594195322475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69953bd4ab6057%3A0xc264c2008b4a344d!2sVan%20Aroma!5e0!3m2!1sen!2sid!4v1638933860580!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma HQ">
                        </iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="kolaka" role="tabpanel" aria-labelledby="kolakaAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#kolakaTxt">
                      <b class="mb-4"> Van Aroma - Kolaka </b>
                      <p class="mb-2"> Jl. Poros Kolaka Wolo, Mangolo, Latambaga,<br>
                      Kab. Kolaka, Sulawesi Tenggara 93512,<br>
                      Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=-4.026706,121.548805&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link"> vanaroma </a>
                      </div>
                    </div>
                    <div id="kolakaTxt" class="copy-content" style="display: none;">
                      <b class="mb-4"> Van Aroma - Kolaka </b>
                      <p class="mb-2"> Jl. Poros Kolaka Wolo, Mangolo, Latambaga,<br>
                      Kab. Kolaka, Sulawesi Tenggara 93512,<br>
                      Indonesia </p>
                        <p>Phone : +62 811 999 5762</p>
                        <p>WA : +62 811 999 5762 </p>
                    </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1590.457965852714!2d121.54848237853327!3d-4.027017550987108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d978b2dc109dd99%3A0x744ee42ec915c41a!2sVan%20Aroma%20-%20Kolaka!5e0!3m2!1sen!2sid!4v1638935877775!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Kolaka"></iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="makassar" role="tabpanel" aria-labelledby="makassarAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#makassarTxt">
                      <b class="mb-4"> Van Aroma - Makassar </b>
                      <p class="mb-2">JL. Ir Sutami No. 38 Kawasan Pergudangan Parangloe Indah (Natura Cluster 1)<br>
                      Block E No. 8, Kapasa Raya, Bira, Tamalanrea,<br>
                      Kota Makassar, Sulawesi Selatan 90244,<br>
                      Indonesia</p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Makassar+(Parangloe)&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-phone"></i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link"> vanaroma </a>
                      </div>
                    </div>
                    <div id="makassarTxt" class="copy-content" style="display: none;">
                      <b class="mb-4"> Van Aroma - Makassar </b>
                      <p class="mb-2">JL. Ir Sutami No. 38 Kawasan Pergudangan Parangloe Indah (Natura Cluster 1)<br>
                      Block E No. 8, Kapasa Raya, Bira, Tamalanrea,<br>
                      Kota Makassar, Sulawesi Selatan 90244,<br>
                      Indonesia</p>
                        <p>Phone : +62 811 999 5762</p>
                        <p>WA : +62 811 999 5762 </p>
                    </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d607.0338524854475!2d119.48439512024272!3d-5.097563184741318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dbefdf69abf82cf%3A0xb06f8e5382d07d6b!2sVan%20Aroma%20-%20Makassar%20(Parangloe)!5e0!3m2!1sen!2sid!4v1638936264840!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Makassar"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="padang" role="tabpanel" aria-labelledby="padangAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#padangTxt">
                      <b class="mb-4"> Van Aroma - Padang </b>
                      <p class="mb-2"> Jl. Raya Bypass, KM 20,<br>
                      Koto Panjang Ikua Koto, Koto Tengah,<br>
                      Kota Padang, Sumatera Barat 25175,<br>
                      Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Padang,+Jl.+By+Pass+No.KM.17,+RT.002%2FRW.008,+Koto+Panjang+Ikua+Koto,+Kec.+Koto+Tangah,+Kota+Padang,+Sumatera+Barat+25586&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link"> vanaroma </a>
                      </div>
                  </div>
                  <div id="padangTxt" class="copy-content" style="display: none;">
                      <b class="mb-4"> Van Aroma - Padang </b>
                      <p class="mb-2"> Jl. Raya Bypass, KM 20,<br>
                      Koto Panjang Ikua Koto, Koto Tengah,<br>
                      Kota Padang, Sumatera Barat 25175,<br>
                      Indonesia </p>
                        <p>Phone : +62 811 999 5762</p>
                        <p>WA : +62 811 999 5762 </p>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.372857484289!2d100.36809301475355!3d-0.8561913993754036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9150c2bf59825b46!2sVan%20Aroma%20-%20Padang!5e0!3m2!1sid!2sid!4v1639117296209!5m2!1sid!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Makassar"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ampana" role="tabpanel" aria-labelledby="ampanaAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#ampanaTxt">
                      <b class="mb-4"> Van Aroma - Ampana </b>
                      <p class="mb-2">JL. Moh. Hatta No. 138, Muara Toba, Ratolindo,
                      <br>Kab. Tojo Una Una, Sulawesi Tengah 94683,
                      <br>Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Ampana,+PT.+Van+Aroma,+Jl.+Moh.+Hatta+No.+138,+RT.+001+%2F+RW.001,+Kel.+Muara+Toba+Uentanaga+Bawah,+Kab,+Uentanaga+Bawah,+Kec.+Ampana+Kota,+Kabupaten+Tojo+Una-Una,+Sulawesi+Tengah+94683&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link"> vanaroma </a>
                      </div>
                  </div>
                  <div id="ampanaTxt" class="copy-content" style="display: none;">
                    <b class="mb-4"> Van Aroma - Ampana </b>
                      <p class="mb-2">JL. Moh. Hatta No. 138, Muara Toba, Ratolindo,
                      <br>Kab. Tojo Una Una, Sulawesi Tengah 94683,
                      <br>Indonesia </p>
                        <p>Phone : +62 811 999 5762</p>
                        <p>WA : +62 811 999 5762 </p>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.3588610341685!2d121.5841031147536!3d-0.8695383993656696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd05daa5206aaf08b!2sVan%20Aroma%20-%20Ampana!5e0!3m2!1sid!2sid!4v1639104006343!5m2!1sid!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Ampana"></iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="kotamobagu" role="tabpanel" aria-labelledby="kotamobaguAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma - Kotamobagu </b>
                      <p class="mb-2">Jl. Gatot Subroto No.KEL, RT.3/RW.2, Mongkonai, Kec. Kotamobagu Bar.
                      <br>Kota Kotamobagu, Sulawesi Utara 95716,
                      <br>Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Kotamobagu,+Jl.+Gatot+Subroto+No.KEL,+RT.3%2FRW.2,+Mongkonai,+Kotamobagu+Barat,+Kotamobagu+City,+North+Sulawesi+95716&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.491142345835!2d124.28631051114449!3d0.7337634632420044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x327e3d2fb5abe7cb%3A0x3322763302e49249!2sVan%20Aroma%20-%20Kotamobagu!5e0!3m2!1sen!2sid!4v1725245372414!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
              </div>
            </div>
            
            <div class="tab-pane fade" id="toari" role="tabpanel" aria-labelledby="toariAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#toariTxt">
                      <b class="mb-4">Sustainable Patchouli Cultivation Project</b>
                      <p class="mb-2">Lakito, Toari,<br>
                      Kolaka Regency, South East Sulawesi 93565,<br>
                      Indonesia</p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=-4.578105,121.499601&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                    </div>
                    <div id="toariTxt" class="copy-content" style="display: none;">
                      <b class="mb-4">Sustainable Patchouli Cultivation Project</b>
                      <p class="mb-2">Lakito, Toari,<br>
                      Kolaka Regency, South East Sulawesi 93565,<br>
                      Indonesia</p>
                    </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1988.5443084946394!2d121.499601!3d-4.578105000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe629bf495619aba6!2sDemo%20plot%20Toari%20-%20Van%20Aroma!5e0!3m2!1sen!2sid!4v1671005028852!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Van Aroma Sustainable Patchouli Cultivation Project"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="newJersey" role="tabpanel" aria-labelledby="newJerseyAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address" data-action="copy-content" data-target="#newJerseyTxt">
                      <b class="mb-4"> Van Aroma Inc - New Jersey </b>
                      <p class="mb-2">1 Home News Row New Brunswick, New Jersey USA, 08901<br>
                          EIN: 87-2422347<br>
                          Identification No. 0450696180</p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=40.475947,-74.478645&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <!-- <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+14124632627" target="_blank" class="address-link"> +1 412 463 2627 (WhatsApp)</a>
                      </div> -->
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/19087982243" target="_blank" class="address-link"> +1 908 798 2243</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:usa@vanaroma.com" target="_blank" class="address-link">usa@vanaroma.com</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link"> vanaroma </a>
                      </div>
                  </div>
                  <div id="newJerseyTxt" class="copy-content" style="display: none;">
                      <b class="mb-4"> Van Aroma Inc - New Jersey </b>
                      <p class="mb-2">1 Home News Row New Brunswick, New Jersey USA, 08901<br>
                          EIN: 87-2422347<br>
                          Identification No. 0450696180</p>
                          <p>WA : +1 908 798 2243</p>
                          <p>Email : usa@vanaroma.com </p>
                    </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d758.7423033722476!2d-74.47918267063903!3d40.475946099999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c765958878a1%3A0xcd4b644a64e246e!2sVan%20Aroma%20Inc%20-%20USA!5e0!3m2!1sen!2sid!4v1646711256039!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma USA"></iframe>
                    </div>
                </div>
              </div>
            </div>
            
            <div class="tab-pane fade" id="europe" role="tabpanel" aria-labelledby="europeAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma Europe c/o Jandico Ltd </b>
                      <p class="mb-2">7 Briarfiled, Egerton,<br>Bolton. Lancashire. BL7 9TX<br>United Kingdom</p>
                      <div class="mb-3">
                        <img src="https://cdn.vanaroma.com/new_website/images/VA_Jandico_png.png" class="address-img">
                      </div>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=53.6302876,-2.4453703&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="https://wa.me/447455257985" target="_blank" class="address-link"> +44 7455 257985</a> <a href="#" data-action="copy-content"  data-target="#europePhone"><i class="fas fa-copy"></i></a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:europe@vanaroma.com" target="_blank" class="address-link">europe@vanaroma.com</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-globe"></i></span>
                          <a href="https://jandico.co.uk/projects/van-aroma" target="_blank" class="address-link">jandico.co.uk</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                  <div id="europeTxt" class="copy-content" style="display: none;">
                      <b class="mb-4"> Van Aroma Europe c/o Jandico Ltd </b>
                      <p class="mb-2">7 Briarfiled, Egerton,<br>Bolton. Lancashire. BL7 9TX<br>United Kingdom</p>
                      <p>Tlp : +44 7455 257985</p>
                      <p>Email : europe@vanaroma.com </p>
                  </div>
                  <div id="europePhone" class="copy-content" style="display: none;">+447455257985</div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2365.9356218519306!2d-2.4453703235272948!3d53.630287572373035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487ba748f58f6bf5%3A0x19aedae7cb9f0011!2sJandico%20Ltd!5e0!3m2!1sen!2sid!4v1720010154712!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
              </div>
            </div>

          </div>
          <!-- tabs end-->
        </div>
      </div>
    </div>
    <div class="whatsapp-btn">
      <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
        <!-- <span class="icon"><i class="fab fa-whatsapp"></i></span> -->
        <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
    </div>
  </section>
  <!--Posts End-->
</template>
<script>
export default {
  name: 'ContactView',
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Contact - Van Aroma';
  },
  methods:{
    showLocation(e,loc){
      let text = e.target.text;
      document.getElementById('dropdownLocation').innerHTML=text;
      let btn=document.getElementById(loc+'Address');
      btn.click();
    },
    copyContent(target){
      window.getSelection().removeAllRanges();
      // Select the content
      var content = document.querySelector(target);  
      var range = document.createRange();  
      range.selectNode(content);  
      window.getSelection().addRange(range);  
      document.execCommand('copy');
      window.getSelection().removeAllRanges();  
    }
  }
}
</script>
<style scoped>
.company-map iframe{
  width: 100%;
  height: 400px;
}
.nav-tabs .tab-link{
    text-align: left;
    padding: 15px 0px !important;
    min-width: unset;
    margin-right: 50px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .1em;
  }
  .nav-tabs .tab-link::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #2d3545;
    transition: transform 0.4s cubic-bezier(0.85, 0.26, 0.17, 1);
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }
  .nav-tabs .tab-link.active::before, .nav-tabs .tab-link:hover::before{
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  .nav-tabs .tab-link.active{
    background-color: #fff !important;
    color: #2d3545;
  }
  .nav-tabs .nav-item .tab-link:hover{
    background-color: #fff !important;
  }
  .enquiries{
    font-size: 35px;
  }
  .enquiries i {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    .enquiries{
      font-size: 25px;
    }
  }
</style>