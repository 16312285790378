<template>
  <section class="ls s-py-60">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-10">
          <blockquote class="blockquote-theme page-header">
            <h4>
              <span class="color-main">Our Ingredient's Origins :</span><br>Van Aroma’s Value-Added Products Begin Here<br>
            </h4>
          </blockquote>
        </div>
      </div>
      <div class="row">
        <div class="sorting">
          <button class="btn btn-default btn-product-language float-end" data-bs-toggle="dropdown"><img :src="'/assets/images/flag/'+FlagIcon" class="flag-icon mb-1"> {{ language }} <i class="fa fa-caret-down"></i></button>
          <div class="dropdown-menu dropdown-menu-right product-language-item" aria-labelledby="sort-text">
              <a class="dropdown-item change-lang" :class="{active : language === 'EN'}" href="#"  @click="changeLanguage('EN')" data-language="english"><img src="/assets/images/flag/united-kingdom.svg" class="flag-icon"> EN</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'ID'}" href="#"  @click="changeLanguage('ID')"><img src="/assets/images/flag/indonesia-flag.svg" class="flag-icon"> ID</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'CN'}" href="#"  @click="changeLanguage('CN')"><img src="/assets/images/flag/china-flag.svg" class="flag-icon"> CN</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'FR'}" href="#"  @click="changeLanguage('FR')"><img src="/assets/images/flag/france-flag.svg" class="flag-icon"> FR</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'SP'}" href="#"  @click="changeLanguage('SP')"><img src="/assets/images/flag/spain.svg" class="flag-icon"> SP</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'JP'}" href="#"  @click="changeLanguage('JP')"><img src="/assets/images/flag/japan-flag.svg" class="flag-icon"> JP</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'VN'}" href="#"  @click="changeLanguage('VN')"><img src="/assets/images/flag/vietnam-flag.svg" class="flag-icon"> VN</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'TR'}" href="#"  @click="changeLanguage('TR')"><img src="/assets/images/flag/turkey-flag.svg" class="flag-icon"> TR</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'RU'}" href="#"  @click="changeLanguage('RU')"><img src="/assets/images/flag/russia-flag.svg" class="flag-icon"> RU</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'SA'}" href="#"  @click="changeLanguage('SA')"><img src="/assets/images/flag/sa.svg" class="flag-icon"> SA</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'KR'}" href="#"  @click="changeLanguage('KR')"><img src="/assets/images/flag/kr.svg" class="flag-icon"> KR</a>
              <a class="dropdown-item change-lang" :class="{active : language === 'IT'}" href="#"  @click="changeLanguage('IT')"><img src="/assets/images/flag/italy-flag.svg" class="flag-icon"> IT</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center mt-5 p-5" v-if="getTry >= 5">
            <p>Failed to get request</p>
            <a class="link-reload" href="https://vanaroma.com/sources">Reload Page</a>
        </div>
       <div class="loading mt-5" v-if="isLoading">
            <img src="@/assets/img/loading.gif" >
        </div>
        <div v-else class="col-xl-2 col-lg-3 col-md-4 col-6 mt-5" v-for="source in sources" :key="source.recid_product_sources">
          <div class="property-card">
            <div class="img-placeholder"></div>
            <router-link :to="source.web_detail_page" v-if="source.web_detail_page">
              <div class="property-image" :style="[source.image ? {'background-image': 'url('+imageUrl+'img/imageproduct/' + source.image + ')'} : {'background-image': 'url('+imageUrl+'empty-image.jpg'}]">
                <div class="property-image-title">
                  <!-- Optional <h5>Card Title</h5> If you want it, turn on the CSS also. -->
                </div>
              </div>
            </router-link>
            <div v-else>
              <div class="property-image" :style="[source.image ? {'background-image': 'url('+imageUrl+'img/imageproduct/' + source.image + ')'} : {'background-image': 'url('+imageUrl+'empty-image.jpg'}]">
                <div class="property-image-title">
                  <!-- Optional <h5>Card Title</h5> If you want it, turn on the CSS also. -->
                </div>
              </div>
            </div>
            <div class="property-description">
                <h5 v-if="language== 'EN'">{{ source.product_source_name }}</h5>
                <h5 v-if="language === 'ID'">{{ (source.indonesian_name) ? source.indonesian_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'CN'">{{ (source.chinese_name) ? source.chinese_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'FR'">{{ (source.french_name) ? source.french_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'SP'">{{ (source.spanish_name) ? source.spanish_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'JP'">{{ (source.japanese_name) ? source.japanese_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'VN'">{{ (source.vietnamese_name) ? source.vietnamese_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'TR'">{{ (source.turkish_name) ? source.turkish_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'RU'">{{ (source.russian_name) ? source.russian_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'SA'">{{ (source.arabic_name) ? source.arabic_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'KR'">{{ (source.korean_name) ? source.korean_name.toUpperCase() : source.product_source_name }}</h5>
            <h5 v-if="language === 'IT'">{{ (source.italian_name) ? source.italian_name.toUpperCase() : source.product_source_name }}</h5>
            <div class="animate-text">
              <TransitionGroup name="slide-up" mode="out-in">
                <div v-if="activeSlide == 0">{{ (source.indonesian_name) ? source.indonesian_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 1">{{ (source.chinese_name) ? source.chinese_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 2">{{ (source.french_name) ? source.french_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 3">{{ (source.spanish_name) ? source.spanish_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 4">{{ (source.japanese_name) ? source.japanese_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 5">{{ (source.vietnamese_name) ? source.vietnamese_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 6">{{ (source.turkish_name) ? source.turkish_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 7">{{ (source.russian_name) ? source.russian_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 8">{{ (source.arabic_name) ? source.arabic_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 9">{{ (source.korean_name) ? source.korean_name.toUpperCase() : '-' }}</div>
                <div v-if="activeSlide == 9">{{ (source.italian_name) ? source.italian_name.toUpperCase() : '-' }}</div>
              </TransitionGroup>
              </div>
              <p>
                <i>{{ source.botanical_name }}</i>
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="divider-50 divider-lg-150"></div>
    <div class="whatsapp-btn">
      <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
      <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: 'BotanicalSources',
  data() {
    return {
      sources: [],
      isLoading:false,
      getTry:0,
      activeSlide: 0,
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
      language:localStorage.getItem('language') ? localStorage.getItem('language') : 'EN' ,
      FlagIcon:localStorage.getItem('FlagIcon') ? localStorage.getItem('FlagIcon') : 'united-kingdom.svg',
      apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
    };
  },
  watch: {
        sources:function(val){
            if((!val || Object.keys(val).length === 0) && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getSources()
                    this.getTry++
                }, 1000);
            }
        },
  },
  methods:{
    setSources(data) {
      this.sources = data;
      this.isLoading=false;
    },
    getSources(){
      axios.get(this.apiUrl+'/webx/r_botanical_sources/')
      .then((response) => {
        if(response.data.data == undefined){
            console.log('retrying to get data source')
            if(this.getTry < 5){
                setTimeout(() => {
                    this.getSources()
                    this.getTry++
                }, 1000);
            }
        }else{
            this.setSources(response.data.data)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setSources([])
      });
    },
    changeLanguage(lang){
        this.language=lang;
        if(this.language == 'EN'){
            this.FlagIcon='united-kingdom.svg';
        }else if(this.language == 'ID'){
            this.FlagIcon='indonesia-flag.svg';
        }else if(this.language == 'CN'){
            this.FlagIcon='china-flag.svg';
        }else if(this.language == 'FR'){
            this.FlagIcon='france-flag.svg';
        }else if(this.language == 'SP'){
            this.FlagIcon='spain.svg';
        }else if(this.language == 'JP'){
            this.FlagIcon='japan-flag.svg';
        }else if(this.language == 'VN'){
            this.FlagIcon='vietnam-flag.svg';
        }else if(this.language == 'TR'){
            this.FlagIcon='turkey-flag.svg';
        }else if(this.language == 'RU'){
            this.FlagIcon='russia-flag.svg';
        }else if(this.language == 'KR'){
            this.FlagIcon='KR.svg';
        }else if(this.language == 'IT'){
            this.FlagIcon='italy-flag.svg';
        }
        
        localStorage.setItem('language',lang);
        localStorage.setItem('FlagIcon',this.FlagIcon);
    },
    animateText(){
      var self=this
      setInterval(function(){
        if( self.activeSlide < 9){
          self.activeSlide++;
        }else{
          self.activeSlide=0;
        }
      }, 3000);
    },
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Botanical Sources - Van Aroma';


      //cek status loading
      axios.interceptors.request.use((config) => {
          this.isLoading = true;
          return config;
      }, (error) => {
          this.isLoading = false;
          return Promise.reject(error);
      });

      axios.interceptors.response.use((response) => {
          this.isLoading = true;
          return response;
      }, (error) => {
          this.isLoading = false;
          return Promise.reject(error);
      });
      
      this.getSources();
      this.animateText()
      
  }
}
</script>
<style scoped>
.loading img{
  width: 60px;
  height: 20px;
  object-fit: cover;
  margin: auto;
  display: block;
}
.property-card
{
  height:18em;
  width:100%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  position:relative;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius:12px;
  overflow:hidden;
  -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}
/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image
{
  height:10em;
  width:100%;
  padding:1em 2em;
  position:Absolute;
  top:0px;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-size:cover;
  background-repeat:no-repeat;
}

/* Bottom Card Section */

.property-description
{
  background-color: #FAFAFC;
  height:8em;
  width:100%;
  line-height: 16px;
  position:absolute;
  bottom:0em;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1em 1em;
  text-align:center;
}

/* Social Icons */

.property-description h5{
  font-size: 15px;
  margin-bottom: 0px;
  line-height: 18px;
}
.property-description p{
  font-size: 12px;
}
.property-social-icons
{
  width:1em;
  height:1em;
  background-color:black;
  position:absolute;
  bottom:1em;
  left:1em;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.animate-text{
  font-size: 12px;
  margin-top: 2px;
  overflow: hidden;
  height: 30px;
  color: #777;
  display: grid;
  align-items: center;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.5s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(10px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
/* Property Cards Hover States */

.property-card:hover
{
  transform: scale(1.05);
}

/* hover animation */
/* .property-card:hover .property-description
{
  height:0em;
  padding:0px 1em;
}
.property-card:hover .property-image
{
  height:18em;
}

.property-card:hover .property-social-icons
{
  background-color:white;
}

.property-card:hover .property-social-icons:hover
{
  background-color:blue;
  cursor:pointer;
} */
 @media screen and (max-width: 768px) {
  .blockquote-theme.page-header h4{
    font-size: 16px;
  }
  .s-py-60 .container{
    padding-top: 30px;
  }
 }
</style>
