<template>
  <section class="ls s-py-90 s-py-xl-50 dont-copy">
    <div class="container" id="widget-container">
      <div class="row">
        <div class="col-md-11 col-12">
          <div class="chanel-header">
            <div class="d-inline-block">
              <!-- <img :src="chanelThumbnail.url" class="chanel-thumbnail"> -->
              <img src="https://cdn.vanaroma.com/new_website/img/nilampedia.jpg" class="chanel-thumbnail">
            </div>
            <div class="d-inline-block chanel-info">
                <h4>NilamPedia</h4>
                <div>
                  <span>{{ subscribers }} Subscribers</span>
                  <span class="divider"></span>
                  <span>{{ totalVideo }} Videos</span>
                  <span class="divider"></span>
                  <span>{{ views }} Views</span>
                </div>
                <p class="chanel-description">
                  NilamPedia is a not-for-profit YouTube channel launched as part of the Patchouli sustainability project spearheaded by Van Aroma & Symrise. NilamPedia focusses on providing farmers, and distillers of Patchouli Oil, in-depth explanations/instructional videos for every step of the Patchouli cultivation process, as well as content dedicated to solving the various issues specific to the Patchouli crop (Irrigation, Diseases, nutrient deficiencies, land preparation, land management, protection against extreme weather, etc.)
                </p>
            </div>
          </div>
        </div>
        <div class="col-md-1 col-12 subscribe-btn">
          <div class="g-ytsubscribe" data-channelid="UCpiCpJGE23sVafcBcAfe3Ag" data-layout="default" data-theme="dark" data-count="hidden"></div>
        </div>
        <div class="col-12">
          <div class="text-center mt-5 p-5" v-if="getTry >= 5">
              <p>Failed to get request</p>
              <a class="link-reload" href="https://vanaroma.com/nilampedia">Reload Page</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-12 mt-5" v-for="video in videoList" :key="video.id">
          <div class="video-card" v-if="video.id.videoId">
            <iframe class="video-grid" :src="'https://www.youtube.com/embed/'+video.id.videoId" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="row mt-100">
        <div class="col-md-12 text-center">
          <a href="https://www.youtube.com/@Nilampedia" target="_blank" class="btn-community"><i class="fa-brands fa-youtube"></i> Our Channel</a>
          <a href="https://www.facebook.com/nilampedia" target="_blank" class="btn-community mx-2"><i class="fa-brands fa-facebook"></i> Follow Us</a>
          <a href="https://www.facebook.com/groups/nilampedia/" target="_blank" class="btn-community"><i class="fa-brands fa-facebook"></i> Our Community</a>
        </div>
      </div>
    </div>
  </section>
  <div class="divider-40 divider-lg-150"></div>
</template>
<style src="@/assets/css/about.css" scoped></style>
<script>
import axios from "axios";
export default {
  name: 'SustainabilityPage',data(){
    return{
      chanel:[],
      chanelStatistics:[],
      chanelThumbnail:[],
      videos:[],
      subscribers:'',
      views:'',
      totalVideo:'',
      getTry:0,
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
      apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)

      let oldJS=document.getElementById('reInit');
      if(oldJS){
        oldJS.remove();
      }
      let reInit = document.createElement('script')
      reInit.setAttribute('id', 'reInit')
      reInit.setAttribute('src', 'https://apis.google.com/js/platform.js')
      document.body.appendChild(reInit)

      this.getVideo()
  },
  methods:{
    getVideo(){
      axios.get(this.apiUrl+'/webx/r_youtube_data/?id=1')      
      .then((response) => {
        if(response.data.data == undefined){
            console.log('retrying to get data')
            if(this.getTry < 5){
                setTimeout(() => {
                    this.getVideo()
                    this.getTry++
                }, 1000);
            }
        }else{
          //console.log(JSON.parse(response.data.data))
          this.chanel=JSON.parse(response.data.data.data_chanel.replace(/\n/g, '')).items[0].snippet
          this.chanelStatistics=JSON.parse(response.data.data.data_chanel.replace(/\n/g, '')).items[0].statistics
          this.chanelThumbnail=JSON.parse(response.data.data.data_chanel.replace(/\n/g, '')).items[0].snippet.thumbnails.medium
          this.videos=JSON.parse(response.data.data.data_video.replace(/\n/g, '')).items
          this.subscribers=parseInt(this.chanelStatistics.subscriberCount).toLocaleString()
          this.totalVideo=parseInt(this.chanelStatistics.videoCount).toLocaleString()
          this.views=parseInt(this. chanelStatistics.viewCount).toLocaleString()
          console.log(this.videos)
        }
      })
      .catch((error) => {
          console.log(error)
      });
    }
  },
  computed: {
    videoList: function () {
      return this.videos.filter(v => v.id.kind === 'youtube#video')
    }
  }
}
</script>
<style>
.chanel-header{
  padding: 20px 10px;
}
.chanel-header h4{
  margin-bottom: 0px;
}
.chanel-info span{
  font-size: 14px;
  display: inline-block;
}
.chanel-info .divider{
  font-size: 6px;
    margin: 2px 15px;
    width: 5px;
    height: 5px;
    background-color: #484848;
    border-radius: 50%;
}
.chanel-thumbnail{
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
  margin-top: -120px;
}
.subscribe-btn{
  text-align: right;
  padding-top: 50px;
}
.video-grid{
  width: 100%;
  height: 250px;
  border-radius: 10px;
  background-color: #eee;
}
.chanel-description{
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}
.chanel-info{
  width: 85%;
}
.btn-community{
  cursor: pointer;
  line-height: 1.3em;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 10px 20px;
  margin-top: 5px;
  background: transparent;
  border: 1px solid #2d3545;
  color: #2d3545;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}
@media (max-width: 767px) {
  .subscribe-btn{
    text-align: left;
    padding: 0px 20px;
  }
  .chanel-info{
    width: 100%;
  }
}
</style>