<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>CANANGA</h3>
                <h5>Cananga odorata</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row mb-5">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/cananga.jpg'" class="img-source p-4" alt="cananga oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction to Cananga Oil</h4>
                        <p>Cananga oil is an essential oil derived from the flowers of cananga tree, which is native to Asia. It has a sweet, floral and slightly woody aroma</p>
                        <p>The height of the Cananga tree can make harvesting dangerous, as it can reach several dozen meters. One must climb a ladder, then the tree, holding a long stick, to loosen the flowers, which fall to the ground to then be gathered. The Cananga blossoms are then hydro-distilled to obtain the essential oil. In perfumery, Cananga essential oil has long been viewed as a less-expensive alternative to the very pricey ylang-ylang essence. Today, Cananga essential oil has been deemed as having its own olfactory qualities. It has a strong floral character and jasmine and woody facets. In aromatherapy, this oil is used as an antidepressant and as a sedative. It is also considered to be an aphrodisiac and is believed to stimulate the circulatory system.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 mt-5">
                        <h4>A brief insight into the Cananga Oil Industry in Indonesia.</h4>
                        <p>Cananga is of the same family as Ylang Ylang which is found in areas such Madagascar and in mainly used when the availability of Ylang Ylang goes down or  if the cost is prohibitive</p>
                        <p>Mainly used in the fragrance industry, it is grown only in the East Java region of Blitar and its surrounding areas. Produced and exported in small volumes, it has only 3-4 exporters from Indonesia. While Cananga oil itself is fairly expensive, it is considered to be a cheaper alternative to Ylang-ylang, and often even incorrectly marketed as such.</p>
                    </div>
                    <div class="col-md-6 mt-5">
                       <p class="text-center"><b>Regions of Cultivation</b></p>
                        <div class="mt-2">
                            <img :src="imageUrl+'new_website/images/botanical-source/map-cananga.jpg'" class="w-100" alt="cananga oil indonesia">
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Cananga Oil ?</h4>
                        <p class="text-center">
                          We get all our Cananga Oil from local Indonesian farmers and accumulators who harvest and hydro-distill the flowers to produce a crude form of Cananga Oil.
                        <br>CANANGA OIL DISTILLATION IN 3 STEPS
                        </p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_4.jpg'" alt="cananga oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>PLANTATION</h5>
                                                <p>Cananga is a member of the Custard Apple family, Annonaceae. This fragrant tree is indigenous to Philippines and Indonesia. Generally, most of the members of this plant family grow up to an average height of 12 mts. The flower is drooping, long-stalked, with six narrow, greenish-yellow petals and yields the highly fragrant essential oil.  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_4.jpg'" alt="cananga oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>COLECTING FLOWERS</h5>
                                                <p>Flowers from the mature Cananga tree are collected throughout the year and sent for hydro distillation. The flowers should be harvested during the early morning hours as they contain more essential oil during night. The ideal harvesting time is just before daybreak. As the day progresses, especially in strong sunlight, the oil content rapidly diminishes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3_2.jpg'" alt="cananga oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>DISTILATION</h5>
                                                <p>
                                                    Hydro distillation is used to isolate, separate and purify the oil content from the flowers to provide optimum quality of Cananga Oil.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_2.jpg'" alt="cananga oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>CANANGA OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="1241b280-c930-4c97-a9df-2d22490679a0"/>
        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Cananga Product List</h4>
                    </div>
                    <ProductList source="1241b280-c930-4c97-a9df-2d22490679a0"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'CanangaPage',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
    }
  },
  components:{
        ProductList,
        FaqElement
},
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Cananga Oil - Van Aroma';
  }
}
</script>
