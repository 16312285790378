<template>
   <!-- job list -->
    <div v-if="!job">
        <section class="ds s-overlay s-py-90 s-py-xl-150 career-title">
            <div class="container">
            <div class="row">

                <div class="col-md-12 text-center">
                <h1 class="emptytext">Career</h1>
                </div>

            </div>
            </div>
        </section>
        <section class="ls s-py-90 dont-copy">
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-12">
                <blockquote class="blockquote-theme">
                    <h5 class="color-main">Unlock Your Potential with Van Aroma : Explore Exciting Career Opportunities</h5>
                    <p>
                    Are you ready to embark on a journey where innovation meets ambition? Welcome to Van Aroma, where we believe in fostering a workplace that not only values your skills but also empowers your growth. We're on the lookout for passionate individuals who are eager to make a meaningful impact in their careers. Join us, and together, let's redefine success, push boundaries, and create a future where your professional aspirations become reality. Discover your potential with Van Aroma – where every career path is a story waiting to be written.
                    </p>
                </blockquote>
                </div>
            </div>
            </div>
        </section>
        <section class="ls dont-copy">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-12">
                        <hr>
                    </div>
                </div>
            </div>
        </section>
        <section class="ls s-py-90 dont-copy">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-12">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h5>Available Openings</h5>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <div class="col-6">
                                                <select class="form-control" v-model="filterLocation">
                                                    <option value="">All Location</option>
                                                    <option v-for="(location, index) in locations" :key="index" :value="location">{{ dumpLocation(location) }}</option>
                                                </select>
                                            </div>
                                            <div class="col-6">
                                                <select class="form-control" v-model="filterDepartment">
                                                    <option value="">All Department</option>
                                                    <option v-for="(department, index) in departments" :key="index" :value="department">{{ department }}</option>
                                                </select>
                                            </div>
                                            <!-- <div class="col-4">
                                                <input type="text" class="form-control" placeholder="search job.." v-model="search">
                                            </div>
                                            <div class="col-2">
                                                <button type="button" class="btn-search-job" @click="getJobs()"><span class="d-none d-md-block">Searh</span> <i class="fas fa-search d-block d-md-none"></i></button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-50 text-muted" v-if="emptyResult">
                                <span>Sorry, there are currently no jobs available</span>
                            </div>
                           <div class="col-12" v-if="isLoading">
                                <div class="text-center">
                                    <div class="loading mt-5">
                                        <img src="@/assets/img/loading.gif" >
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-50">
                                <div class="row">
                                    <div class="col-md-6" v-for="(job, index) in jobList" :key="index">
                                        <div class="job-card">
                                            <div class="title">
                                                {{ job.job_title }} - {{ job.job_level }}
                                            </div>
                                            <!-- <div class="department">
                                                {{ job.department }} -  
                                            </div> -->
                                            <div class="location">
                                                <i class="fas fa-map-marker me-2"></i>{{ dumpLocation(job.location) }}
                                            </div>
                                            <div class="description" v-html="job.job_description.substring(0, 100)+'.....'"></div>
                                            <div class="footer">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <router-link :to="'/career/'+job.slug" class="detail-link">View Detail</router-link>
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <div class="dropdown">
                                                            <button class="btn btn-default btn-share" data-bs-toggle="dropdown"><i class="fa fa-share-nodes"></i></button>
                                                            <div class="dropdown-menu dropdown-share" aria-labelledby="sort-text">
                                                                <span class="menu-header">Share</span>
                                                                <a class="dropdown-item" href="#" @click.prevent="copyLink('https://vanaroma.com/career/'+job.slug)"><i class="fa fa-copy me-2"></i> Copy Link</a>
                                                                <a class="dropdown-item" :href="'https://api.whatsapp.com/send?text='+job.job_title+' job vacancies at PT Van Aroma https://vanaroma.com/career/'+job.slug" target="_blank"><i class="fab fa-whatsapp me-2"></i> Whatsapp</a>
                                                                <a class="dropdown-item" :href="' https://www.linkedin.com/sharing/share-offsite/?url=https://vanaroma.com/career/'+job.slug" target="_blank"><i class="fab fa-linkedin me-2"></i> Linkedin</a>
                                                                <a class="dropdown-item" :href="'https://www.facebook.com/sharer/sharer.php?u=https://vanaroma.com/career/'+job.slug" target="_blank"><i class="fab fa-facebook me-2"></i> Facebook</a>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- job detail -->
    <div v-if="job">
        <div v-if="notFound">
            <section class="s-py-50 error-404 not-found page_404 s-overlay mobile-overlay">
                <div class="container">
                    <div class="row">

                        <div class="col-sm-12 text-center">
                            <img src="https://cdn.vanaroma.com/new_website/images/404.webp">
                            <!-- .page-header -->
                            <div class="page-content">
                                <h4 class="mb-15">oops...</h4>
                                <p class="fw-600">We're sorry, but something went wrong</p>
                                <div class="divider-50"></div>
                                <!-- <p>
                                    <a href="./" class="btn btn-maincolor btn-medium">Go to homepage</a>
                                </p> -->
                            </div>
                            <!-- .page-content -->
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="ds s-overlay s-py-90 s-py-xl-150">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h3 class="job-title">{{ jobDetail.job_title }} - {{ jobDetail.job_level }}</h3>
                            <!-- <h4 class="job-department">{{ jobDetail.department }} -  </h4> -->
                            <h5 class="job-location"><i class="fas fa-map-marker me-2"></i> {{ dumpLocation(jobDetail.location) }}</h5>
                            <div class="text-center">
                                <a href="#modalApply" data-bs-toggle="modal" class="btn-apply" v-if="!hasApply">Apply Now</a>
                                <a href="#" class="btn-apply disabled" v-else>application sent</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="ls s-py-90 dont-copy">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12" v-if="isLoading">
                            <div class="text-center">
                                <div class="loading mt-5">
                                    <img src="@/assets/img/loading.gif" >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 col-12">
                            <blockquote class="blockquote-theme">
                                <div class="mb-3 text-muted">
                                    Last Update on {{ jobDetail.last_modification_date }}
                                </div>
                                <div class="mb-5">
                                    <h6 class="color-main">Job Description</h6>
                                    <p v-html="jobDetail.job_description"></p>
                                </div>

                                <div class="mb-5">
                                    <h6 class="color-main">Requirements</h6>
                                    <p v-html="jobDetail.qualification"></p>
                                </div>
                                
                                <div v-if="jobDetail.benefit">
                                    <h6 class="color-main">Benefits</h6>
                                    <p v-html="jobDetail.benefit"></p>
                                </div>
                            </blockquote>
                        </div>
                        <div class="col-md-8 col-12 mt-5">
                            <div class="row">
                                <div class="col-9">
                                    Share on : 
                                    <a class="share-link" href="#" @click.prevent="copyLink('https://vanaroma.com/career/'+jobDetail.slug)"><i class="fa fa-copy me-2"></i></a>
                                    <a class="share-link" :href="'https://api.whatsapp.com/send?text='+jobDetail.job_title+' job vacancies at PT Van Aroma https://vanaroma.com/career/'+jobDetail.slug" target="_blank"><i class="fab fa-whatsapp me-2"></i></a>
                                    <a class="share-link" :href="' https://www.linkedin.com/sharing/share-offsite/?url=https://vanaroma.com/career/'+jobDetail.slug" target="_blank"><i class="fab fa-linkedin me-2"></i></a>
                                    <a class="share-link" :href="'https://www.facebook.com/sharer/sharer.php?u=https://vanaroma.com/career/'+jobDetail.slug" target="_blank"><i class="fab fa-facebook me-2"></i></a>
                                </div>
                                <div class="col-3 text-end">
                                    <router-link to="/career/" class="detail-link">Back</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <!-- Modal document apply job -->
    <div class="modal fade" id="modalApply" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-body job-application">
                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" id="closeApplication" aria-label="Close"></button>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" :style="'width: '+progress+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <Transition name="slide-fade" mode="out-in">
                        <div v-if="steps==1" class="mt-3">
                            <div class="form-title">Personal Information</div>
                            <div class="form-group mb-3">
                                <label>ID Number (NIK) </label>
                                <input type="text" aria-required="true" class="form-control" v-model="job_application.id_number" maxlength="16" @keypress="allowOnlyNumbers">
                                <div class="error-message" v-if="errorForm.nik">ID Number/ NIK must contain 16 digits</div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="d-none d-md-block">Full Name</label>
                                        <label class="d-md-none">Name</label>
                                        <select class="form-control" v-model="job_application.gender">
                                            <option value="1">Mr</option>
                                            <option value="0">Ms</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <label></label>
                                    <div class="form-group">
                                        <input type="text" aria-required="true" class="form-control" v-model="job_application.name">
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Place of Birth </label>
                                        <input type="text" aria-required="true" class="form-control" v-model="job_application.pob">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Date of Birth</label>
                                        <input type="date" aria-required="true" class="form-control" v-model="job_application.dob">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label>Last Education</label>
                                <select class="form-control" v-model="job_application.last_education">
                                    <option value="SMA">SMA / SMK</option>
                                    <option value="D3">D3</option>
                                    <option value="S1">S1</option>
                                    <option value="S2">S2</option>
                                    <option value="S3">S3</option>
                                </select>
                            </div>
                        </div>
                        <div v-else-if="steps==2" class="mt-3">
                            <div class="form-title">Contacts Information</div>
                            <p class="text-center" style="margin-top: -25px;font-size: 10px;">Please fill in correctly, we will use this to contact you</p>
                            <div class="form-group mb-3">
                                <label>Whatsapp Number </label>
                                <input type="text" aria-required="true" class="form-control" v-model="job_application.phone" @keypress="allowOnlyNumbers">
                            </div>
                            <div class="form-group mb-3">
                                <label>Email </label>
                                <input type="email" aria-required="true" class="form-control" v-model="job_application.email">
                                <div class="error-message" v-if="errorForm.email">Email is invalid</div>
                            </div>
                            <div class="form-group mb-3">
                                <label>Address </label>
                                <textarea v-model="job_application.address" class="form-control" rows="4"></textarea>
                            </div>
                        </div>
                        <div v-else-if="steps==3" class="mt-3">
                            <div class="form-title">Attachment</div>
                            <p class="text-center" style="margin-top: -25px;font-size: 10px;">Format .pdf max size 2mb</p>
                            <div class="form-group mb-4">
                                <label>Resume</label> 
                                <input type="file" class="form-control mt-2" @change="selectResume">
                                <div class="error-message" v-if="errorForm.resume">Extension or file size is not allowed</div>
                            </div>
                            <div class="form-group mb-4">
                                <label>Other Certificate </label><i>(optional)</i>
                                <input type="file" class="form-control mt-2" @change="selectCertificate">
                                <div class="error-message" v-if="errorForm.certificate">Extension or file size is not allowed</div>
                            </div>
                        </div>
                        <div  v-else-if="steps==4" class="mt-3">
                            <div class="form-title">Tell us why we should choose you</div>
                            <p class="text-center" style="margin-top: -25px;font-size: 10px;">Describe interesting things about you such as strengths and experiences</p>
                            <div class="form-group mb-3">
                               <textarea class="form-control" rows="7" v-model="job_application.notes"></textarea>    
                            </div>
                            <div class="error-response" v-if="errorResponse" v-html="errorResponse"></div>
                        </div>
                    </Transition>
                </div>
                <div class="modal-footer">
                    <Transition name="slide-fade" mode="out-in">
                        <button type="button" class="btn-back btn-outline-darkgrey" @click.prevent="backStep()" v-if="steps > 1">Back</button>
                    </Transition>
                    <Transition name="slide-fade" mode="out-in">
                        <button type="button" class="btn-next btn-outline-darkgrey" @click.prevent="nextStep()" v-if="steps < 4">Next</button>
                        <button type="button" class="btn-next btn-outline-darkgrey" v-else-if="steps == 4" @click="validateApplication()" id="btnApply">Apply</button>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import validator from 'validator';
export default {
  name: 'DistributionNetwork',
  data(){
    return{
        imageUrl: 'https://cdn.vanaroma.com/',
        apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
        getTry:0,
        isLoading:false,
        filterLocation:'',
        filterDepartment:'',
        search:'',
        jobList:[],
        locations:[],
        departments:[],
        jobDetail:[],
        emptyResult:false,
        notFound:false,
        steps:1,
        progress:25,
        errorForm:{
            'nik':false,
            'email':false,
            'resume':false,
            'certificate':false
        },
        job_application:{
            'id_number':'',
            'name':'',
            'gender':1,
            'phone':'',
            'email':'',
            'pob':'',
            'dob':'',
            'last_education':'',
            'address':'',
            'resume':'',
            'certificate':'',
        },
        appliedJob:(localStorage.getItem('appliedJob')) ? JSON.parse(localStorage.getItem('appliedJob')) : [],
        hasApply:false,
        errorResponse:'',
        recid_job_vacancies:''
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Career - Van Aroma';
      if(this.job){
        this.getJobDetail()
      }else{
        this.getJobs()
      }
  },
  methods:{
    getJobs(){
        this.isLoading = true;
        let formdata = new FormData();
        formdata.append("location", this.filterLocation);
        formdata.append("department", this.filterDepartment);
        formdata.append("search", this.search);
        let bodyContent =  formdata;
        let reqOptions = {
            url: this.apiUrl+"/webx/r_job_vacancies/",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
        .then((response) => {
            if(response.data.data == undefined){
                console.log('retrying to get jobs')
                if(this.getTry < 5){
                    setTimeout(() => {
                        this.getJobs()
                        this.getTry++
                    }, 1000);
                }
            }else{
                if(Object.keys(response.data.data).length !== 0){
                    this.jobList=response.data.data
                    // Extract department names and remove duplicates
                    const dataDepartment = Array.from(new Set(Object.values(response.data.data).map(item => item.department)));
                    // Sort departments alphabetically
                    this.departments= dataDepartment.sort();

                     // Extract department names and remove duplicates
                    const dataLocation = Array.from(new Set(Object.values(response.data.data).map(item => item.location)));
                    // Sort departments alphabetically
                    this.locations= dataLocation.sort();
                    this.emptyResult=false
                    
                }else{
                  this.jobList=[];
                  this.emptyResult=true
                }
                
            }
            this.isLoading = false;
        })
        .catch((error) => {
            console.log(error)
            this.isLoading = false;
        });
    },
    dumpLocation(loc){
       if(loc){
        let explode = loc.split('|')
        return explode[0]+', '+explode[1]+' - '+explode[2]
       }
    },
    getJobDetail(){
        this.isLoading = true;
        let slug=this.job
        let formdata = new FormData();
        formdata.append("slug", slug);
        let bodyContent =  formdata;
        let reqOptions = {
            url: this.apiUrl+"/webx/r_job_detail/",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
        .then((response) => {
            if(response.data.data == undefined){
                console.log('retrying to get job')
                if(this.getTry < 5){
                    setTimeout(() => {
                        this.getJobDetail()
                        this.getTry++
                    }, 1000);
                }else{
                    this.notFound=true
                }
                //this.notFound=true
            }else{
                if(Object.keys(response.data.data).length !== 0){
                    this.jobDetail=response.data.data;
                    this.recid_job_vacancies=this.jobDetail.recid_job_vacancies
                    this.hasApply=this.appliedJob.some(item => item.recid == this.recid_job_vacancies)
                    if (this.jobDetail.status == '1') {
                        this.notFound=false
                    } else {
                        this.notFound=true
                    }
                    
                    
                }else{
                  this.jobDetail=[];
                  this.notFound=true
                }
                
            }
            this.isLoading = false;
        })
        .catch((error) => {
            this.isLoading = false;
            console.log(error)
        });
    },
    copyLink(link){
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = link;
        document.body.appendChild(textarea);
        // Select and copy the text
        textarea.select();
        document.execCommand('copy');
        // Remove the temporary textarea
        document.body.removeChild(textarea);
        // You can also provide user feedback, e.g., a tooltip or notification
        this.$swal(
            {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Link has been copied',
                showConfirmButton: false,
                timer: 2500
            }
        );
    },
    nextStep(){
        this.steps++;
        this.progress+=25;
    },
    backStep(){
        if(this.steps > 0){
            this.steps--;
            this.progress-=25;
        }
    },
    allowOnlyNumbers(event) {
      // Allow only numeric characters (charCode 48 to 57)
      if (!(event.charCode >= 48 && event.charCode <= 57)) {
        event.preventDefault();
      }
    },
    selectResume(event) {
        let maxSize = 2000; //in kb
        let allowed = ['pdf'];
        let size = event.target.files[0].size; //satuan bite
		let ext = event.target.files[0].name.split('.').pop(); //fungsi get extention file
        
        if ((size / 1000) > maxSize || !allowed.includes(ext.toLowerCase())) {
			this.job_application.resume = ''
            this.errorForm.resume=true
		}else{
            this.job_application.resume = event.target.files[0];
            this.errorForm.resume=false
        }
    },
    selectCertificate(event) {
        let maxSize = 2000; //in kb
        let allowed = ['pdf'];
        let size = event.target.files[0].size; //satuan bite
		let ext = event.target.files[0].name.split('.').pop(); //fungsi get extention file
        
        if ((size / 1000) > maxSize || !allowed.includes(ext.toLowerCase())) {
			this.job_application.certificate = ''
            this.errorForm.certificate=true
		}else{
            this.job_application.certificate = event.target.files[0];
            this.errorForm.certificate=false
        }
    },
    validateApplication(){
        let emptyFild=0;
        for (const field in this.job_application) {
           if(this.job_application[field] == '' && field !='certificate'){
              emptyFild++;
           }
        }
        this.errorForm.nik = (this.job_application.id_number.length < 16) ? true : false
        if(emptyFild == 0 && !this.errorForm.nik){
            if(validator.isEmail(this.job_application.email)){
                this.errorForm.email=false;
                this.sendApplication();
            }else{
                this.errorForm.email=true;
                this.steps=2;
                this.progress=50;
                this.$swal(
                    {
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Failed, Email is not Valid',
                    showConfirmButton: false,
                    timer: 2500
                    }
                );
            }
        }else{
            this.steps=1;
            this.progress=25;
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Please fill all information',
                showConfirmButton: false,
                timer: 2500
                }
            );
        }
        
    },
    sendApplication(){
        let btn=document.getElementById('btnApply');
        btn.disabled=true
        btn.innerHTML='<i class="fa fa-spinner fa-spin"></i> Sending Application';
        let formdata = new FormData();
        for (const key in this.job_application) {
            formdata.append(key, this.job_application[key]);
        }
        formdata.append("recid_job_vacancies", this.recid_job_vacancies);
        let bodyContent =  formdata;
        let reqOptions = {
            url: this.apiUrl+"/webx/c_job_application/",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
        .then((response) => {
            let callback = response.data.data
            if(callback.status == "success"){
                btn.innerHTML='Application sent';
                this.saveAppliedJob()
                this.errorResponse=''
            }else{
                this.errorResponse=callback.message
            }
        })
        .catch((error) => {
            console.log(error)
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Filed to send request, please try again',
                showConfirmButton: false,
                timer: 2500
                }
            );
        });
        
    },
    saveAppliedJob(){
        let btnClose=document.getElementById('closeApplication')
        let application={
            'recid':this.recid_job_vacancies,
            'time': Math.floor(new Date().getTime() / 1000)
        }
        this.appliedJob.push(application)
        localStorage.setItem('appliedJob', JSON.stringify(this.appliedJob))
        btnClose.click()
        this.hasApply=true
        this.$swal(
            {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Application has been sent',
                showConfirmButton: false,
                timer: 2500
            }
        );
        
    }
  },
  watch: {
        product:function(val){
            if(Object.keys(val).length === 0 && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getProduct()
                    this.getTry++
                }, 1000);
            }
        },
        job:function(){
            if(this.job){
                this.getJobDetail()
            }
        }
  },
  computed:{
        job(){
            return this.$route.params.job;
        },
        
  },
}
</script>
<style scoped>
    .career-title{
        background: url('https://cdn.vanaroma.com/new_website/images/career-bg.jpg');
        background-position: center;
    }
    .btn-search-job{
        width: 100%;
        padding: 10px !important;
    }
    .job-card{
        padding: 30px 15px 15px 15px;
        margin-top: 20px;
        border:1px solid #eee;
    }
    .job-card .title{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
        color: #32b176;
    }
    .job-card .department{
        margin-bottom: 5px;
    }
    .job-card .location{
        font-size: 12px;
        color: #b9b4b4;
    }
    .job-card .description{
        margin-top: 20px;
    }
    .job-card .description{
        list-style: none !important;
    }
    .job-card .footer{
        margin-top: 25px;
        border-top: 1px solid #eee;
        padding-top: 15px;
    }
    .btn-share{
        font-size: 20px;
        padding: 0px;
    }
    .btn-share:focus{
        box-shadow: none;
    }
    .dropdown-share{
        padding: 15px;
    }
    .dropdown-share .dropdown-item{
        color: #6e6e6e;
        margin: 0;
        padding: 5px;
    }
    .dropdown-share .menu-header{
        border-bottom: 1px solid #eee;
        display: block;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
    .detail-link{
        border: 1px solid ;
        padding: 5px 15px;
    }
    .job-title{
        margin-bottom: 10px !important;
    }
    .job-department{
        margin-top: 10px !important;
        font-weight: 400;
        color: #cbcbcb;
        font-style: italic;
    }
    .job-location{
        margin-top: 0px !important;
        font-weight: 400;
        color: #cbcbcb;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .btn-apply{
        background-color: #32b176;
        color: #fff;
        padding: 10px 30px;
        border-radius: 30px;
    }
    .btn-apply.disabled{
        background-color: #89c4a8;
    }
    .share-link{
        padding: 5px;
        font-size: 18px;
    }
    .btn-next,.btn-back{
        padding: 5px 30px !important;
        min-width: unset !important;
    }
    .btn-next:disabled{
        border-color: #6e6e6e;
        color: #6e6e6e;
    }
    .job-application .progress{
        margin: 10px 0px;
        height: 7px;
    }
    .job-application .form-control{
        height: 30px;
        font-size: 14px;
        max-height: unset;
    }
    .job-application textarea.form-control{
        min-height: unset;
        height: unset;
    }
    .job-application .form-title{
        font-size: 16px;
        margin-bottom: 25px;
        font-weight: bold;
        text-align: center;
    }
    .job-application .progress-bar{
        height: 7px;
    }

    .job-application .btn-close:hover{
        color: unset;
        background-color: unset;
    }
    .error-message{
        color: #ff4c4c;
        font-style: italic;
        font-size: 12px;
        margin-top: 5px;
    }
    .error-response, .error-response p{
        color: #ff4c4c;
        font-style: italic;
        font-size: 12px;
        margin-top: 5px;
    }
    select {
        /*webkit browsers */
        -webkit-appearance: menulist !important;
        /*Firefox */
        -moz-appearance: menulist !important;
        /* modern browsers */
        appearance: menulist !important;
    }
</style>
