<template>
  <section class="ls s-py-md-90 dont-copy">
    <div class="container product-catalog mt-3">
      <div class="row">
        <div class="col-md-3" id="filterSidebar">
            <div class="product-filter">
                <button class="btn-close-filter" @click="closeFilter()"><i class="fa-solid fa-angles-left"></i></button>
                <h5>Filter Results</h5>
                <div class="category-filter">
                    <h6>
                        <a data-bs-toggle="collapse" href="#filterCategory" role="button" aria-expanded="true" aria-controls="filterCategory">Product Category</a>
                    </h6>
                    <div id="filterCategory" class="collapse show">
                        <div class="category-item" v-if="!category">
                            <div class="checkbox">
                                <div>
                                    <input type="checkbox" id="checkAllCategory" @change="checkAllCategories($event)" checked />
                                    <label for="checkAllCategory">
                                        <span> </span> <b>All Category</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="category-item" v-for="ItemCategory in categories" :key="ItemCategory.recid_product_category">
                            <div class="checkbox" v-if="!category || category == ItemCategory.product_category_name.toLowerCase()">
                                <div>
                                    <input type="checkbox" class="check-category filter-product" @change="filterProduct(ItemCategory.recid_product_category,$event)" :id="'check'+ItemCategory.recid_product_category" :value="ItemCategory.recid_product_category" :checked="!filter || objFilter.includes(ItemCategory.recid_product_category)" />
                                    <label :for="'check'+ItemCategory.recid_product_category">
                                        <span></span>
                                        <i class="language-lable" v-if="language === 'EN'">{{ ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'ID'">{{ (ItemCategory.product_category_name_indonesian) ? ItemCategory.product_category_name_indonesian.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'CN'">{{ (ItemCategory.product_category_name_chinese) ? ItemCategory.product_category_name_chinese.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'FR'">{{ (ItemCategory.product_category_name_french) ? ItemCategory.product_category_name_french.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'SP'">{{ (ItemCategory.product_category_name_spanish) ? ItemCategory.product_category_name_spanish.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'JP'">{{ (ItemCategory.product_category_name_japanish) ? ItemCategory.product_category_name_japanish.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'VN'">{{ (ItemCategory.product_category_vietnamese) ? ItemCategory.product_category_vietnamese.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'TR'">{{ (ItemCategory.product_category_name_turkish) ? ItemCategory.product_category_name_turkish.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'RU'">{{ (ItemCategory.product_category_name_russian) ? ItemCategory.product_category_name_russian.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'SA'">{{ (ItemCategory.product_category_name_arabic) ? ItemCategory.product_category_name_arabic.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'KR'">{{ (ItemCategory.product_category_name_korean) ? ItemCategory.product_category_name_korean.toUpperCase() : ItemCategory.product_category_name }}</i>
                                        <i class="language-lable" v-if="language === 'IT'">{{ (ItemCategory.product_category_name_italian) ? ItemCategory.product_category_name_italian.toUpperCase() : ItemCategory.product_category_name }}</i>
                                    </label>
                                     <span class="count-result" :data-category="ItemCategory.recid_product_category"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <h6 class="mt-4">
                        <a data-bs-toggle="collapse" href="#filterSources" role="button" aria-expanded="true" aria-controls="filterSources">Product Sources</a>
                    </h6>
                    <div class="collapse show" id="filterSources">
                        <div class="category-item">
                            <div class="checkbox">
                                <div>
                                    <input type="checkbox" id="checkAllsources" @change="checkAllSources($event)" checked />
                                    <label for="checkAllsources">
                                        <span> </span> <b>All Sources</b>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="category-item"  v-for="source in sources" :key="source.recid_product_sources">
                            <div class="checkbox">
                                <div>
                                    <input type="checkbox" class="check-sources filter-product" @change="filterProduct(source.recid_product_sources,$event)" :id="'check'+source.recid_product_sources" :value="source.recid_product_sources" :checked="!filter || objFilter.includes(source.recid_product_sources)" />
                                    <label :for="'check'+source.recid_product_sources">
                                    <span></span>
                                    <i class="language-lable" v-if="language === 'EN'">{{ source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'ID'">{{ (source.indonesian_name) ? source.indonesian_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'CN'">{{ (source.chinese_name) ? source.chinese_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'FR'">{{ (source.french_name) ? source.french_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'SP'">{{ (source.spanish_name) ? source.spanish_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'JP'">{{ (source.japanese_name) ? source.japanese_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'VN'">{{ (source.vietnamese_name) ? source.vietnamese_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'TR'">{{ (source.turkish_name) ? source.turkish_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'RU'">{{ (source.russian_name) ? source.russian_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'SA'">{{ (source.arabic_name) ? source.arabic_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'KR'">{{ (source.korean_name) ? source.korean_name.toUpperCase() : source.product_source_name }}</i>
                                    <i class="language-lable" v-if="language === 'IT'">{{ (source.italian_name) ? source.italian_name.toUpperCase() : source.product_source_name }}</i>
                                    </label>
                                    <span class="count-result" :data-category="source.recid_product_sources"></span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h6 class="mt-4">EU REACH Status</h6>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="check-reach" @change="checkReach(1,$event)" :checked="!filter || objFilter.includes('reach')" />
                                <label for="check-reach">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    <i class="language-lable" v-if="language === 'EN'">Registered</i>
                                    <i class="language-lable" v-if="language === 'ID'">Terdaftar</i>
                                    <i class="language-lable" v-if="language === 'CN'">挂号的</i>
                                    <i class="language-lable" v-if="language === 'FR'">Inscrit</i>
                                    <i class="language-lable" v-if="language === 'SP'">Registrado</i>
                                    <i class="language-lable" v-if="language === 'JP'">登録済み</i>
                                    <i class="language-lable" v-if="language === 'VN'">đăng ký</i>
                                    <i class="language-lable" v-if="language === 'TR'">kayıtlı</i>
                                    <i class="language-lable" v-if="language === 'RU'">зарегистрирован</i>
                                    <i class="language-lable" v-if="language === 'SA'">مسجل</i>
                                    <i class="language-lable" v-if="language === 'KR'">등기</i>
                                    <i class="language-lable" v-if="language === 'IT'">Registrato</i>
                                </label>
                                <span class="count-result" data-category="reach-registered"></span>
                            </div>
                        </div>
                    </div>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="check-non-reach" @change="checkReach(0,$event)" :checked="!filter || objFilter.includes('non-reach')" />
                                <label for="check-non-reach">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    <i class="language-lable" v-if="language === 'EN'">Not Registered</i>
                                    <i class="language-lable" v-if="language === 'ID'">Tidak Terdaftar</i>
                                    <i class="language-lable" v-if="language === 'CN'">未注册</i>
                                    <i class="language-lable" v-if="language === 'FR'">Non Enregistré</i>
                                    <i class="language-lable" v-if="language === 'SP'">No Registrado</i>
                                    <i class="language-lable" v-if="language === 'JP'">未登録</i>
                                    <i class="language-lable" v-if="language === 'VN'">Chưa đăng ký</i>
                                    <i class="language-lable" v-if="language === 'TR'">Kayıtlı değil</i>
                                    <i class="language-lable" v-if="language === 'RU'">Не зарегистрирован</i>
                                    <i class="language-lable" v-if="language === 'SA'">غير مسجل</i>
                                    <i class="language-lable" v-if="language === 'KR'">등록되지 않은</i>
                                    <i class="language-lable" v-if="language === 'IT'">Non registrato</i>
                                </label>
                                <span class="count-result" data-category="reach-not-registered"></span>
                            </div>
                        </div>
                    </div>
                    <h6 class="mt-4">Product Form</h6>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="form-solid" @change="productFormFilter('solid',$event)" :checked="!filter || objFilter.includes('solid')" />
                                <label for="form-solid">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    <i class="language-lable" v-if="language === 'EN'">Solid</i>
                                    <i class="language-lable" v-if="language === 'ID'">Padat</i>
                                    <i class="language-lable" v-if="language === 'CN'">坚硬的</i>
                                    <i class="language-lable" v-if="language === 'FR'">Solide</i>
                                    <i class="language-lable" v-if="language === 'SP'">Sólido</i>
                                    <i class="language-lable" v-if="language === 'JP'">個体</i>
                                    <i class="language-lable" v-if="language === 'VN'">Chất rắn</i>
                                    <i class="language-lable" v-if="language === 'TR'">Sağlam</i>
                                    <i class="language-lable" v-if="language === 'RU'">Твердый</i>
                                    <i class="language-lable" v-if="language === 'SA'">صلب</i>
                                    <i class="language-lable" v-if="language === 'KR'">단단한</i>
                                    <i class="language-lable" v-if="language === 'IT'">Solido</i>
                                </label>
                                <span class="count-result" data-category="solid"></span>
                            </div>
                        </div>
                    </div>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="form-viscous" @change="productFormFilter('viscous',$event)" :checked="!filter || objFilter.includes('viscous')" />
                                <label for="form-viscous">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    <i class="language-lable" v-if="language === 'EN'">Viscous</i>
                                    <i class="language-lable" v-if="language === 'ID'">Kental</i>
                                    <i class="language-lable" v-if="language === 'CN'">粘稠的</i>
                                    <i class="language-lable" v-if="language === 'FR'">Visqueux</i>
                                    <i class="language-lable" v-if="language === 'SP'">Viscoso</i>
                                    <i class="language-lable" v-if="language === 'JP'">粘性</i>
                                    <i class="language-lable" v-if="language === 'VN'">Nhớt</i>
                                    <i class="language-lable" v-if="language === 'TR'">Viskoz</i>
                                    <i class="language-lable" v-if="language === 'RU'">Вязкий</i>
                                    <i class="language-lable" v-if="language === 'SA'">لزج</i>
                                    <i class="language-lable" v-if="language === 'KR'">점성</i>
                                    <i class="language-lable" v-if="language === 'IT'">Viscoso</i>
                                </label>
                                <span class="count-result" data-category="viscous"></span>
                            </div>
                        </div>
                    </div>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="form-liquid" @change="productFormFilter('liquid',$event)" :checked="!filter || objFilter.includes('liquid')" />
                                <label for="form-liquid">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    <i class="language-lable" v-if="language === 'EN'">Liquid</i>
                                    <i class="language-lable" v-if="language === 'ID'">Cairan</i>
                                    <i class="language-lable" v-if="language === 'CN'">液体</i>
                                    <i class="language-lable" v-if="language === 'FR'">Liquide</i>
                                    <i class="language-lable" v-if="language === 'SP'">Líquido</i>
                                    <i class="language-lable" v-if="language === 'JP'">液体</i>
                                    <i class="language-lable" v-if="language === 'VN'">Chất lỏng</i>
                                    <i class="language-lable" v-if="language === 'TR'">Sıvı</i>
                                    <i class="language-lable" v-if="language === 'RU'">Жидкость</i>
                                    <i class="language-lable" v-if="language === 'SA'">سائل</i>
                                    <i class="language-lable" v-if="language === 'KR'">액체</i>
                                    <i class="language-lable" v-if="language === 'IT'">Liquido</i>
                                </label>
                                <span class="count-result" data-category="liquid"></span>
                            </div>
                        </div>
                    </div>

                    <h6 class="mt-4">Green Status</h6>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="natural" @change="greenStatusFilter('natural',$event)" :checked="!filter || objFilter.includes('natural')" />
                                <label for="natural">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    Natural
                                </label>
                                <span class="count-result" data-category="natural"></span>
                            </div>
                        </div>
                    </div>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="bio-derived" @change="greenStatusFilter('bio-derived',$event)" :checked="!filter || objFilter.includes('bio-derived')" />
                                <label for="bio-derived">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    Bio-derived
                                </label>
                                <span class="count-result" data-category="bio-derived"></span>
                            </div>
                        </div>
                    </div>

                    <h6 class="mt-4">Upcycled</h6>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="upcycled" @change="upcycledFilter('upcycled',$event)" :checked="!filter || objFilter.includes('upcycled')" />
                                <label for="upcycled">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    Upcycled
                                </label>
                                <span class="count-result" data-category="upcycled"></span>
                            </div>
                        </div>
                    </div>
                    <div class="category-item">
                        <div class="checkbox">
                            <div>
                                <input type="checkbox" id="non-upcycled" @change="upcycledFilter('non-upcycled',$event)" :checked="!filter || objFilter.includes('non-upcycled')" />
                                <label for="non-upcycled">
                                    <span>
                                        <!-- This span is needed to create the "checkbox" element -->
                                    </span>
                                    non-upcycled
                                </label>
                                <span class="count-result" data-category="non-upcycled"></span>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
        <div class="col-md-9 product-content-container">
            <div class="product-top-bar" id="product-top-bar">
                <div class="row">
                    <div class="col-md-2 col-3">
                        <div class="view-mode">
                            <button  :class="{active : viewMode === 'list'}" @click.prevent="changeViewMode('list')"><i class="fa fa-list"></i></button>
                            <button  :class="{active : viewMode === 'grid'}" @click.prevent="changeViewMode('grid')"><i class="fa fa-th-large"></i></button>
                        </div>
                    </div>
                    <div class="col-md-6 col-9 d-md-block d-none">
                         <div class="search">
                            <form @submit.prevent="submitSearch()">
                                <input type="text" placeholder="AI Search..." id="search_product" v-model="search" @keyup="searchProduct()">
                                <button type="button" class="search-lable"><i class="fa fa-search" aria-hidden="true"></i></button>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4 col-9 text-center text-md-left">
                        <div class="sorting">
                            <button class="btn btn-default btn-product-language" data-bs-toggle="dropdown"><img :src="'/assets/images/flag/'+FlagIcon" class="flag-icon mb-1"> {{ language }} <i class="fa fa-caret-down"></i></button>
                            <div class="dropdown-menu dropdown-menu-right product-language-item" aria-labelledby="sort-text">
                                <a class="dropdown-item change-lang" :class="{active : language === 'EN'}" href="#"  @click="changeLanguage('EN')" data-language="english"><img src="/assets/images/flag/united-kingdom.svg" class="flag-icon"> EN</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'ID'}" href="#"  @click="changeLanguage('ID')"><img src="/assets/images/flag/indonesia-flag.svg" class="flag-icon"> ID</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'CN'}" href="#"  @click="changeLanguage('CN')"><img src="/assets/images/flag/china-flag.svg" class="flag-icon"> CN</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'FR'}" href="#"  @click="changeLanguage('FR')"><img src="/assets/images/flag/france-flag.svg" class="flag-icon"> FR</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'SP'}" href="#"  @click="changeLanguage('SP')"><img src="/assets/images/flag/spain.svg" class="flag-icon"> SP</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'JP'}" href="#"  @click="changeLanguage('JP')"><img src="/assets/images/flag/japan-flag.svg" class="flag-icon"> JP</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'VN'}" href="#"  @click="changeLanguage('VN')"><img src="/assets/images/flag/vietnam-flag.svg" class="flag-icon"> VN</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'TR'}" href="#"  @click="changeLanguage('TR')"><img src="/assets/images/flag/turkey-flag.svg" class="flag-icon"> TR</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'RU'}" href="#"  @click="changeLanguage('RU')"><img src="/assets/images/flag/russia-flag.svg" class="flag-icon"> RU</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'SA'}" href="#"  @click="changeLanguage('SA')"><img src="/assets/images/flag/sa.svg" class="flag-icon"> SA</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'KR'}" href="#"  @click="changeLanguage('KR')"><img src="/assets/images/flag/kr.svg" class="flag-icon"> KR</a>
                                <a class="dropdown-item change-lang" :class="{active : language === 'IT'}" href="#"  @click="changeLanguage('IT')"><img src="/assets/images/flag/italy-flag.svg" class="flag-icon"> IT</a>
                            </div>
                            | 
                            <span class="dropdown">
                                <span class="d-none d-md-inline">{{ sortLable }}</span>
                                <span class="d-md-none fas fa-arrow-down-a-z"></span>
                                <button class="btn btn-default btn-sorting" data-bs-toggle="dropdown"> {{ selectedSort }} <i class="fa fa-caret-down"></i></button>
                            <div class="dropdown-menu dropdown-menu-right product-language-item" aria-labelledby="sort-text">
                                <a class="dropdown-item sort-list" href="#" @click="sorting('searched')"><i class="fa fa-check" v-if="sort === 'searched' || sort === ''"></i> {{ mostSearched }}</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('most')"><i class="fa fa-check" v-if="sort === 'most'"></i> {{ mostPopular }}</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('low-to-high')"><i class="fa fa-check" v-if="sort === 'low-to-high'"></i> {{ priceLowToHigh }}</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('high-to-low')"><i class="fa fa-check" v-if="sort === 'high-to-low'"></i> {{ priceHighToLow }}</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('sources')"><i class="fa fa-check" v-if="sort === 'sources'"></i> {{ botanicalSource }}</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('recent')"><i class="fa fa-check" v-if="sort === 'recent'"></i> {{ mostRecent }}</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('az')"><i class="fa fa-check" v-if="sort === 'az'"></i> A - Z</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('natural')"><i class="fa fa-check" v-if="sort === 'natural'"></i> Natural</a>
                                <a class="dropdown-item sort-list" href="#" @click="sorting('bio-derived')"><i class="fa fa-check" v-if="sort === 'bio-derived'"></i> Bio-derived</a>
                            </div>
                            </span>
                        </div>
                    </div>

                    <div class="col-12 mt-4 d-md-none">
                         <div class="search">
                            <button class="btn-show-filter" @click="showFilter()"><i class="fas fa-filter"></i> </button>
                            <form @submit.prevent="submitSearch()">
                                <input type="text" placeholder="Catalog Search..." id="search_product2" v-model="search" @keyup="searchProduct2()">
                                <button type="button" class="search-lable"><i class="fa fa-search" aria-hidden="true"></i></button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <div class="loading mt-5" v-if="isLoading">
                <img src="@/assets/img/loading.gif">
            </div>
                <div v-else>
                    <div class="text-center mt-5 p-5" v-if="getTry >= 5">
                        <p>Failed to get request</p>
                        <a class="link-reload" href="https://vanaroma.com/product-catalog">Reload Page</a>
                    </div>
                    <div v-if="viewMode === 'list'">
                        <div class="product-content" id="product-content"  v-for="product in products" :key="product.recid_product">
                            <div :class="product.recid_product_category+' div-category'" v-if="!category || category == product.product_category_name.toLowerCase()" :style="(filter && objFilter.includes(product.recid_product_category) == false) ? {'display' : 'none'} : {}">
                                <div class="div-source" :class="product.recid_product_sources" :style="(filter && objFilter.includes(product.recid_product_sources) == false) ? {'display' : 'none'} : {}">
                                    <div class="reach-div" 
                                    :class="product.REACH_registration_no != '' ? 'reach-registered' : 'reach-not-registered'"
                                    :style="(filter && ((objFilter.includes('reach') == false && product.REACH_registration_no != '') || (objFilter.includes('non-reach') == false && product.REACH_registration_no == '') )) ? {'display' : 'none'} : {}"
                                    >
                                        <div :class="'product-form '+product.product_form.toLowerCase()" :style="(filter && (objFilter.includes(product.product_form.toLowerCase()) == false && product.product_form != '')) ? {'display' : 'none'} : {}">
                                            <div :class="'green-status '+product.green_status.toLowerCase()" :style="(filter && (objFilter.includes(product.green_status.toLowerCase()) == false && product.green_status != '')) ? {'display' : 'none'} : {}">
                                                <div class="upcycled-div" 
                                                    :class="(product.upcycled == 1)  ? 'upcycled' : 'non-upcycled'"
                                                    :style="(filter && ((objFilter.includes('upcycled') == false && product.upcycled == 1) || (objFilter.includes('non-upcycled') == false && product.upcycled != 1) )) ? {'display' : 'none'} : {}"
                                                    >
                                                    <div class="row product-row">
                                                        <div class="search-content d-none">
                                                                {{ 
                                                                    product.product_ipc.replace('-', '') + '<br>'+
                                                                    product.product_ipc + '<br>'+
                                                                    product.product_name + '<br>'+
                                                                    product.botanical_name + '<br>'+
                                                                    product.indonesian_name + '<br>'+
                                                                    product.chinese_name + '<br>'+
                                                                    product.french_name + '<br>'+
                                                                    product.spanish_name + '<br>'+
                                                                    product.japanese_name + '<br>'+
                                                                    product.vietnamese_name + '<br>'+
                                                                    product.CAS_no +'<br>'+
                                                                    product.CAS_no.replace('-', '') + '<br>'+
                                                                    product.FEMA_no + '<br>'+
                                                                    product.FEMA_no.replace('-', '')+'<br>'+
                                                                    product.EC_no+'<br>'+
                                                                    product.EC_no.replace('-', '')+'<br>'+
                                                                    product.product_synonym.replace(",", " ")+'<br>'+
                                                                    product.olfactory_profile.replace(",", " ")+'<br>'
                                                                }}
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="img-product-box d-none d-md-block">
                                                                <img v-if="!product.product_image" :src="imageUrl+'img/imageproduct/nope.jpg'" class="product-img">
                                                                <div v-else class="owl-carousel owl-loaded owl-drag owl-theme"  data-draggable="true" :data-dots="(product.product_image[1]) ? 'true' : 'false'" data-nav="true" data-navclass="owl-nav owl-nav-product-image" data-navprev='<span class="fa fa-angle-left" aria-hidden="true"></span>' data-navnext='<span class="fa fa-angle-right" aria-hidden="true"></span>' data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                                                    <div v-for="(img, index) in product.product_image" :key="index" class="img-container">
                                                                        <div class="img-placeholder" v-if="index == 0"></div>
                                                                        <img class="owl-lazy product-img" @click="showDetail(product.recid_masking,product.slug)" :data-src="imageUrl+'img/imageproduct/'+img.file_name">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-10 px-md-5 py-2 py-md-0">
                                                            <div class="row">
                                                                <div class="col-md-9 col-10">
                                                                    <div class="product-name" @click="showDetail(product.recid_masking,product.slug)"> 
                                                                        <span v-if="product.web_public.includes('product_name')">
                                                                            <span class="product-name-class" v-if="language === 'EN'">{{ product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'ID'">{{ (product.indonesian_name) ? product.indonesian_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'CN'">{{ (product.chinese_name) ? product.chinese_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'FR'">{{ (product.french_name) ? product.french_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'SP'">{{ (product.spanish_name) ? product.spanish_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'JP'">{{ (product.japanese_name) ? product.japanese_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'VN'">{{ (product.vietnamese_name) ? product.vietnamese_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'TR'">{{ (product.turkish_name) ? product.turkish_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'RU'">{{ (product.russian_name) ? product.russian_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'SA'">{{ (product.arabic_name) ? product.arabic_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'KR'">{{ (product.korean_name) ? product.korean_name : product.product_name }}</span>
                                                                            <span class="product-name-class" v-if="language === 'IT'">{{ (product.italian_name) ? product.italian_name : product.product_name }}</span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="botanical-name" @click="showDetail(product.recid_masking,product.slug)"  v-text="'ex-'+capitalize(product.botanical_name.toLowerCase())"></div>
                                                                    <div class="row">
                                                                            <div class="col-md-12">
                                                                                <span class="d-inline-block product-attr" v-if="product.web_public.includes('product_ipc')"><b>IPC : </b>{{ product.product_ipc }}</span>
                                                                                <span class="d-inline-block product-attr" v-if="product.web_public.includes('CAS_no') && product.CAS_no != 'NA' && product.CAS_no != ''">
                                                                                    <b>CAS : </b>
                                                                                    <a :href="product.cas_link" v-if="product.cas_link != ''" target="_blank">{{ product.CAS_no }}</a>
                                                                                    <span v-else v-text="product.CAS_no"></span>
                                                                                </span>
                                                                                <span class="d-inline-block product-attr" v-if="product.web_public.includes('FEMA_no') && product.FEMA_no != 'NA' && product.FEMA_no != ''"><b>FEMA : </b> <a :href="'https://www.femaflavor.org/flavor-library/search?fulltext='+product.FEMA_no " target="_blank">{{ product.FEMA_no }}</a></span>
                                                                                <span class="d-inline-block product-attr" v-if="product.web_public.includes('EC_no') && product.EC_no != 'NA' && product.EC_no != '--' && product.EC_no != ''"><b>EINECS : </b>
                                                                                    <a :href="product.echa_link" target="_blank" v-if="product.echa_link != ''" v-text="product.EC_no"></a>
                                                                                    <span v-else v-text="product.EC_no"></span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="col-md-12 d-none d-md-block">
                                                                                <div class="d-inline-block" v-if="product.show_price_trend == 1">
                                                                                    <span class="product-attr price-trend-text">
                                                                                        Price Trend :
                                                                                    </span>
                                                                                    <span class="price-trend up" data-bs-toggle="tooltip" title="Up" v-if="product.show_price_trend == 1 && product.price_trend == 1">
                                                                                        <i class="fa-solid fa-arrow-trend-up"></i>
                                                                                    </span> 
                                                                                    <span class="price-trend down" data-bs-toggle="tooltip" title="Down" v-if="product.show_price_trend == 1 && product.price_trend == 2">
                                                                                        <i class="fa-solid fa-arrow-trend-down"></i>
                                                                                    </span> 
                                                                                    <span class="price-trend stable" data-bs-toggle="tooltip" title="Stable" v-if="product.show_price_trend == 1 && product.price_trend == 0">
                                                                                        <i class="fa-solid fa-arrow-right-long"></i>
                                                                                    </span> 
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12 d-none d-md-block">
                                                                                <span class="d-inline-block product-attr reach"  v-if="product.REACH_registration_no != ''">
                                                                                    <i class="fa-regular fa-circle-check"></i> REACH Compliant
                                                                                </span>
                                                                                <span class="d-inline-block product-attr reach" v-if="product.green_status">
                                                                                    <i class="fa fa-leaf" aria-hidden="true" v-if="product.green_status =='Natural'"></i>
                                                                                    <i class="fa fa-flask bio-derived-icon" aria-hidden="true" v-if="product.green_status == 'Bio-derived'"></i>
                                                                                    &nbsp;{{ product.green_status }}
                                                                                </span>
                                                                                <span class="d-inline-block product-attr reach" v-if="product.upcycled == 1">
                                                                                    <img src="https://cdn.vanaroma.com/new_website/images/upcycled_logo_va.webp" class="upcycled-logo">
                                                                                    Upcycled
                                                                                </span>
                                                                            </div>
                                                                            <div class="col-md-12" v-if="this.showPrice == 'true' && product.idr > 0">
                                                                            <span class="price"> Rp. {{ product.idr }} / kg</span>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                                <div class="col-md-3 col-2 position-relative">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <button class="btn-default btn-xs btn-product-document float-md-end d-none d-md-block" data-bs-toggle="dropdown"><i class="fa-solid fa-file-signature"></i> Request <i class="fa fa-caret-down"></i></button>
                                                                            <button class="btn-default btn-xs btn-product-document d-md-none" data-bs-toggle="dropdown"><i class="fa-solid fa-file-signature"></i></button>
                                                                            <div class="dropdown-menu dropdown-menu-right dropdown-product-document" aria-labelledby="sort-text">
                                                                                <button class="dropdown-item btn-request" @click="selectProduct({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':''})" :class="selectedProducts.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''">OFFER</button>

                                                                                <button class="dropdown-item btn-request" @click="selectSample({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':'15'})" :class="selectedSamples.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''">SAMPLE</button>
                                                                                <div class="dropdown-header">DOCUMENTS</div>
                                                                                <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'TDS',$event)" :disabled="product.customer_document_requests.tds > 0">TDS</button>
                                                                                <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'SDS',$event)" :disabled="product.customer_document_requests.sds > 0">SDS</button>
                                                                                <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'DOCS',$event)" :disabled="product.customer_document_requests.docs > 0">DOCS</button>
                                                                                <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'GC',$event)" :disabled="product.customer_document_requests.gc > 0">GC</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="row" v-if="product.show_price_trend == 1">
                                                                        <div class="col-12 text-end">
                                                                            <span class="price-trend up">
                                                                                <i class="fa-solid fa-arrow-trend-up text-succes"></i>
                                                                            </span> 
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-3  col-6 mt-md-3 product-grid" v-for="product in products" :key="product.recid_product" 
                        :class="(category &&  category != product.product_category_name.toLowerCase()) ? 'd-none' : ''"
                        :style="(filter && (objFilter.includes(product.recid_product_category) == false || objFilter.includes(product.recid_product_sources) == false || (objFilter.includes('reach') == false && product.REACH_registration_no != '') || (objFilter.includes('non-reach') == false && product.REACH_registration_no == '') )) ? {'display' : 'none'} : {}"
                        >
                            <div :class="product.recid_product_category+' div-category'">
                                <div class="div-source" :class="product.recid_product_sources">
                                    <div class="reach-div" :class="product.REACH_registration_no != '' ? 'reach-registered' : 'reach-not-registered'">
                                        <div :class="'product-form '+product.product_form.toLowerCase()">
                                            <div :class="'green-status '+product.green_status.toLowerCase()" :style="(filter && (objFilter.includes(product.green_status.toLowerCase()) == false && product.green_status != '')) ? {'display' : 'none'} : {}">
                                                <div class="upcycled-div" 
                                                    :class="(product.upcycled == 1)  ? 'upcycled' : 'non-upcycled'"
                                                    :style="(filter && ((objFilter.includes('upcycled') == false && product.upcycled == 1) || (objFilter.includes('non-upcycled') == false && product.upcycled != 1) )) ? {'display' : 'none'} : {}"
                                                    >
                                                <div class="row product-row">
                                                    <div class="search-content d-none">
                                                            {{ 
                                                                product.product_ipc.replace('-', '') + '<br>'+
                                                                product.product_ipc + '<br>'+
                                                                product.product_name + '<br>'+
                                                                product.botanical_name + '<br>'+
                                                                product.indonesian_name + '<br>'+
                                                                product.chinese_name + '<br>'+
                                                                product.french_name + '<br>'+
                                                                product.spanish_name + '<br>'+
                                                                product.japanese_name + '<br>'+
                                                                product.vietnamese_name + '<br>'+
                                                                product.CAS_no +'<br>'+
                                                                product.CAS_no.replace('-', '') + '<br>'+
                                                                product.FEMA_no + '<br>'+
                                                                product.FEMA_no.replace('-', '')+'<br>'+
                                                                product.EC_no+'<br>'+
                                                                product.EC_no.replace('-', '')+'<br>'+
                                                                product.product_synonym.replace(",", " ")+'<br>'+
                                                                product.olfactory_profile.replace(",", " ")+'<br>'
                                                            }}
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="img-product-box">
                                                            <img v-if="!product.product_image" :src="imageUrl+'img/imageproduct/nope.jpg'" class="product-img">
                                                            <div v-else class="owl-carousel owl-loaded owl-drag owl-theme"  data-draggable="true" :data-dots="(product.product_image[1]) ? 'true' : 'false'" data-nav="true" data-navclass="owl-nav owl-nav-product-image" data-navprev='<span class="fa fa-angle-left" aria-hidden="true"></span>' data-navnext='<span class="fa fa-angle-right" aria-hidden="true"></span>' data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                                                <div v-for="(img,index) in product.product_image" :key="index" class="img-container">
                                                                    <div class="img-placeholder" v-if="index == 0"></div>
                                                                    <img class="owl-lazy product-img" @dblclick="showDetail(product.recid_masking,product.slug)" :data-src="imageUrl+'img/imageproduct/'+img.file_name">
                                                                </div>
                                                            </div>
                                                            <div class="floating-btn">
                                                                <button class="btn-default btn-xs btn-product-document" @click="selectProduct({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':''})" :class="selectedProducts.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''"><i class="fa fa-cart-plus" aria-hidden="true"></i></button>

                                                                <button class="btn-default btn-xs btn-product-document" @click="selectSample({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':'15'})" :class="selectedSamples.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''">
                                                                        <i class="fa-solid fa-bottle-droplet"></i>
                                                                    </button>
                                                                <button class="btn-default btn-xs btn-product-document" data-bs-toggle="dropdown"><i class="fa fa-file"></i></button>
                                                                    <div class="dropdown-menu dropdown-menu-right dropdown-product-document" aria-labelledby="sort-text">
                                                                        <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'TDS',$event)" :disabled="product.customer_document_requests.tds > 0">TDS</button>
                                                                        <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'SDS',$event)" :disabled="product.customer_document_requests.sds > 0">SDS</button>
                                                                        <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'DOCS',$event)" :disabled="product.customer_document_requests.docs > 0">DOCS</button>
                                                                        <button class="dropdown-item btn-request" @click.prevent="requestDocument(product.recid_product,user.email,'GC',$event)" :disabled="product.customer_document_requests.gc > 0">GC</button>
                                                                    </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 py-2 py-md-0 text-center">
                                                        <div class="product-name" @click="showDetail(product.recid_masking,product.slug)"> 
                                                            <span v-if="product.web_public.includes('product_name')">
                                                                <span class="product-name-class" v-if="language === 'EN'">{{ product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'ID'">{{ (product.indonesian_name) ? product.indonesian_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'CN'">{{ (product.chinese_name) ? product.chinese_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'FR'">{{ (product.french_name) ? product.french_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'SP'">{{ (product.spanish_name) ? product.spanish_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'JP'">{{ (product.japanese_name) ? product.japanese_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'VN'">{{ (product.vietnamese_name) ? product.vietnamese_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'TR'">{{ (product.turkish_name) ? product.turkish_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'RU'">{{ (product.russian_name) ? product.russian_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'SA'">{{ (product.arabic_name) ? product.arabic_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'KR'">{{ (product.korean_name) ? product.korean_name : product.product_name }}</span>
                                                                <span class="product-name-class" v-if="language === 'IT'">{{ (product.italian_name) ? product.italian_name : product.product_name }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="botanical-name" @click="showDetail(product.recid_masking,product.slug)" v-text="'ex-'+capitalize(product.botanical_name.toLowerCase())"></div>
                                                    </div>
                                                    <div class="col-md-12" v-if="this.showPrice == 'true' && product.idr > 0">
                                                        <span class="price"> Rp. {{ product.idr }} / kg</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>  <!-- end col-md-9 -->

      </div>
    </div>
    <div>
        <!-- Button trigger modal -->
        <button type="button" id="trigerModalDocument" class="btn d-none" data-bs-toggle="modal" data-bs-target="#modalDocument">open</button>
        <!-- Modal document request -->
        <form method="POST" @submit.prevent="submitDocumentRequest()" id="form-document-request">
            <div class="modal fade" id="modalDocument" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="closeModalDocument"></button>
                        <div class="text-center doc-request-head">
                            Log In to our <a href="https://portal.vanaroma.com/login" target="_blank"><i>Customer Portal</i></a>, or<br>
                            complete the below form
                        </div>
                        <div class="modal-header text-center">
                            <h5 class="modal-title w-100">Document Access Request</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group modal-form mb-3">
                                        <label>Email</label>
                                        <input type="email" class="form-control" name="email" placeholder="Email" v-model="userForm.email" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                         <label>Full Name</label>
                                        <input type="text" class="form-control" name="full_name" placeholder="Full Name" v-model="userForm.full_name" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Department</label>
                                        <input type="text" class="form-control" name="department" placeholder="Department" v-model="userForm.department" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Company</label>
                                        <input type="text" class="form-control" name="company" placeholder="Company" v-model="userForm.company" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group modal-form mb-3">
                                            <label>Industry</label>
                                        <input type="text" class="form-control" name="industry" placeholder="Industry" v-model="userForm.industry" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Country</label>
                                        <select class="form-control" name="country" v-model="userForm.country" @change="selectCode()">
                                            <option value="">Select Country</option>
                                            <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Phone / Whatsapp</label>
                                        <div class="row">
                                            <div class="col-3">
                                                <select class="form-control" name="phone_code" v-model="userForm.phone_code">
                                                    <option value="">Code</option>
                                                    <option v-for="country in countries" :key="country.code" :value="country.dial_code">{{ country.dial_code }}</option>
                                                </select>
                                            </div>
                                            <div class="col-9">
                                                <input type="text" class="form-control" name="phone" placeholder="number" v-model="userForm.phone" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Company Website</label>
                                        <input type="text" class="form-control" name="website" placeholder="https://" v-model="userForm.website" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <input type="hidden" class="form-control" v-model="request_document_type" name="document_type" id="document_type">
                                <input type="hidden" class="form-control" v-model="request_recid_product" name="recid_product" id="recid_product">
                            </div>
                            <button type="submit" class="btn btn-outline-darkgrey w-100" id="btn-submit-document-request">Send document request</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="whatsapp-btn">
        <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
        <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
    </div>
  </section>
</template>
<style src="@/assets/css/product-catalog.css"></style>
<style scoped>
    .fade {
        transition: opacity .05s linear;
    }
    .modal-backdrop.show {
        opacity: 0.3 !important;
    }
</style>
<script>
import axios from "axios";
import validator from 'validator';
import { mapActions,mapGetters } from "vuex";
import countriesJson from '@/assets/countries.json'
export default {
    name: 'ProductCatalog',
    data() {
        return {
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        viewMode: (window.innerWidth >= 768) ? 'list' : 'grid',
        sources: [],
        categories: [],
        products: [],
        language:localStorage.getItem('language') ? localStorage.getItem('language') : 'EN' ,
        FlagIcon:localStorage.getItem('FlagIcon') ? localStorage.getItem('FlagIcon') : 'united-kingdom.svg',
        sort:'',
        crossref:'',
        search:'',
        category:'',
        filter:'',
        getTry:0,
        emptyResults:false,
        showPrice:false,
        userForm:{
            email:'',
            full_name:'',
            department: '',
            company:'',
            website:'',
            phone:'',
            country:'',
            industry:'',
            phone_code:''
        },
        request_document_type:'',
        request_recid_product:'',
        EndPoint:'',    
        isLoading:false,
        countries:countriesJson,
        apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
        
        };
    },
    computed:{
        productCategory(){
            return this.$route.params.category;
        },
        searchUrl(){
            if(this.search){
                return 'search='+this.search
            }else{
                return ''
            }
        },
        sortUrl(){
            if(this.sort){
                return '&sort='+this.sort
            }else{
                return ''
            }
        },
        langUrl(){
            if(this.$route.query.lang){
                return '&lang='+this.$route.query.lang
            }else{
                return ''
            }
        },
        filterUrl(){
            if(this.$route.query.filter){
                return '&filter='+this.$route.query.filter
            }else{
                return ''
            }
        },
        objFilter(){
            return this.filter.split(',')
        },
        mostPopular(){
            if(this.language === 'EN'){
                return 'Popularity';
            }else if(this.language === 'ID'){
                return 'Paling Populer';
            }else if(this.language === 'CN'){
                return '最受欢迎';
            }else if(this.language === 'FR'){
                return 'Le Plus Populaire';
            }else if(this.language === 'SP'){
                return 'Más Popular';
            }else if(this.language === 'JP'){
                return '最も人気のある';
            }else if(this.language === 'VN'){
                return 'Phổ biến nhất';
            }else if(this.language === 'TR'){
                return 'En popüler';
            }else if(this.language === 'RU'){
                return 'Самый популярный';
            }else if(this.language === 'SA'){
                return 'شعبية';
            }else if(this.language === 'KR'){
                return '인기';
            }else if(this.language === 'IT'){
                return 'Popolarità';
            }else{
                return 'Popularity';
            }
        },
        mostRecent(){
            if(this.language === 'EN'){
                return 'Most Recent';
            }else if(this.language === 'ID'){
                return 'Terbaru';
            }else if(this.language === 'CN'){
                return '最近的';
            }else if(this.language === 'FR'){
                return 'Le plus récent';
            }else if(this.language === 'SP'){
                return 'Más reciente';
            }else if(this.language === 'JP'){
                return '最も最近の';
            }else if(this.language === 'VN'){
                return 'Gần đây nhất';
            }else if(this.language === 'TR'){
                return 'En yeni';
            }else if(this.language === 'RU'){
                return 'Самые последние';
            }else if(this.language === 'SA'){
                return '가장 최근';
            }else if(this.language === 'KR'){
                return 'الأحدث';
            }else if(this.language === 'IT'){
                return 'Più recente';
            }else{
                return 'Most Recent';
            }
        },
        mostSearched(){
            if(this.language === 'EN'){
                return 'Most Searched';
            }else if(this.language === 'ID'){
                return 'Paling Banyak Dicari';
            }else if(this.language === 'CN'){
                return '搜索最多的';
            }else if(this.language === 'FR'){
                return 'Les plus recherchés';
            }else if(this.language === 'SP'){
                return 'Más buscado';
            }else if(this.language === 'JP'){
                return '最も検索された';
            }else if(this.language === 'VN'){
                return 'Tìm kiếm nhiều nhất';
            }else if(this.language === 'TR'){
                return 'En Çok Aranan';
            }else if(this.language === 'RU'){
                return 'Самые популярные';
            }else if(this.language === 'SA'){
                return 'الأكثر بحثا';
            }else if(this.language === 'KR'){
                return '최다 검색';
            }else if(this.language === 'IT'){
                return 'Più cercati';
            }else{
                return 'Most Searched';
            }
        },
        botanicalSource(){
            if(this.language === 'EN'){
                return 'Botanical Source';
            }else if(this.language === 'ID'){
                return 'Sumber Botani';
            }else if(this.language === 'CN'){
                return '植物来源';
            }else if(this.language === 'FR'){
                return 'Source Botanique';
            }else if(this.language === 'SP'){
                return 'Fuente Botánica';
            }else if(this.language === 'JP'){
                return '植物源';
            }else if(this.language === 'VN'){
                return 'Nguồn thực vật';
            }else if(this.language === 'TR'){
                return 'Botanik Kaynak';
            }else if(this.language === 'RU'){
                return 'Ботанический источник';
            }else if(this.language === 'SA'){
                return 'المصدر النباتي';
            }else if(this.language === 'KR'){
                return '식물원';
            }else if(this.language === 'IT'){
                return 'Fonte Botanica';
            }else{
                return 'Botanical Source';
            }
        },
        priceLowToHigh() {
            if(this.language === 'EN'){
                return 'Price: Low to High';
            } else if (this.language === 'ID') {
                return 'Harga: Rendah ke Tinggi';
            } else if (this.language === 'CN') {
                return '价格：低到高';
            } else if (this.language === 'FR') {
                return 'Prix : Du plus bas au plus élevé';
            } else if (this.language === 'SP') {
                return 'Precio: De menor a mayor';
            } else if (this.language === 'JP') {
                return '価格：低い順';
            } else if (this.language === 'VN') {
                return 'Giá: Thấp đến Cao';
            } else if (this.language === 'TR') {
                return 'Fiyat: Düşükten Yükseğe';
            } else if (this.language === 'RU') {
                return 'Цена: По возрастанию';
            }else if (this.language === 'SA') {
                return 'السعر من الارخص للاعلى';
            }else if (this.language === 'KR') {
                return '가격: 낮음순';
            }else if (this.language === 'IT') {
                return 'Prezzo: Basso ad Alto';
            }else {
                return 'Price: Low to High';
            }
        },
        priceHighToLow() {
            if(this.language === 'EN'){
                return 'Price: High to Low';
            } else if (this.language === 'ID') {
                return 'Harga: Tinggi ke Rendah';
            } else if (this.language === 'CN') {
                return '价格：从高到低';
            } else if (this.language === 'FR') {
                return 'Prix : élevé à bas';
            } else if (this.language === 'SP') {
                return 'Precio: Alto a Bajo';
            } else if (this.language === 'JP') {
                return '価格: 高から低まで';
            } else if (this.language === 'VN') {
                return 'Giá từ cao đến thấp';
            } else if (this.language === 'TR') {
                return 'Fiyat pahalıdan ucuza';
            } else if (this.language === 'RU') {
                return 'Цена: от высокой к низкой';
            }else if (this.language === 'SA') {
                return 'السعر الاعلى الى الادنى';
            }else if (this.language === 'KR') {
                return '가격: 높음에서 낮음';
            }else if (this.language === 'IT') {
                return 'Prezzo: Alto a Basso';
            }else {
                return 'Price: High to Low';
            }
        },
        sortLable(){
            if(this.language === 'EN'){
                return 'Sort by';
            }else if(this.language === 'ID'){
                return 'Urutkan';
            }else if(this.language === 'CN'){
                return '种类';
            }else if(this.language === 'FR'){
                return 'Trier';
            }else if(this.language === 'SP'){
                return 'Clasificar';
            }else if(this.language === 'JP'){
                return '選別';
            }else if(this.language === 'VN'){
                return 'Loại';
            }else if(this.language === 'TR'){
                return 'Düzenlemek';
            }else if(this.language === 'RU'){
                return 'Сортировать';
            }else if(this.language === 'SA'){
                return 'ترتيب حسب';
            }else if(this.language === 'KR'){
                return '정렬 기준';
            }else if(this.language === 'IT'){
                return 'Ordina per';
            }else{
                return 'Sort by';
            }
        },
        selectedSort(){
            if(this.sort=='sources') {
                return this.botanicalSource
            }else if(this.sort=='searched') {
                return this.mostSearched
            }else if(this.sort=='recent') {
                return this.mostRecent
            }else if(this.sort == 'az'){
                return 'A-Z'
            }else if(this.sort == 'natural'){
                return 'Natural'
            }else if(this.sort == 'bio-derived'){
                return 'Bio-derived'
            }else if(this.sort == 'low-to-high'){
                return this.priceLowToHigh
            }else if(this.sort == 'high-to-low'){
                return this.priceHighToLow
            }else if(this.sort == 'most'){
                return this.mostPopular;
            }else{
                return this.mostSearched;
            }
        },
       ...mapGetters({
            selectedProducts:"products/selectedProducts",
            selectedSamples:"products/selectedSamples",
            user:"products/user",
        }),
    },
    watch: {
        productCategory: function (val) {
            if(val){
                this.category=val;
            }else{
                this.category='';
            }
        },
        products:function(val){
            if((!val || Object.keys(val).length === 0) && this.emptyResults == false && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getProducts()
                    this.getTry++
                }, 1000);
            }else{
                //count result
                const counts = {};
                for(var i= 0; i< Object.keys(val).length; i++){
                    var prod=val[i];
                    var reachStatus = prod.REACH_registration_no != '' ? 'reach-registered' : 'reach-not-registered'
                    var upcycledStatus = prod.upcycled == 1 ? 'upcycled' : 'non-upcycled'
                    counts[prod.recid_product_category] = counts[prod.recid_product_category] ? counts[prod.recid_product_category] + 1 : 1;
                    counts[prod.recid_product_sources] = counts[prod.recid_product_sources] ? counts[prod.recid_product_sources] + 1 : 1;
                    counts[reachStatus] = counts[reachStatus] ? counts[reachStatus] + 1 : 1;
                    counts[prod.product_form.toLowerCase()] = counts[prod.product_form.toLowerCase()] ? counts[prod.product_form.toLowerCase()] + 1 : 1;
                    counts[prod.product_form.toLowerCase()] = counts[prod.product_form.toLowerCase()] ? counts[prod.product_form.toLowerCase()] + 1 : 1;
                    counts[prod.green_status.toLowerCase()] = counts[prod.green_status.toLowerCase()] ? counts[prod.green_status.toLowerCase()] + 1 : 1;
                    counts[upcycledStatus] = counts[upcycledStatus] ? counts[upcycledStatus] + 1 : 1;
                }

                const classNames = document.getElementsByClassName('count-result');
                for (const className of classNames) {
                    var category = className.getAttribute('data-category')
                    if(counts[category]){
                        className.innerHTML='('+counts[category]+')'
                    }else{
                        className.innerHTML='(0)'
                    }
                }
            }

            
        },
        categories:function(val){
            if((!val || Object.keys(val).length === 0) && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getCategories()
                    this.getTry++
                }, 1000);
            }
        },
        sources:function(val){
            if((!val || Object.keys(val).length === 0) && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getSources()
                    this.getTry++
                }, 1000);
            }
        }
    },
    methods:{
        capitalize(s){
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
        nl2br (str) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            var breakTag = '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },
        alertSuccess() {
             this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Your request is already send',
                showConfirmButton: false,
                timer: 2500
                }
            );
        },
        alertError() {
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Failed, something is wrong',
                showConfirmButton: false,
                timer: 2500
                }
            );
        },
        setSources(data) {
            this.sources = data;
        },
        setCategories(data) {
            this.categories = data;
        },
        setProducts(data) {
            this.products = data;
            this.isLoading=false;
            let oldScript= document.getElementById('popoverJS');
            if(oldScript){
                oldScript.remove()
            }
            let popoverJS = document.createElement('script')
            popoverJS.setAttribute('id', 'popoverJS')
            popoverJS.setAttribute('src', '/assets/js/reinit.js')
            document.body.appendChild(popoverJS)
        },
        changeLanguage(lang){
            this.language=lang;
            if(this.language == 'EN'){
               this.FlagIcon='united-kingdom.svg';
            }else if(this.language == 'ID'){
               this.FlagIcon='indonesia-flag.svg';
            }else if(this.language == 'CN'){
               this.FlagIcon='china-flag.svg';
            }else if(this.language == 'FR'){
               this.FlagIcon='france-flag.svg';
            }else if(this.language == 'SP'){
               this.FlagIcon='spain.svg';
            }else if(this.language == 'JP'){
               this.FlagIcon='japan-flag.svg';
            }else if(this.language == 'VN'){
               this.FlagIcon='vietnam-flag.svg';
            }else if(this.language == 'TR'){
               this.FlagIcon='turkey-flag.svg';
            }else if(this.language == 'RU'){
               this.FlagIcon='russia-flag.svg';
            }else if(this.language == 'SA'){
               this.FlagIcon='sa.svg';
            }else if(this.language == 'KR'){
               this.FlagIcon='kr.svg';
            }else if(this.language == 'IT'){
               this.FlagIcon='italy-flag.svg';
            }
            localStorage.setItem('language',lang);
            localStorage.setItem('FlagIcon',this.FlagIcon);            
            history.pushState('', '', "?"+this.searchUrl+this.sortUrl+"&lang=" + lang+this.filterUrl);
        },
        filterProduct(className,e){
            
           const rows = document.getElementsByClassName(className);
           if(e.target.checked){
                for (const row of rows) {
                    if(this.viewMode === 'list'){
                        row.style.display = '';
                    }else{
                        row.closest('.product-grid').style.display = '';
                    }
                }
           }else{
                for (const row of rows) {
                    if(this.viewMode === 'list'){
                        row.style.display = 'none';
                    }else{
                        row.closest('.product-grid').style.display = 'none';
                    }
                }
           }
           this.setFilter()
        },
        setFilter(){
            //add into url
           let filters=''
           const sources = document.getElementsByClassName('check-sources');
           for (const source of sources) {
                if(source.checked == true){
                    filters+=','+source.value
                }
           }
           const categories = document.getElementsByClassName('check-category');
           for (const category of categories) {
                if(category.checked == true){
                    filters+=','+category.value
                }
           }
           const reach=document.getElementById('check-reach');
           const nonreach=document.getElementById('check-non-reach');
           if(reach.checked == true){
                filters+=',reach'
           }
           if(nonreach.checked == true){
                filters+=',non-reach'
           }
           const liquid=document.getElementById('form-liquid');
           const viscous=document.getElementById('form-viscous');
           const solid=document.getElementById('form-solid');
           if(liquid.checked == true){
                filters+=',liquid'
           }
           if(solid.checked == true){
                filters+=',solid'
           }
           if(viscous.checked == true){
                filters+=',viscous'
           }
           const natural=document.getElementById('natural');
           const bioDerived=document.getElementById('bio-derived');
           if(natural.checked == true){
                filters+=',natural'
           }
           if(bioDerived.checked == true){
                filters+=',bio-derived'
           }
           const upcycled=document.getElementById('upcycled');
           const nonUpcycled=document.getElementById('non-upcycled');
           if(upcycled.checked == true){
                filters+=',upcycled'
           }
           if(nonUpcycled.checked == true){
                filters+=',non-upcycled'
           }

           this.$route.query.filter=filters
           history.pushState('', '', "?"+this.searchUrl+this.sortUrl +this.langUrl + "&filter="+filters);
        },
        checkAllSources(e){
            let rows
            if(this.viewMode === 'list'){
                 rows = document.getElementsByClassName('div-source');
            }else{
                 rows = document.getElementsByClassName('product-grid');
            }
            const checkboxes = document.getElementsByClassName('check-sources');
            if(e.target.checked){
                for (const row of rows) {
                    row.style.display = '';
                }
                for (const checkbox of checkboxes) {
                    checkbox.checked = 'checked';
                }
            }else{
                for (const row of rows) {
                    row.style.display = 'none';
                }
                for (const checkbox of checkboxes) {
                    checkbox.checked = '';
                }
            }
            this.setFilter()
        },
        checkAllCategories(e){
            let rows
            if(this.viewMode === 'list'){
                rows = document.getElementsByClassName('div-category');
            }else{
                rows = document.getElementsByClassName('product-grid');
            }
            const checkboxes = document.getElementsByClassName('check-category');
            if(e.target.checked){
                for (const row of rows) {
                    row.style.display = '';
                }
                for (const checkbox of checkboxes) {
                    checkbox.checked = 'checked';
                }
            }else{
                for (const row of rows) {
                    row.style.display = 'none';
                }
                for (const checkbox of checkboxes) {
                    checkbox.checked = '';
                }
            }
            this.setFilter()
        },
        checkReach(status,e){

            let display,classList;
            if(status == 1){
                classList=document.getElementsByClassName('reach-registered');
            }else{
                classList=document.getElementsByClassName('reach-not-registered');
            }
            if(e.target.checked){
                display='';
            }else{
                display='none';
            }
            for (const className of classList) {
                if(this.viewMode === 'list'){
                    className.style.display = display;
                }else{
                    className.closest('.product-grid').style.display = display;
                }
            }
            this.setFilter()
            
        },
        productFormFilter(form,e){
            let display,classList;
            classList=document.getElementsByClassName(form);
            if(e.target.checked){
                display='';
            }else{
                display='none';
            }
            for (const className of classList) {
                if(this.viewMode === 'list'){
                    className.style.display = display;
                }else{
                    className.closest('.product-grid').style.display = display;
                }
            }
            this.setFilter()
        },
        greenStatusFilter(greenStatus,e){
            let display,classList;
            classList=document.getElementsByClassName(greenStatus);
            if(e.target.checked){
                display='';
            }else{
                display='none';
            }
            for (const className of classList) {
                if(this.viewMode === 'list'){
                    className.style.display = display;
                }else{
                    className.closest('.product-grid').style.display = display;
                }
            }
            this.setFilter()
        },
        upcycledFilter(upcycled,e){
            let display,classList;
            classList=document.getElementsByClassName(upcycled);
            if(e.target.checked){
                display='';
            }else{
                display='none';
            }
            for (const className of classList) {
                if(this.viewMode === 'list'){
                    className.style.display = display;
                }else{
                    className.closest('.product-grid').style.display = display;
                }
            }
            this.setFilter()
        },
        getSources(){
            axios.get(this.apiUrl+'/webx/r_botanical_sources/')      
            .then((response) => {
                if(response.data.data == undefined){
                    console.log('retrying to get data source')
                    if(this.emptyResults == false && this.getTry < 5){
                        setTimeout(() => {
                            this.getSources()
                            this.getProducts()
                            this.getTry++
                        }, 1000);
                    }
                }else{
                    this.setSources(response.data.data)
                }
            })
            .catch((error) => {
                console.log(error)
                this.setSources([])
            });
        },
        getCategories(){
            //get product categories
            axios.get(this.apiUrl+'/webx/r_product_categories/')      
            .then((response) => {
                if(response.data.data == undefined){
                    console.log('retrying to get data category')
                    if(this.emptyResults == false && this.getTry < 5){
                        setTimeout(() => {
                            this.getCategories()
                            this.getProducts()
                            this.getTry++
                        }, 1000);
                    }
                }else{
                    this.setCategories(response.data.data)
                }
            })
            .catch((error) => {
                console.log(error)
                this.setCategories([])
            })
        },
        getProducts(){
            //get all products

            if(parseInt(window.innerWidth) >= 768){
                document.getElementById('search_product').focus()
            }else{
                document.getElementById('search_product2').focus()
            }
            this.isLoading = true;
            let formdata = new FormData();
            formdata.append("sort", this.sort);
            formdata.append("like", this.search);
            formdata.append("requestor_email", this.user.email);
            formdata.append("recid_company", this.crossref);
            let bodyContent =  formdata;
            if(this.crossref == ''){
                this.EndPoint = this.apiUrl+"/webx/r_products/";
            }else{
                this.EndPoint = this.apiUrl+"/webx/r_product_crossref/";
            }
            let reqOptions = {
                url: this.EndPoint,
                method: "POST",
                data: bodyContent,
            }      
            axios.request(reqOptions)
                .then((response) => {
                    if(response.data.data == undefined){
                        console.log('retrying to get data product')
                        if(this.emptyResults == false && this.getTry < 5){
                            setTimeout(() => {
                                this.getProducts()
                                this.getTry++
                            }, 1000);
                        }
                    }else{
                        this.setProducts(response.data.data)
                        this.emptyResults=true
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setProducts([])
                });
        },
        searchProduct(){
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            var input = document.getElementById('search_product');
            var filter = input.value.toUpperCase();
            if(this.viewMode =='list'){
                var rows =document.getElementsByClassName('product-row');
                for(const row of rows){
                    var className = row.getElementsByClassName('search-content')[0];
                    var content = className.textContent.toUpperCase();
                    if (content.indexOf(filter) > -1) {
                        row.style.display=''
                    } else {
                        row.style.display='none'
                    }

                }
            }else{
                var grids =document.getElementsByClassName('product-grid');
                for(const grid of grids){
                    var searchContent = grid.getElementsByClassName('search-content')[0];
                    var contents = searchContent.textContent.toUpperCase();
                    if (contents.indexOf(filter) > -1) {
                        grid.style.display=''
                    } else {
                        grid.style.display='none'
                    }

                }
            }
        },
        searchProduct2(){
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            var input = document.getElementById('search_product2');
            var filter = input.value.toUpperCase();
            if(this.viewMode =='list'){
                var rows =document.getElementsByClassName('product-row');
                for(const row of rows){
                    var className = row.getElementsByClassName('search-content')[0];
                    var content = className.textContent.toUpperCase();
                    if (content.indexOf(filter) > -1) {
                        row.style.display=''
                    } else {
                        row.style.display='none'
                    }

                }
            }else{
                var grids =document.getElementsByClassName('product-grid');
                for(const grid of grids){
                    var searchContent = grid.getElementsByClassName('search-content')[0];
                    var contents = searchContent.textContent.toUpperCase();
                    if (contents.indexOf(filter) > -1) {
                        grid.style.display=''
                    } else {
                        grid.style.display='none'
                    }

                }
            }
        },
        sorting(newSort){
            this.sort = newSort;
            this.crossref='';
            // history.pushState('', '', "/product-catalog?sort=" + newSort);
            history.pushState('', '', "?"+this.searchUrl+"&sort=" + newSort +this.langUrl +this.filterUrl);
            this.getProducts();
        },
        submitSearch(){
            //console.log(this.filterUrl)
            history.pushState('', '', "?search=" + this.search+this.sortUrl+this.langUrl+this.filterUrl);
            this.getProducts();
        },
        requestDocument(product,email,type,event){
            event.target.disabled=true;
            this.request_recid_product=product
            this.request_document_type=type
            if(email == '' || email == null){
                let btn=document.getElementById('trigerModalDocument');
                btn.click();
            }else{
                this.sendDocumentRequest();
            }
        },
        submitDocumentRequest(){
            if(validator.isEmail(this.userForm.email)){
                let closeBtn=document.getElementById("closeModalDocument");
                this.setUser(this.userForm);
                this.sendDocumentRequest();
                closeBtn.click();
            }else{
                this.$swal(
                    {
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Failed, Email is not Valid',
                    showConfirmButton: false,
                    timer: 2500
                    }
                );
            }
        },
        sendDocumentRequest(){
            document.getElementById('btn-submit-document-request').disabled=true;
            let formdata = new FormData();
            formdata.append("document_type", this.request_document_type);
            formdata.append("recid_product", this.request_recid_product);
            formdata.append("email", this.user.email);
            formdata.append("name", this.user.full_name);
            formdata.append("company", this.user.company);
            formdata.append("website", '');
            formdata.append("phone", '');
            formdata.append("designation", '');
            formdata.append("ip_address", '');
            formdata.append("function", this.user.department);
            formdata.append("website", this.user.website);
            formdata.append("phone", this.user.phone);
            formdata.append("phone_code", this.user.phone_code);
            formdata.append("country", this.user.country);
            formdata.append("industry", this.user.industry);
            formdata.append("status", 0);
            formdata.append("type", 0);
            let bodyContent =  formdata;
            let reqOptions = {
                url: this.apiUrl+"/webx/c_customer_document_request",
                method: "POST",
                data: bodyContent,
            }      
            axios.request(reqOptions)
                .then((response) => {
                    this.isLoading=false;
                    if(response.data.data.status == 'success'){
                        this.alertSuccess();
                    }else{
                        console.log(response.data.data.status);
                    }
                    document.getElementById('btn-submit-document-request').disabled=false;
                })
                .catch((error) => console.log(error));
            
        },
        showDetail(recid,slug){
            if(slug){
                this.$router.push('/product-detail/'+slug)
            }else{
                this.$router.push('/product-detail/'+recid)
            }
        },
        changeViewMode(mode){
            this.viewMode=mode
            this.getProducts();
        },
         selectCode(){
            var country = this.countries.find((country) => country.name === this.userForm.country);

            this.userForm.phone_code = country.dial_code;
        },
        ...mapActions({
            selectProduct:"products/selectProduct",
            selectSample:"products/selectSample",
            setUser:"products/setUser",
        }),
        resizeFunction(){
            var width = window.innerWidth;
            var mode
            if(width >= 768){
                mode = 'list'
            }else{
                mode = 'grid'
            }
            this.changeViewMode(mode)
        },
        showFilter(){
            var filterSidebar = document.getElementById('filterSidebar');
            filterSidebar.classList.add('show');
        },
        closeFilter() {
            var filterSidebar = document.getElementById('filterSidebar');
            filterSidebar.classList.remove('show');
        }
    },
    mounted() {
            var urlParams=this.$route.query;
            if(urlParams.sort){
                this.sort=urlParams.sort;
            }
            if(urlParams.crossref){
                this.crossref=urlParams.crossref;
            }
            if(urlParams.search){
                this.search=urlParams.search;
            }
            if(urlParams.filter){
                this.filter=urlParams.filter;
            }
            if(urlParams.lang){
                this.language=urlParams.lang.toUpperCase();
                if(urlParams.lang.toUpperCase() == 'EN'){
                this.FlagIcon='united-kingdom.svg';
                }else if(urlParams.lang.toUpperCase() == 'ID'){
                this.FlagIcon='indonesia-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'CN'){
                this.FlagIcon='china-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'FR'){
                this.FlagIcon='france-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'SP'){
                this.FlagIcon='spain.svg';
                }else if(urlParams.lang.toUpperCase() == 'JP'){
                this.FlagIcon='japan-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'VN'){
                this.FlagIcon='vietnam-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'TR'){
                this.FlagIcon='turkey-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'RU'){
                this.FlagIcon='russia-flag.svg';
                }else if(urlParams.lang.toUpperCase() == 'SA'){
                this.FlagIcon='sa.svg';
                }else if(urlParams.lang.toUpperCase() == 'KR'){
                this.FlagIcon='kr.svg';
                }
            }
            if(urlParams.show_price){
                this.showPrice=urlParams.show_price;
            }
            
            if(this.$route.params.category){
                this.category=this.$route.params.category;
            }
            let oldScript=document.getElementById('mainJS');
            if(oldScript){
                oldScript.remove();
            }

            //window.addEventListener("resize",this.resizeFunction);

            let mainJS = document.createElement('script')
            mainJS.setAttribute('id', 'mainJS')
            mainJS.setAttribute('src', '/assets/js/main.js')
            document.body.appendChild(mainJS)
            document.title = 'Product Catalog - Van Aroma';
            this.getSources();
            this.getCategories();
            this.getProducts();
            
        }
    }
</script>

