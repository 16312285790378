<template>
  <div>
    <div class="cookie animated fadeInUp" v-if="!acceptCookie">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            This website uses cookies to ensure you get the best experience on our website. Please read our <router-link to="/privacy-policy">Privacy Policy</router-link>
            <button @click.prevent="acceptCookieAction()">Got it!</button>
          </div>
          <div class="col-md-4">

          </div>
        </div>
      </div>
    </div>
    <footer class="page_footer ds ms s-py-70 s-py-md-90 c-gutter-60 dark-gradient leaf-wrap overflow-visible" v-if="currentRouteName !=='page-not-found' && currentRouteName !== 'maps' && currentRouteName !== 'specific-maps' ">
      <!-- <div class="leaf leaf-left animate animated" data-animation="fadeInUp" data-delay="150">
        <img src="@/assets/images/footer-leaf-1.png" alt="img">
      </div>
      <div class="leaf leaf-right animate animated" data-animation="fadeInUp" data-delay="150">
        <img src="@/assets/images/footer-leaf-2.png" alt="img">
      </div> -->
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="divider-xl-80"></div>
          </div>
          <div class="col-md-8 d-none d-md-block">
            <div class="row">
              <div class="col-md-4 animate" data-animation="fadeInUp">
                <div class="widget widget_nav_menu">

                  <h3 class="widget-title">Company</h3>

                  <div class="menu-container">

                    <ul class="menu">

                      <li class="menu-item">
                        <router-link to="/about" class="nav-link">
                          About us
                        </router-link>
                      </li>
                      <li class="menu-item"> 
                        <router-link to="/distribution-network" class="nav-link">
                          Distribution Network
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/privacy-policy">
                          Privacy Policy
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/contact">
                          Contact Us
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/career">
                          Career
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="divider-60 divider-md-0"></div>
              </div>
              <div class="col-md-4 animate" data-animation="fadeInUp">
                <div class="widget widget_nav_menu">

                  <h3 class="widget-title">Products</h3>

                  <div class="menu-container">

                    <ul class="menu">
                      <li class="menu-item">
                        <router-link to="/product/essential%20oils">
                          Essential Oils
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/product/absolutes">
                          Absolutes
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/product/co2%20oils%20%26%20oleoresins">
                          CO2 Oils & Oleoresins
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/product/extracts">
                        Extracts
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/product/isolates">
                        Isolates
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/product/spices">
                        Spices
                        </router-link>
                      </li>
                      <li class="menu-item">
                        <router-link to="/product/resinoids">
                        Resinoids
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="divider-60 divider-xl-0"></div>
              </div>
              <div class="col-md-4 animate" data-animation="fadeInUp">
                <div class="widget widget_nav_menu">

                  <h3 class="widget-title">Portal Area</h3>

                  <div class="menu-container">
                    <ul class="menu">
                      <li class="menu-item">
                        <a href="https://portal.vanaroma.com" target="_blank">
                          Customer Portal
                        </a>
                      </li>
                      <li class="menu-item">
                        <a href="https://regsup.vanaroma.com" target="_blank">
                          Supplier Registration
                        </a>
                      </li>
                      
                      
                    </ul>
                  </div>
                </div>
                <div class="divider-60 divider-xl-0"></div>
              </div>
            </div>
          </div>
          <div class="col-md-4 animate" data-animation="fadeInUp">
            <div class="widget widget_mailchimp">
              <h3 class="widget-title text-center text-d-start">Subscribe To Our Newsletter</h3>
              <form class="signup" id="formEmail" action="/" @submit.prevent="subscribe">
                <input name="email" type="email" class="form-control" v-model="form.email" placeholder="Email address">
                <button type="submit" class="search-submit">
                  <span class="screen-reader-text">Subscribe</span>
                </button>
                <div></div>
              </form>
                <div class="response error" v-if="error">{{ error }}</div>
            </div>

            <div class="divider-50"></div>

            <p class="social-icons text-center text-d-start">

              <a href="https://www.linkedin.com/company/vanaroma" target="_blank" class="fa fa-linkedin border-icon rounded-icon" title="linkedin"></a>
              <a href="https://www.facebook.com/vanaromaindonesia" target="_blank" class="fa fa-facebook border-icon rounded-icon" title="facebook"></a>
              <a href="https://www.instagram.com/van_aroma/" target="_blank" class="fa fa-instagram border-icon rounded-icon" title="twitter"></a>
              <a href="https://linktr.ee/vanaroma" class="linktree-icon border-icon rounded-icon" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="m13.736 5.853l4.005-4.117l2.325 2.38l-4.2 4.005h5.908v3.305h-5.937l4.229 4.108l-2.325 2.334l-5.74-5.769l-5.741 5.769l-2.325-2.325l4.229-4.108H2.226V8.121h5.909l-4.2-4.004l2.324-2.381l4.005 4.117V0h3.472zm-3.472 10.306h3.472V24h-3.472z"/>
                  </svg>
              </a>
            </p>
          </div>
          
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import validator from 'validator';
export default {
  name: 'FooterSection',
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  data() {
        return {
          form:{
            email:''
          },
          error:'',
          acceptCookie : localStorage.getItem('acceptCookie') ? localStorage.getItem('acceptCookie') : '' 
        }
  },
  methods:{
    subscribe(){
      let inputEmail=this.form.email;
      if(validator.isEmail(inputEmail)){
        let cekgmail = this.form.email.indexOf("gmail");
        let cekhotmail = this.form.email.indexOf("hotmail");
        let ceklive = this.form.email.indexOf("live");
        let cekyahoo = this.form.email.indexOf("yahoo");
        if (cekyahoo != -1 || ceklive != -1 || cekhotmail != -1 || cekgmail != -1) {
          this.error='Please use company email'
        }else{
          document.getElementById('subscriberEmail').value=inputEmail;
          this.error=''
          let btn=document.getElementById('trigerModal');
          btn.click();
        }
      }else{
        this.error='Email is not valid'
      }
    },
    acceptCookieAction(){
      this.acceptCookie = true
      localStorage.setItem('acceptCookie',true)
    }
  },
}
</script>
<style scoped>
.error{
  font-style: italic;
  color: #e09f23;
  font-size: 14px;
}
.cookie{
  font-size: 18px;
  color: #fff;
  text-align: center;
  width: 100%;
  z-index: 99;
  position: fixed;
  bottom: 0;
  padding: 20px 0px;
  background-color: #424851;
}
.cookie a{
  color: #49d2a4;
}
.cookie button{
  margin-left: 3rem;
  padding: 5px 20px !important;
  border-radius: 25px !important;
  color: #fff !important;
  border-color: #fff !important;
}
@keyframes fadeInUp {
    from {
        transform: translate3d(0,80px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,80px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.fadeInUp {
    opacity: 1;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    /* animation-delay: 1s; */
}
@media (min-width: 1600px){
  /* .dark-gradient {
      background-image: linear-gradient(90deg, #28303e 58%, #28303e 58%, #2d3545 58%, #2d3545 100%);
  } */
  .dark-gradient {
    background-image: none;
  }
}
 @media (max-width: 500px) {
    .cookie{
      font-size: 14px;
    }
    .cookie button{
      display: block;
      margin-top: 10px !important;
      margin: auto;
    }
 }

  .linktree-icon {
    display: inline-block;
    align-items: center;
    border-radius: 50%;
    border: 1px solid transparent;
    padding: 20px;
    transition: border-color 0.3s ease;
    margin-left: 3px;
    margin-right: 3px;
    width: 55px;
    height: 55px;
    line-height: 1;
  }

  .linktree-icon:hover {
      border-color: white;
  }

  .linktree-icon svg {
      width: 0.7em;
      height: 0.7em;
  }

</style>