<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>MASSOIA BARK</h3>
                <h5>Cryptocaria massoia</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row mb-5">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/pic-massoiabark-01.jpg'" class="img-source p-4" alt="massoia bark oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction to Massoia Bark Oil</h4>
                        <p>Derived from the bark of the massoia tree, its main component is lactone which used for food flavouring, as an additive in butter and milk flavour.</p>
                        <p>All the Massoia Bark Oil in the world is produced in Irian Jaya and Papua region because of the geographic conditions being appropriate, and unique. Massoia trees grow best in rainforests between 400m and 1000m altitude.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 mt-5">
                        <h4>A brief insight into the Massoia Bark Oil Industry in Indonesia.</h4>
                        <p>The aromatic bark from the tree has been an article of commerce for centuries</p>
                        <p>
                        For example, it has been used by Javanese and Balinese women to prepare a warming ointment, called Borbory, with a pleasant smell. Massoia bark has a sweet, coconut-like aroma and is steam distilled to yield Massoia Bark Oil.</p>
                    </div>
                    <div class="col-md-6 mt-5">
                       <p class="text-center"><b>Regions of Cultivation</b></p>
                        <div class="mt-2">
                            <img :src="imageUrl+'new_website/images/botanical-source/map-massoiabark.jpg'" class="w-100" alt="massoia bark oil indonesia">
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Massoia Bark Oil ?</h4>
                        <p class="text-center">
                          We get all our Massoia Bark Oil from local Indonesian farmers and accumulators who harvest and process the trees through steam distillation to produce a crude form of Massoia Bark Oil.
                        <br>MASSOIA BARK DISTILLATION IN 5 STEPS
                        </p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_3.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>SEEDS</h5>
                                                <p>Massoia seeds naturally drop from matured trees and germinate over time becoming independent trees. It is a natural process and grows wild in the forest.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_3.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>PLANTATION</h5>
                                                <p>The young Massoia trees are carefully looked after to make sure they mature into big strong trees with barks that are high in oil content.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3.1_0.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>MASSOIA BARK CHIPS</h5>
                                                <p>
                                                    Raw Material - The tree bark is broken into small chips.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3.2_0.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 4 <br>MASSOIA BARK POWDER</h5>
                                                <p>
                                                    Raw Material - The bark chips are crushed into powder.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_8.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 5 <br>DISTILLATION</h5>
                                                <p>
                                                    The crushed powder is then sent to the steam distillation stills to obtain Massoia Bark Oil.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step5_1.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>MASSOIA BARK OIL 50-55% C10</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step6_1.jpg'" alt="massoia bark oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>MASSOIA BARK OIL 90% C10</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="0c70f270-213f-490a-a3a2-47837404bccb"/>
        
        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Massoia Bark Product List</h4>
                    </div>
                    <ProductList source="0c70f270-213f-490a-a3a2-47837404bccb"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'MassoiaBark',
   data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Massoia Bark - Van Aroma';
  }
}
</script>
