<template>
    <div class="mt-5">
      <section class="pb-150 mb-5">
         <div class="container dont-copy">
           <div class="row mt-5 mb-5">
             <div class="page-title mt-5">
                 <h3>TOBACO EXTRACT</h3>
                 <h5>NICOTIANA</h5>
             </div>
           </div>
           <div class="row justify-content-center">
             <div class="col-md-10">
                 <div class="row">
                     <div class="col-md-6"><br>
                         <img :src="imageUrl+'new_website/images/botanical-source/tobbaco_into.jpg'" class="img-source p-4" alt="tobacco indonesia">
                     </div>
                     <div class="col-md-6">
                        <h4 class="mt-5">Introduction</h4>
                        <p>Indonesia is one of the largest producers an consummers of tobaco in the world (primarily, in the form of cigarettes).</p>
                        <p align="left">Tobacco extract is commonly used as a flavor booster in cigarettes. More recently, with the rise in the use of e-cigarettes as an alternative, the demand for this extract has risen significantly. 

                        <br><br>At Van Aroma we produce Tobacco extract through the process of solvent extraction using food grade alcohol. Our extracts are Non-GMO, made with food grade alcohol. Alcohol free extracts are available to suit your specific needs.
                        </p>
                     </div>

                     <br><br>
                        <div class="row" style="margin-top: 80px;">
                            <div class="col-md-12">
                                <img :src="imageUrl+'new_website/images/botanical-source/tobbaco_map.jpg'" class="img-thumbnail" style="width: 100%" alt="tobacco indonesia">
                                
                            </div>
                        </div><!-- / row -->

                     <div class="col-md-12 mt-5">
                         <h4 class="mt-5 mb-5 text-center">How do we at Van Aroma source our Patchouli Oil?</h4>
                         <p class="text-center" >Most of Indonesia's tobacco plantations are on the island of Java. Bali and West Nusa Tenggara contibute small proportions of the total produce.
                        <br><br>Our Tobacco extract is obtained from carefully handpicked, and sorted leaves that provide an exceptional odor and flavor profile. Check out the simple five-step field to factory slideshow on the right to get a better understanding of our process.</p>
                         <div class="mt-5">
                             <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Tobacco-Plantation.jpg'" alt="tobacco indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 1 <br>PLANTATION</h5>
                                                 <p>The cultivation of tobacco usually takes place annually. The tobacco is germinated in cold frames or hotbeds and then transplanted to the field until it matures. It is grown in warm climates with rich, well-drained soil.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Tobacco-Harvesting.jpg'" alt="tobacco indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 2 <br>HARVESTING</h5>
                                                 <p>Once ripe, tobacco leaves are harvested / cut from the foot.    </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Tobacco-Drying_Fermentation.jpg'" alt="tobacco indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 3 <br>DRYING AND PERMENTATION</h5>
                                                 <p>Moisture content is reduced by drying the leaves in the sun (day). This increases the leaves longevity by preventing it from rotting and helps retain the aroma within the leaves.    </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Tobacco-GroundLeaves.jpg'" alt="tobacco indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 4 <br>GRINDING</h5>
                                                 <p>Tobacco leaves are ground into powder to make it possible to extract the essence of Tobacco.     </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/SolventExtractor_0.jpg'" alt="tobacco indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 5 <br>EXTRACTION</h5>
                                                 <p>Solvent Extraction is used to extract the rich essence.  </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
 
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
           </div>
         </div>

         <FaqElement source="440bf5e0-275f-4374-8d26-6d0cd666f81c"/>
         
         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Tobacco Product List</h4>
                    </div>
                    <ProductList source="440bf5e0-275f-4374-8d26-6d0cd666f81c"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://cdn.vanaroma.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
      </section>
    </div>
 </template>
 <style src="@/assets/css/source-detail.css"></style>
 
 <script>
 import ProductList from '@/components/ProductList.vue'
 import FaqElement from '@/components/FaqElement.vue'
 export default {
   name: 'TobaccoPage',
    data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://cdn.vanaroma.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
   mounted() {
     let oldScript=document.getElementById('mainJS');
     if(oldScript){
       oldScript.remove();
     }
     let mainJS = document.createElement('script')
     mainJS.setAttribute('id', 'mainJS')
     mainJS.setAttribute('src', 'assets/js/main.js')
     document.body.appendChild(mainJS)
 
     let oldPopover= document.getElementById('popoverJS');
     if(oldPopover){
         oldPopover.remove()
     }
     let popoverJS = document.createElement('script')
     popoverJS.setAttribute('id', 'popoverJS')
     popoverJS.setAttribute('src', 'assets/js/reinit.js')
     document.body.appendChild(popoverJS)
 
     document.title = 'Tobacco Extract - Van Aroma';
   }
 }
 </script>
 